import React, {Fragment, Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import axios from 'axios';
import { CloudWatchLogsClient, CreateLogGroupCommand, CreateLogStreamCommand, PutLogEventsCommand, DescribeLogStreamsCommand  } from "@aws-sdk/client-cloudwatch-logs";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            hid: props.match.params.hid,
            patient: {fields: {}},
            history: {fields: {}},
            pageLoading: true,
        }
        this.loadPatient();
        this.writeLogs = this.writeLogs.bind(this)
    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.writeLogs(response.data[0])
            this.setState({
                patient: response.data[0],
            }, this.loadHistory);
        }).catch(error => {
            console.log(error);
        })
    }

    async writeLogs(patient) {
        const logKey = localStorage.getItem('logKey');
        const client = new CloudWatchLogsClient({region: 'us-east-1',   credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'us-east-1' },
            identityPoolId: 'us-east-1:671651f5-c5ab-497b-8966-5baae2389b1a',
          })})
        const logGroupName = 'daps_access_logs'
        const logStreamName = `access_logs_${logKey}`
        const logGroupCommand = new CreateLogGroupCommand({logGroupName: logGroupName})
        const logStreamCommand = new CreateLogStreamCommand({logGroupName: logGroupName, logStreamName: logStreamName})
        try {
            const createLogGroup = await client.send(logGroupCommand)
        } catch (error) {
            if (!error.name === 'ResourceAlreadyExistsException') {
                console.log('log group error', error)
            }
        } finally {
            try {
                const createLogStream = await client.send(logStreamCommand)
            } catch (error) {
                if (!error.name === 'ResourceAlreadyExistsException') {
                    console.log('log stream error', error)
                }
            } finally {
                try  {
                    const describeLogStreams = new DescribeLogStreamsCommand({logGroupName: logGroupName, logStreamNamePrefix: logStreamName, limit: 1})
                    const currentStreams = await client.send(describeLogStreams)
                    const sequenceToken = currentStreams.logStreams[0].uploadSequenceToken
                    const logEventCommand = new PutLogEventsCommand({logGroupName: logGroupName, logStreamName: logStreamName, logEvents: [{message: `user ${localStorage.getItem('user_name')} has accessed patient ${patient.fields.FirstName} ${patient.fields.LastName}: ${patient.fields.PatientId} history records on ${window.location.href}`, timestamp: Date.now()}], sequenceToken: sequenceToken})
                    const data = await client.send(logEventCommand)
                } catch (error) {
                    console.log('log event error', error)
                }
            }
        }
    }

    loadHistory() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/history/${this.state.hid}/show/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                history: response.data[0],
                pageLoading: false,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        let patient = this.state.patient ? this.state.patient.fields : {};
        let history = this.state.history ? this.state.history.fields : {};
        return (
            <Fragment>
                {this.state.pageLoading ? 
                <Container></Container> :
                <Container>
                    <Card>
                        <CardHeader>
                            <h2 className="float-left">{patient.LastName + ', ' + patient.FirstName}</h2>
                        </CardHeader>
                        <CardBody>
                            <span className="h6 float-right">Record Date: {history.updated}</span>
                            <h3>Patient History</h3>
                            <div className="mx-4">
                                <Row>
                                    <Col md="6">
                                        <p className="h4">Diagnosis and Control</p>
                                        <div className="ml-4">
                                            <p className="h5">Age at Diagnosis: {history.diagnosed}</p>
                                            <p className="h5">Control Method: {history.controlMethod}</p>
                                        </div>
                                        <p className="h4">Complications</p>
                                        <div className="ml-4">
                                            <p className="h5">Leg/Foot Ulcer or Lower Leg Amputation/Surgery: {history.ulcerAmputation}</p>
                                            <p className="h5">Angioplasty, Stent or Leg Bypass Surgery: {history.circulation}</p>
                                            <p className="h5">Foot Wound: {history.wound}</p>
                                            <p className="h5">Smoking: {history.smoking}</p>
                                        </div>
                                        <p className="h4">Comorbidities</p>
                                        <div className="ml-4">
                                            <p className="h5">Clinical Depression: {history.depression}</p>
                                            <p className="h5">Problems with Eyes: {history.eyeProblems}</p>
                                            <p className="h5">Heart Disease: {history.heartDisease}</p>
                                            <p className="h5">Kidney Disease: {history.kidneyDisease}</p>
                                            <p className="h5">High Blood Pressure: {history.highBloodPressure}</p>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <p className="h4">Neuropathy</p>
                                        <div className="ml-4">
                                            <p className="h5">Burning or Tingling in Legs and Feet: {history.legFootBurning}</p>
                                            <p className="h5">Leg or Foot Pain with Activity or Rest: {history.legFootPain}</p>
                                            <p className="h5">Changes in Skin Color or Skin Lesions: {history.skinColorLesions}</p>
                                            <p className="h5">Loss of Sensation in Legs and Feet: {history.legFootNumbness}</p>
                                        </div>
                                        <p className="h4">Procedures and Medications</p>
                                        <div className="ml-4">
                                            <p className="h5">Surgeries: {history.surgeries}</p>
                                            <p className="h5">Medications: {history.medications}</p>
                                            <p className="h5">Most Recent Hemoglobin A1C: {history.recentA1c}</p>
                                            <p className="h5">Morning Glucose: {history.morningGlucose}</p>
                                            <p className="h5">Regular Podiatric Care: {history.podiatricCare}</p>
                                        </div>
                                        <p className="h4">Additional Notes</p>
                                        <div className="ml-4">
                                            <p className="h5">{history.additionalNotes}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Container>}
            </Fragment>
        )
    }
}
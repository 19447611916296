import React, {Fragment, Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Dropdown, DropdownItem} from 'muicss/react';
import axios from 'axios';
import Cookies from 'universal-cookie'
import {Line, Bar} from 'react-chartjs-2';

const qs = require('qs');
const cookies = new Cookies()
const moment = require('moment');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

//const screeningScores = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

export default class LocationShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lid: props.match.params.lid,
            activeTab: 'location',
            location: {},
            pageLoading: true,
            hours:[],
            service:[],
        }
        this.loadLocation();
        this.loadServices();
        this.loadHours();
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    loadLocation() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/locations/${this.state.lid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                location: response.data[0],
                pageLoading: false,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    loadServices(){
        const authToken = cookies.get('auth_token')
        axios({
            method:'get',
            url:`${base}/api/caregivers/locations/services/${this.state.lid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                service:response.data,
            })
        }).catch(error => {

        })
    }

    loadHours(){
        let authToken = cookies.get('auth_token')
        axios({
            method:'get',
            url:`${base}/api/caregivers/locations/hours/${this.state.lid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                hours:response.data,
            })
        }).catch(error => {
            
        })
    }

    render() {
        let location = this.state.location;
        let staff = this.state.location.Staff;
        return (
            <Fragment>
                {this.state.pageLoading ? 
                <Container></Container> :
                <Container>
                    <Card>
                        <CardHeader>
                            <h2 className="float-left">{location.Name}</h2>
                            <Dropdown className="float-right" variant="raised" label="Update">
                                <DropdownItem link={"/admin/locations/" + this.state.lid}>Enter Location Info</DropdownItem>
                                <DropdownItem link={"/admin/locations/hour/new/"  + this.state.lid}>Enter New Hours</DropdownItem>
                                <DropdownItem link={"/admin/locations/" + this.state.lid + "/services"}>Enter Location Services</DropdownItem>
                            </Dropdown>
                            
                        </CardHeader>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === 'location' ? 'active' : ''} onClick={() => {this.toggle('location')}}>Information</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === 'hours' ? 'active' : ''} onClick={() => {this.toggle('hours')}}>Hours</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === 'service' ? 'active' : ''} onClick={() => {this.toggle('service')}}>Services</NavLink>
                                </NavItem>
                            </Nav> 
                        <TabContent activeTab ={this.state.activeTab}>
                            <TabPane tabId="location"> 
                                <Card className="border-success">
                                    <CardHeader>Location</CardHeader>
                                    <CardBody>
                                        <h4>Name: {location.Name}</h4>
                                        <h3>Address: {`${location.Address} ${location.City}, ${location.State} ${location.ZipCode}`}</h3>
                                    </CardBody>
                                </Card>
                                <Card className="border-primary">
                                    <CardHeader>Staff</CardHeader>
                                    <CardBody>
                                        {staff.map((person) => (
                                            <div key={person.pk}>
                                                <p className="h4">{person.LastName + ", " + person.FirstName}</p>
                                            </div>
                                        ))}
                                    </CardBody>
                                </Card>
                            </TabPane> 

                            <TabPane tabId = "hours">
                            <div className="ml-4">
                                {this.state.hours.map(h=>(
                                    <Card key={h.id} className="border-info">
                                        <CardHeader>{h.Day}</CardHeader>
                                        <CardBody>
                                            <h4>Open: {moment(h.StartTime, 'HH:mm:ss').format('h:mm a')}</h4>
                                            <h4>Close: {moment(h.EndTime, 'HH:mm:ss').format('h:mm a')}</h4>
                                        </CardBody>
                                    </Card>
                                ))}
                            </div>    
                            </TabPane> 

                            <TabPane tabId = "service">
                            <div className="ml-4">
                                {this.state.service.map(s=>(
                                    <Card key={s.id} className="border-info">
                                        <CardHeader>{"Appointment " + s.Type}</CardHeader>
                                        <CardBody>
                                            <p><strong>Appointment Length: </strong> {s.Length}</p>
                                            <p><strong>Gap Between Appointments: </strong> {s.Gap}</p>
                                        </CardBody>
                                        <CardFooter>
                                            <span className="float-right"><strong>Max Number of Concurrent Appointments: </strong> {s.Count}</span>
                                        </CardFooter>
                                    </Card>
                                ))}
                            </div>
                            </TabPane>   
                        </TabContent>  
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>      
                </Container>
                }
            </Fragment>
        )
    }
}
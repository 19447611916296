import React, { Component } from 'react';
import {
    Container, Row, Col,
    Card, CardHeader, CardBody, CardFooter,
    Alert
} from 'reactstrap';

import { Form, Input, Button, Radio } from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from './../../assets/DAPSwhitelogo.png';

const qs = require('qs');
const moment = require('moment')
const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class SelectMfa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            method: '',
            alertShow: false,
            alertMessage: '',
            color: 'danger',
            email: '',
            profile: {}
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.loadUser()
    }

    loadUser() {
        let authToken = cookies.get('auth_token')
        axios.get(`${base}/api/auth/current_user`,
        {
            withCredentials: true,
            headers: {
                'Authorization': `Token ${authToken}`
            }
        }).then(response => {
            let user = response.data
            let email = user ? user.email : ''
            let profile = user ? user.profile : {}
            this.setState({email: email, profile: profile})
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        let authToken = cookies.get('auth_token')  
        let isNew = cookies.get('mfa_configure')  
        let csrfToken = cookies.get('csrftoken')
        event.preventDefault()
        const data = {
            method: this.state.method,
            csrfmiddlewaretoken: csrfToken,
        }
        if (this.state.profile && this.state.method === 'sms_twilio') {
            data['profile'] = this.state.profile.User
        }
        if (this.state.method === 'email' && !this.state.email) {
            if (isNew) {
                this.props.history.push('/add-details?method=email')
            } else {
                this.props.history.push('/admin/add-details?method=email')
            }
        }
        else if (this.state.method === 'sms_twilio' && (!this.state.profile || !this.state.profile.phone_number)) {
            if (isNew) {
                this.props.history.push('/add-details?method=sms_twilio')
            } else {
                this.props.history.push('/admin/add-details?method=sms_twilio')
            }
        } else {
            axios.post(`${base}/api/mfaAuth/${this.state.method}/activate/`, data, {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization':  `Token ${authToken}`}}).then((response) => {
                if (isNew) {
                    if (this.state.method === 'app') {
                        const qrCode = response.data.details
                        this.props.history.push(`/mfa-qr/?qrCode=${qrCode}`)
                    } else {
                        this.props.history.push(`/mfa-confirm?method=${this.state.method}`)
                    }
                } else {
                    if (this.state.method === 'app') {
                        const qrCode = response.data.details
                        this.props.history.push(`/admin/mfa-qr/?qrCode=${qrCode}`)
                    } else {
                        this.props.history.push(`/admin/mfa-confirm?method=${this.state.method}`)
                    }
                }
            }).catch((error) => {
                this.setState({ alertShow: true, alertMessage: error.response.data });
            })
        }
    }

    render() {
        return (
            <Container>
                <Row className="p-4">
                    <Col sm="12" md="6" className="text-center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src={Logo} alt="DAPS Health Logo" style={{maxHeight: '80%', maxWidth: '80%'}} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md='6' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <Card>
                            <Form onSubmit={this.handleSubmit}>
                                <CardHeader>
                                    <legend>Select MFA Method</legend>
                                </CardHeader>
                                <CardBody>
                                    <Alert className="m-2" color={this.state.color} isOpen={this.state.alertShow} toggle={() => { this.setState({ alertShow: false }) }}>
                                        {this.state.alertMessage}
                                    </Alert>
                                    <fieldset>
                                        <div>
                                            <Radio name="method" id="method-email" value="email" label="Email" onChange={this.handleInputChange} />
                                            <p className="small">{this.state.email}</p>
                                        </div>
                                        <div>
                                            <Radio name="method" id="method-sms" value="sms_twilio" label="Phone" onChange={this.handleInputChange} />
                                            <p className="small">{this.state.profile ? this.state.profile.phone_number : ''}</p>
                                            <p className='small'>Some fees may apply</p>
                                        </div>
                                        <div>
                                            <Radio name="method" id="method-app" value="app" label="Application" onChange={this.handleInputChange} />
                                            <p className='small'>Such as Authy or Google Authenticator</p>
                                        </div>
                                    </fieldset>
                                </CardBody>
                                <CardFooter>
                                    <Button className="bg-success" color="primary" variant="raised">Submit</Button>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default SelectMfa;
import React, { Component } from 'react';
import {
    Container, Row, Col,
    Card, CardHeader, CardBody, CardFooter,
    Alert
} from 'reactstrap';

import { NavLink as Link } from 'react-router-dom';

import { Form, Input, Button } from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from './../../assets/DAPSwhitelogo.png';

const qs = require('qs');
const moment = require('moment')
const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;
const cookieDomain = window.location.hostname
class MfaConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            method: qs.parse(props.location.search, {ignoreQueryPrefix: true}).method,
            ephemeralToken: qs.parse(props.location.search, {ignoreQueryPrefix: true}).ephemeralToken,
            code: '',
            alertShow: false,
            alertMessage: '',
            color: 'danger',
            backupCodes: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.requestNewCode = this.requestNewCode.bind(this);
    }

    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    requestNewCode(event) {
        event.preventDefault()
        let csrfToken = cookies.get('csrftoken')
        let authToken = cookies.get('auth_token') ? cookies.get('auth_token') : cookies.get('temp_token')
        let data = {
            csrfmiddlewaretoken: csrfToken,
            method: this.state.method,
        }
        axios.post(`${base}/api/mfaAuth/code/request/`, data, {withCredentials: true, headers: {'X-CSRFToken': csrfToken, 'Authorization': `Token ${authToken}`}}).then((response) => {
            console.log(response.data)
        }).catch((error) => {
            this.setState({ alertShow: true, alertMessage: error.response.data.detail });
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state.ephemeralToken)
        let cookieDate = moment().add(120, 'm').toDate()
        let csrfToken = cookies.get('csrftoken')
        let authToken = cookies.get('auth_token')
        let data = {
            csrfmiddlewaretoken: csrfToken,
            code: this.state.code,
        }
        if (this.state.ephemeralToken) {
            data['ephemeral_token'] = this.state.ephemeralToken
            axios.post(`${base}/api/mfaAuth/login/code/`, 
            data, 
            {
                withCredentials: true, 
                headers: {                    
                    'Content-Type': 'application/json',
                    'Origin': prebase,
                    'Upgrade-Insecure-Requests': 1,
                }
            }).then((response) => {
                cookies.set('auth_token', response.data.auth_token, { path: '/', domain: cookieDomain, expires: cookieDate})
                this.loadUser();
            }).catch((error) => {
                this.setState({ alertShow: true, alertMessage: error.response.data.error });
            })
        } else {
            axios.post(`${base}/api/mfaAuth/${this.state.method}/activate/confirm/`, 
            data, 
            {
                withCredentials: true, 
                headers: {                    
                    'Content-Type': 'application/json',
                    'Origin': prebase,
                    'Upgrade-Insecure-Requests': 1,
                    'Authorization': `Token ${authToken}`,
                }
            }).then((response) => {
                this.setState({backupCodes: response.data.backup_codes, color: 'success', alertShow: true, alertMessage: "MFA successfully activated. Thank you"})
            }).catch((error) => {
                this.setState({ alertShow: true, alertMessage: error.response.data.error });
            })
        }
    }

    loadUser() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/isCare/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let user = response.data;
            let isCaregiver = false;
            for (let key in user) {
                isCaregiver = true;
            }
            if (isCaregiver) {
                this.props.history.push('/admin/patients/list');
            } else {
                this.props.history.push('/admin/forbidden');
            }
        }).catch(error => {
            this.setState({ alertShow: true, alertMessage: error.response.data });
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Row className="p-4">
                    <Col sm="12" md="6" className="text-center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src={Logo} alt="DAPS Health Logo" style={{maxHeight: '80%', maxWidth: '80%'}} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md='6' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <Card>
                            <Form onSubmit={this.handleSubmit}>
                                <CardHeader>
                                    <legend>Check Your {this.state.method !== 'sms_twilio' ? this.capitalize(this.state.method) : "Phone"}</legend>
                                </CardHeader>
                                <CardBody>
                                    <Alert className="m-2" color={this.state.color} isOpen={this.state.alertShow} toggle={() => { this.setState({ alertShow: false }) }}>
                                        {this.state.alertMessage}
                                    </Alert>
                                    <fieldset>
                                        <label htmlFor="mfa-code">MFA Code</label>
                                        <Input type="text" name="code" id="mfa-code" value={this.state.code} onChange={this.handleInputChange} autoFocus/>
                                    </fieldset>
                                    {this.state.backupCodes && 
                                    <div className="mb-4">
                                        <p>For your security we will ask that you please log in again.</p>
                                    </div>}
                                </CardBody>
                                {!this.state.backupCodes && <div className='mx-4 my-2'><Link to="#" onClick={this.requestNewCode}>Resend Code</Link></div>}
                                <CardFooter>
                                    {!this.state.backupCodes && <Button className="bg-success" color="primary" variant="raised">Submit</Button>}
                                    {(this.state.backupCodes && cookies.get('mfa_configure')) && <Button className="bg-success" color="primary" variant="raised" type="button" onClick={() => this.props.history.push('/')}>Return to Login</Button>}
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default MfaConfirm;
import React, {Component} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import {Form, Input, Button, Select, Option} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const moment = require('moment');
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientReferral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            patient: props.match.params.pid,
            caregiver: '',
            specialist: '',
            datetime: moment().format('MM/DD/YYYY HH:mm:ss'),
            specialistType: '',
            patients: [],
            caregivers: [],
            specialists: [],
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadUser();
        this.loadPatients();
        this.loadCaregivers();
        this.loadSpecialists();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        }, this.loadSpecialists);
    }

    loadUser() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/isCare/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let user = response.data;
            this.setState({
                caregiver: user.pk,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    loadPatients() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let dataset = [
                {value: '', label: ''}
            ];
            response.data.forEach((object) => {
                let item = {
                    value: object.pk,
                    label: `${object.fields.LastName}, ${object.fields.FirstName}`
                }
                dataset.push(item);
            })
            this.setState({
                patients: dataset,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    loadCaregivers() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/personnel/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let dataset = [
                {value: '', label: ''}
            ];
            response.data.forEach((object) => {
                let item = {
                    value: object.pk,
                    label: `${object.fields.LastName}, ${object.fields.FirstName}`
                }
                dataset.push(item);
            })
            this.setState({
                caregivers: dataset,
            })
        }).catch(error => {
            console.log(error);
        })
    }
    
    loadSpecialists() {
        let authToken = cookies.get('auth_token')
        let query = {
            type: this.state.type,
        }
        axios({
            method: 'get',
            url: `${base}/api/patients/specialist/?${qs.stringify(query)}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let dataset = [
                {value: '', label: ''}
            ];
            response.data.forEach((object) => {
                let item = {
                    value: object.pk,
                    label: `${object.fields.LastName}, ${object.fields.FirstName}`
                }
                dataset.push(item);
            })
            this.setState({
                specialists: dataset,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        let authToken = cookies.get('auth_token')
        let csrfToken = cookies.get('csrftoken');
        let referral = {
            csrfmiddlewaretoken: csrfToken,
            Personnel: this.state.caregiver,
            Patient: this.state.patient,
            Specialist: this.state.specialist,
            Type: this.state.specialistType,
            Time: this.state.datetime,
        }
        axios({
            method: 'post',
            url: `${base}/api/patients/referral/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: referral
        }).then(response => {
            console.log(response);
            this.props.history.push(`/admin/patients/${this.state.pid}/view`)
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Referral</legend>
                        </CardHeader>
                        <CardBody>
                            <fieldset>
                                <label htmlFor="patient-referral-patient">Patient</label>
                                <Select id="patient-referral-patient" name="patient" value={this.state.patient} onChange={this.handleInputChange}>
                                    {this.state.patients.map((item) => (
                                        <Option key={item.value} value={item.value} label={item.label} />
                                    ))}
                                </Select>
                                <label htmlFor="patient-referral-caregiver">Care Manager</label>
                                <Select id="patient-referral-caregiver" name="caregiver" value={this.state.caregiver} onChange={this.handleInputChange}>
                                    {this.state.caregivers.map((item) => (
                                        <Option key={item.value} value={item.value} label={item.label} />
                                    ))}
                                </Select>
                                <label htmlFor="patient-referral-type">Specialist Type</label>
                                <Select id="patient-referral-type" name="specialistType" value={this.state.specialistType} onChange={this.handleInputChange}>
                                    <Option value="" label="" />
                                    <Option value="Primary" label="Primary Care Practioner" />
                                    <Option value="Retina" label="Retina Care Specialist" />
                                    <Option value="Cardiologist" label="Cardiologist" />
                                    <Option value="Podiatrist" label="Podiatrist" />
                                    <Option value="Nephrologist" label="Nephrologist" />
                                    <Option value="Psychologist" label="Psychiatrist/Psychologist" />
                                </Select>
                                <label htmlFor="patient-referral-specialist">Specialist</label>
                                <Select id="patient-referral-specialist" name="specialist" value={this.state.specialist} onChange={this.handleInputChange}>
                                    {this.state.specialists.map((item) => (
                                        <Option key={item.value} value={item.value} label={item.label} />
                                    ))}
                                </Select>
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import Cookies from 'universal-cookie';
import Navbar from './navigation/navbar';
import Admin from './navigation/admin';
import Forbidden from './navigation/forbidden';
import FootConfig from './low-ext/footConfig';

import PatientHistory from './forms/patientHistory';
import PatientScreening from './forms/patientScreening';
import PatientVitals from './forms/patientVitals';
import PatientUpdate from './forms/patientUpdate';
import PatientUser from './forms/patientUser';
import PatientReferral from './forms/patientReferrals';
import RegisterNewPatient from './forms/registerNewPatient';

import PatientView from './patients/patientView';
import PatientList from './patients/patientList';
import HistoryList from './patients/historyList';
import HistoryView from './patients/historyView';
import VitalsList from './patients/vitalsList';
import VitalsView from './patients/vitalsView';
import ScreeningList from './patients/screeningList';
import ScreeningView from './patients/screeningView';

import PersonnelForm from './forms/personnelForm';
import LocationForm from './forms/locationForm';
import PersonnelList from './admin/personnelList';
import PersonnelView from './admin/personnelView';
import LocationList from './admin/locationList';
import LocationView from './admin/locationView';
import LetterForm from './forms/letterForm';
import LetterList from './patients/letterList';
import BlockForm from './forms/blockForm';
import ServiceForm from './forms/serviceForm';

import AppointmentView from './appointments/appointmentView';
import AppointmentForm from './appointments/appointmentForm';

import LogoutTimer from './authentication/logoutTimer';
import axios from 'axios';
import AboutPage from './dashboard/about';
import Dashboard from './dashboard/dashboard';
import LocationHourForm from './forms/hourForm';
import ChangePassword from './forms/changePatientPassword';
import SelectMfa from './authentication/selectMfa';
import AddDetails from './authentication/addDetails';
import MfaConfirm from './authentication/mfaConfirm';
import MfaQr from './authentication/MfaQr';

const cookies = new Cookies();
const mapStateToProps = state => {
    return { logged_in: state.logged_in }
}

const mql = window.matchMedia(`(min-width: 900px)`);
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;


class PrivateContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mql: mql,
            sidebarOpen: false,
            sidebarDocked: false,
            userLoaded: false,
        }
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.onExpandClick = this.onExpandClick.bind(this);
        this.loadUser()
    }

    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
        this.setState({ mql: mql, sidebarDocked: mql.matches, sidebarOpen: mql.matches });
    }

    componentWillUnmount() {
        this.state.mql.removeListener(this.mediaQueryChanged);
    }

    loadUser() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/isCare/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let user = response.data;
            localStorage.setItem('role', response.data.role);
            localStorage.setItem('user_id', user.pk);
            localStorage.setItem('user_name', `${user.first} ${user.last}: ${user.username}`)
            localStorage.setItem('logKey', user.username)
            this.setState({
                userLoaded: true,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    onExpandClick(event) {
        event.preventDefault();
        this.onSetSidebarOpen()
    }

    onSetSidebarOpen() {
        console.log('set');
        this.setState({
            sidebarOpen: !this.state.sidebarOpen,
            sidebarDocked: this.state.mql.matches && !this.state.sidebarDocked,
        });
    }

    mediaQueryChanged() {
        this.setState({ sidebarDocked: this.state.mql.matches, sidebarOpen: this.state.mql.matches });
    }

    render() {
        let sidebarContent = <Navbar location={this.props.location} history={this.props.history} />;
        let handle = <i className="fa fa-bars" />
        return (
            <div className="page h-100" >
                <Sidebar sidebar={sidebarContent} open={this.state.sidebarOpen} docked={this.state.sidebarDocked} onSetOpen={this.onSetSidebarOpen} touch={false} styles={{ sidebar: { width: '200px' }, content: { overflowY: 'hidden' } }}>
                    <a style={{ postion: 'fixed', top: 0, width: '20px', height: '100vh', float: 'left', backgroundColor: "#f4f4f4", textAlign: 'center' }} onClick={this.onExpandClick} href="#">
                        {handle}
                    </a>
                        {this.state.userLoaded && <div style={{postion: 'fixed', overflowY: 'auto', top: 0, width: 'calc(100% - 20px)', height: '100vh', float: 'right', marginLeft: 'auto', marginRight: 'auto'}}>
                        <br />
                        <Switch>
                            <Route exact path="/admin/forbidden" component={Forbidden} />
                            <Route exact path="/admin/patient/new" component={RegisterNewPatient} />
                            <Route exact path="/admin/history" component={PatientHistory} />
                            <Route exact path="/admin/screening" component={PatientScreening} />
                            <Route exact path="/admin/vitals" component={PatientVitals} />
                            <Route exact path="/admin/update" component={PatientUpdate} />
                            <Route exact path="/admin/letters/blocks" component={BlockForm} />
                            <Route exact path="/admin/appointments" component={AppointmentView} />
                            <Route exact path="/admin/appointments/new" component={AppointmentForm} />
                            <Route exact path="/admin/personnel/new" component={PersonnelForm} />
                            <Route exact path="/admin/personnel/list" component={PersonnelList} />
                            <Route exact path="/admin/personnel/:pid/view" component={PersonnelView} />
                            <Route exact path="/admin/personnel/:pid" component={PersonnelForm} />
                            <Route exact path="/admin/locations/new" component={LocationForm} />
                            <Route exact path="/admin/locations/list" component={LocationList} />
                            <Route exact path="/admin/locations/:lid/view" component={LocationView} />
                            <Route exact path="/admin/locations/:lid/services" component={ServiceForm} />
                            <Route exact path="/admin/locations/:lid" component={LocationForm} />
                            <Route exact path="/admin/patients/list" component={PatientList} />
                            <Route exact path="/admin/patients/:pid/view" component={PatientView} />
                            <Route exact path="/admin/patients/:pid/enroll" component={PatientUser} />
                            <Route exact path="/admin/patients/:pid/history/list" component={HistoryList} />
                            <Route exact path="/admin/patients/:pid/history/:hid" component={HistoryView} />
                            <Route exact path="/admin/patients/:pid/vitals/list" component={VitalsList} />
                            <Route exact path="/admin/patients/:pid/vitals/:vid" component={VitalsView} />
                            <Route exact path="/admin/patients/:pid/screening/list" component={ScreeningList} />
                            <Route exact path="/admin/patients/:pid/screening/:sid" component={ScreeningView} />
                            <Route exact path="/admin/patients/:pid/footpoints" component={FootConfig} />
                            <Route exact path="/admin/patients/:pid/change-password" component={ChangePassword} />
                            <Route exact path="/admin/patients/:pid/letter/new" component={LetterForm} />
                            <Route exact path="/admin/patients/:pid/letter/list" component={LetterList} />
                            <Route exact path="/admin/patients/:pid/letter/:lid" component={LetterForm} />
                            <Route exact path="/admin/patients/:pid/referral/new" component={PatientReferral} />
                            <Route exact path="/admin" component={Admin} />
                            <Route exact path="/admin/dash" component={Dashboard} />
                            <Route exact path="/admin/about" component={AboutPage} />
                            <Route exact path="/admin/locations/hour/new/:lid" component={LocationHourForm}/>
                            <Route exact path="/admin/enable-mfa" component={SelectMfa} />
                            <Route exact path="/admin/add-details" component={AddDetails} />
                            <Route exact path="/admin/mfa-confirm" component={MfaConfirm} />
                            <Route exact path="/admin/mfa-qr" component={MfaQr} />
                        </Switch>
                        <br />
                        {/* <LogoutTimer /> */}
                    </div>}
                </Sidebar>
            </div>
        )
    }
}

export default connect(mapStateToProps)(PrivateContainer);
import React, { Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, Col, Row, TabContent,TabPane,Nav, NavItem, NavLink} from 'reactstrap';
import axios from 'axios';
import {Button,Select,Option,Textarea, Form, Input} from 'muicss/react';
import Cookies from 'universal-cookie';
import FileSaver from 'file-saver';
import moment from 'moment';

const prebase = window.location.protocol + '//' + window.location.host;
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;
const cookies = new Cookies();

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: [],
            history:[],
            user:{},            
            activeTab: 'recent',
            resolution:[],
            role: localStorage.getItem('role'),
            user_id: localStorage.getItem('user_id'),
            threshold: 30,
            endDate: moment().format('YYYY-MM-DD')
        };
        this.changeSeen  = this.changeSeen.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleNoteChange = this.handleNoteChange.bind(this);
        this.downloadAlerts = this.downloadAlerts.bind(this);
        this.loadAlerts();
    }

    loadAlerts(){
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/isCare/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                user: response.data,
            });
            let all_time='';
            // if (response.data.role === 'caremgr') {
            //     all_time = '?all_time=true';
            // }
            let authToken = cookies.get('auth_token')
            axios({
                method: 'get',
                url: `${base}/api/patients/temp/alertsCare/${response.data.pk}/${all_time}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
            }).then(response => {
                this.setState({
                    alerts: response.data.recent,
                    history:response.data.history,
                    resolution:response.data.resolution,
                })
            }).catch(error => {
                console.log(error);
            })
        }).catch(error => {
            console.log(error);
        })

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    changeSeen(pk,seen,resolution,notes){
        let csrfToken = cookies.get('csrftoken');
        var data= {}
        const key = "notes-"+pk

        if((notes == '' || this.state[key] == '' ) && seen==true && (this.state[pk] == undefined || this.state[pk]=='No resolution') && resolution == "No resolution"){
            alert("Please select a valid resolution")
        }
        else{
            data = {"pk":pk,"seen":seen,"notes":this.state[key]}
            if (seen==false){
                data["resolution"] = "No resolution"
            }
            else if(this.state[pk] == undefined){
                data["resolution"] = resolution
            }
            else{
                data["resolution"] = this.state[pk]
            }
            if (data["resolution"] == 'No resolution' && (notes != '' || this.state[key] != '' )){
                data["seen"] = false
            }
            let authToken = cookies.get('auth_token')
            axios({
                method: 'put',
                url: `${base}/api/patients/temp/alerts/${pk}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data:data,
            }).then(response => {
                this.loadAlerts()
            }).catch(error => {
                console.log(error);
            })
           
        }
    }

    handleInputChange(event){
        const key = event.target.name;
        var newState = {};
        newState[key] = event.target.value;
        this.setState(newState);
    }

    handleNoteChange(event){
        const key = "notes-"+event.target.name;
        var newState = {};
        newState[key] = event.target.value;
        this.setState(newState);
    }

    downloadAlerts() {
        let authToken = cookies.get('auth_token')
        let caregiver = ''
        if (this.state.role !== 'admin') {
            caregiver = `&caregiver=${this.state.user_id}`
        }
        axios({
            method: 'get',
            url: `${base}/api/temperature/alerts/csv/?threshold=${this.state.threshold}&endDate=${this.state.endDate}${caregiver}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            responseType: 'blob',
        }).then((response) => {
            FileSaver(response.data, `daps_health_alerts_${this.state.threshold}_from_${this.state.endDate}.xlsx`)
        });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col md="4">
                        <Input type="number" name="threshold" id="threshold" value={this.state.threshold} onChange={this.handleInputChange} />  
                        <label htmlFor="threshold">Days Back</label>
                    </Col>
                    <Col md="4">
                        <Input type="date" name="endDate" id="endDate" value={this.state.endDate} onChange={this.handleInputChange} />  
                        <label htmlFor="endDate">From</label>
                    </Col>
                    <Col md="4">
                        <Button onClick={this.downloadAlerts} variant="raised" color="primary">Download Alerts</Button>
                    </Col>
                </Row>
                <Card>
                    <CardHeader>
                        <h2 className="float-left">{this.state.role === 'admin' && 'Admin '}Dashboard</h2>
                        {this.state.user.role === 'admin' && <b className="float-right">Showing alerts from all locations from past 2 days.</b>}
                        {this.state.user.role === 'caremgr' && <b className="float-right">Showing alerts from all assigned patients for past 2 days.</b>}
                    </CardHeader>
                    <CardBody>
                     <Nav tabs>
                        <NavItem>
                            <NavLink className={this.state.activeTab === 'recent' ? 'active' : ''} onClick={() => {this.toggle('recent')}}>Unresolved Alerts</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={this.state.activeTab === 'all' ? 'active' : ''} onClick={() => {this.toggle('all')}}>Resolved Alerts</NavLink>
                        </NavItem>
    
                     </Nav>      
                     <TabContent activeTab ={this.state.activeTab}>
                         <TabPane tabId="recent"> 
                            {this.state.alerts.map((alert) => (
                               <Card key={alert.pk} body outline color = {alert.flag ? 'danger':''} > 
                               
                                    <CardBody color = "danger">
                                        <Row>   
                                            <Col md={4}>
                                                <p className="h4">
                                                    <a href={`/admin/patients/${alert.Patient__pk}/view`} >{` ${alert.Patient__FirstName} ${alert.Patient__LastName} `}</a>
                                                    <br />
                                                    <small>
                                                    {alert.Patient__CellPhone ? <span>Cell: {alert.Patient__CellPhone}<br /></span>: ""}
                                                    {alert.Patient__HomePhone ? <span>Home: {alert.Patient__HomePhone}<br /></span> : ""}
                                                    {alert.Patient__WorkPhone ? <span>Work: {alert.Patient__WorkPhone}<br /></span> : ""}
                                                    {alert.Patient__EmergencyContact ? <span>Significant Other: {alert.Patient__EmergencyContact}<br /></span> : ""}
                                                    </small>
                                                </p>
                                            </Col>
                                            <Col md={4}>
                                                <p className="h4 ">{`${alert.Text} on ${alert.date} `}{alert.Reoccur ===0 ? '': "has been happening for the past " +alert.Reoccur +" days" }</p>
                                            </Col>
                                            <Col md={4}>       
                                                <Form inline={true} className="float-right">
                                                    <Select defaultValue={alert.Resolution} name={alert.pk} id={alert.pk} onChange={this.handleInputChange} className="mr-3">
                                                        {this.state.resolution.map((resolution) =>
                                                            <Option key={resolution} value={resolution} label={resolution} />
                                                        )}
                                                    </Select>
                                                    <Button size="small" variant="raised" bsstyle="primary" color="primary" type="submit" onClick={() => this.changeSeen(alert.pk, true, alert.Resolution,alert.Notes)}>Resolve</Button>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <label className = "h4" htmlFor={`alert-${alert.pk}-note`}>Notes:</label>
                                                <Select defaultValue={alert.Notes} name={`notes-${alert.pk}`} id={`alert-${alert.pk}-note`} onChange ={this.handleInputChange} style={{width: '100%'}}>
                                                    <Option value="" label="" />
                                                    <Option value="Bedrest" label="Complete Bedrest" />
                                                    <Option value="Cast" label="Get into Total Contact Cast" />
                                                    <Option value="Specialist" label="Go see your Specialist" />
                                                    <Option value="NoContact" label="Cannot Contact Patient" />
                                                </Select>
                                            </Col>
                                        </Row>
                                        <p className="h6 text-right">{alert.Caregiver__FirstName ? `Changed by ${alert.Caregiver__FirstName} ${alert.Caregiver__LastName} ` : ""} {alert.update ? ` on ${alert.update.substring(0, 19)} ` : ""}</p>
                                    </CardBody>
                                </Card>
                            ))}
                        </TabPane>
                        <TabPane tabId="all"> 
                            {this.state.history.map((alert) => (
                                <Card body outline key={alert.pk}> 
                                    <CardBody>
                                        <Row>   
                                            <Col md={4}>
                                            {alert.flag ? 'flag':''}
                                                <p className="h4">
                                                    <a href={`/admin/patients/${alert.Patient__pk}/view`} >{` ${alert.Patient__FirstName} ${alert.Patient__LastName} `}</a>
                                                    <br />
                                                    <small>
                                                    {alert.Patient__CellPhone ? <span>Cell: {alert.Patient__CellPhone}<br /></span>: ""}
                                                    {alert.Patient__HomePhone ? <span>Home: {alert.Patient__HomePhone}<br /></span> : ""}
                                                    {alert.Patient__WorkPhone ? <span>Work: {alert.Patient__WorkPhone}<br /></span> : ""}
                                                    {alert.Patient__EmergencyContact ? <span>Significant Other: {alert.Patient__EmergencyContact}<br /></span> : ""}
                                                    </small>
                                                </p>
                                            </Col>
                                            <Col md={4}>
                                                <p className="h4">{`${alert.Text} on ${alert.date}`}</p>
                                            </Col>
                                            <Col md={4}>
                                            <Form inline={true} className="float-right">
                                                    <Select defaultValue={alert.Resolution} name={alert.pk} id={alert.pk} onChange={this.handleInputChange} className="mr-3">
                                                        {this.state.resolution.map((resolution) =>
                                                            <Option key={resolution} value={resolution} label={resolution}></Option>
                                                        )}
                                                    </Select>
                                                    <Button size="small" variant="raised" className="bg-danger" color="danger" type="submit" onClick ={()=> this.changeSeen(alert.pk,false)}>Unresolve</Button>
                                                </Form>                                            
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                            <label className = "h4" htmlFor={`alert-${alert.pk}-note`}>Notes:</label>
                                                <Select defaultValue={alert.Notes} name={`notes-${alert.pk}`} id={`alert-${alert.pk}-note`} onChange ={this.handleInputChange} style={{width: '100%'}}>
                                                    <Option value="" label="" />
                                                    <Option value="Bedrest" label="Complete Bedrest" />
                                                    <Option value="Cast" label="Get into Total Contact Cast" />
                                                    <Option value="Specialist" label="Go see your Specialist" />
                                                    <Option value="NoContact" label="Cannot Contact Patient" />
                                                </Select>
                                            </Col>
                                        </Row>
                                            <p className="h6 text-right">{alert.Caregiver__FirstName ? `Changed by ${alert.Caregiver__FirstName} ${alert.Caregiver__LastName} `:""} {alert.update ? ` on ${alert.update.substring(0,19)} ` :""}</p>
                                    </CardBody>
                                </Card>
                            ))}
                        </TabPane>
                    </TabContent>    
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </Container>
        )
    }
}


import React, {Component} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Alert} from 'reactstrap';
import {Form, Input, Button} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from './../../assets/DAPSwhitelogo.png';

const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            new_password: '',
            new_password_repeat: '',
            valid: false,
            match: false,
            alertShow: false,
            alertMessage: '',
            color: 'danger',
            passwordComplexity: 0,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let passwordComplexity = this.state.passwordComplexity;
        if (name === 'new_password') {
            if (value.length < 8) {
                passwordComplexity = 0;
            } else {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasNumbers = /\d/.test(value);
                const hasNonAlphas = /\W/.test(value);
                passwordComplexity = 1 + hasUpperCase + hasLowerCase + hasNumbers + hasNonAlphas;
            }
        }
        this.setState({
            [name]: value,
            passwordComplexity: passwordComplexity,
        }, this.checkPassword);
    }

    checkPassword() {
        const match = (this.state.new_password !== ''  && this.state.new_password_repeat !== '' && this.state.new_password === this.state.new_password_repeat);
        this.setState({
            match: match,
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.state.match || this.state.passwordComplexity < 4) {
            this.setState({ alertShow: true, alertMessage: 'Passwords must match and be more than 8 characters in length, and contain a at a combination of at least three of the following: uppercase letters, lowercase letters, numbers or special characters.' });
        } else {
            let authToken = cookies.get('auth_token')
            let csrfToken = cookies.get('csrftoken');
            let passwords = {
                new_password: this.state.new_password,
                new_password_repeat: this.state.new_password_repeat,
                csrfmiddlewaretoken: csrfToken
            }
            axios({
                method: 'post',
                url: `${base}/api/auth/change-password/${this.state.pid}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: passwords
            }).then(response => {
                console.log(response);
                this.props.history.push(`/admin/patients/${this.state.pid}/view`)
            }).catch(error => {
                console.log(error);
            })
        }
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Update Patient Password</legend>
                        </CardHeader>
                        <CardBody>
                            <Alert className="m-2" color={this.state.color} isOpen={this.state.alertShow} toggle={() => { this.setState({ alertShow: false }) }}>
                                {this.state.alertMessage}
                            </Alert>
                            <fieldset>
                                <span className="float-right fa fa-check-circle text-danger">Spaces in passwords will be removed</span>
                                <br />
                                <label htmlFor="patient-enroll-password">New Password</label>
                                <Input type="password" name="new_password" id="patient-enroll-password" value={this.state.new_password} onChange={this.handleInputChange} required={true} />
                                <p>Complexity requirements: All passwords must be at least 8 characters, and contain a at a combination of at least three of the following: uppercase letters, lowercase letters, numbers or special characters.</p>
                                <p>Complexity: <span style={{color: this.state.passwordComplexity < 2 ? 'red' : (this.state.passwordComplexity < 4 ? 'orange' : 'green')}}>{this.state.passwordComplexity < 2 ? 'Poor' : (this.state.passwordComplexity < 4 ? 'Moderate' : 'Strong')}</span></p>
                                <label htmlFor="patient-enroll-password-two">Confirm New Password</label>
                                <span className="float-right fa fa-check-circle text-danger" hidden={this.state.match}>Passwords must match</span>
                                <Input type="password" name="new_password_repeat" id="patient-enroll-password-two" value={this.state.new_password_repeat} onChange={this.handleInputChange} required={true} />
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit" disabled={(this.state.match === false || this.state.passwordComplexity < 4)}>Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}
import React, { Component } from 'react';
import {
    Checkbox, Form, Input
} from 'muicss/react';
import {
    Card, CardBody, CardHeader, CardFooter,
    Button, Alert
} from 'reactstrap';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

/**
 * @name FootConfig
 * @desc Component for lower extremity. Handles displays of alerts and patient charts in relation to the data.
 */
class FootConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            r_great_toe: true,
            r_metatarsal_1: true,
            r_metatarsal_2_4: true,
            r_metatarsal_5: true,
            r_medial_plantar: true,
            r_heel: true,
            l_great_toe: true,
            l_metatarsal_1: true,
            l_metatarsal_2_4: true,
            l_metatarsal_5: true,
            l_medial_plantar: true,
            l_heel: true,
            pvdR: 0.0,
            pvdL: 0.0,
            Thermometer: '',
            pid: props.match.params.pid,
            method: 'post',
            alertShow: false,
            alertStyle: 'success',
            alertMessage: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        if (props.match.params.pid) this.loadFootpoints(props.match.params.pid);

    }

    loadFootpoints(pid) {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/temperature/temp/footpoints/${pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            console.log(response);
            if (response.data.fields.length > 0) {
                let data = response.data.fields[0];
                data['method'] = 'put'
                this.setState(data);
            }
        }).catch(error => {
            console.error(error);
        })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    onChangeText(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault()
        let authToken = cookies.get('auth_token')
        let csrfToken = cookies.get('csrftoken');
        if (this.state.pid) {
            axios({
                method: this.state.method,
                url: `${base}/api/temperature/temp/footpoints/${(this.state.method == 'post') ? '' : this.state.pid + '/'}`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                data: this.state,
                withCredentials: true,
            }).then(response => {
                this.setState({
                    alertShow: true,
                    alertMessage: 'Footpoints saved.',
                    alertStyle: 'success'
                });
                console.log(response.data);
                this.props.history.push(`/admin/patients/${this.state.pid}/view`)
            }).catch(error => {
                this.setState({
                    alertShow: true,
                    alertMessage: 'Could not save foot configuration. Please report this bug via the form on the bottom right.',
                    alertStyle: 'danger'
                })
                console.error(error.response.data);
            })
        }
    }

    render() {
        return (
            <div className="p-3">
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>
                                Active Footpoints
                            </legend>
                        </CardHeader>
                        <CardBody>
                            <small>Active footpoints for patient. Disabled points indicate partial or bk amputation.</small>
                            <hr />
                            <div className="p-3">
                                <Alert color={this.state.alertStyle} isOpen={this.state.alertShow} toggle={() => this.setState({ alertShow: false })}>
                                    {this.state ? this.state.alertMessage : null}
                                </Alert>
                            </div>
                            <Input type="text" name="Thermometer" label="Thermometer Device ID" value={this.state.Thermometer || ""} onChange={this.onChangeText} />
                            <hr />
                            <h4>Right Foot</h4>
                            <fieldset>
                                <Checkbox name="r_great_toe" label="Big Toe" checked={this.state.r_great_toe} onChange={this.onChange.bind(this)} />
                                <Checkbox name="r_metatarsal_1" label="Metatarsal 1" checked={this.state.r_metatarsal_1} onChange={this.onChange.bind(this)} />
                                <Checkbox name="r_metatarsal_2_4" label="Metatarsals 2-4" checked={this.state.r_metatarsal_2_4} onChange={this.onChange.bind(this)} />
                                <Checkbox name="r_metatarsal_5" label="Metatarsal 5" checked={this.state.r_metatarsal_5} onChange={this.onChange.bind(this)} />
                                <Checkbox name="r_medial_plantar" label="Medial Plantar" checked={this.state.r_medial_plantar} onChange={this.onChange.bind(this)} />
                                <Checkbox name="r_heel" label="Heel" checked={this.state.r_heel} onChange={this.onChange.bind(this)} />
                            </fieldset>
                            <hr />
                            <h4>Left Foot</h4>
                            <fieldset>
                                <Checkbox name="l_great_toe" label="Big Toe" checked={this.state.l_great_toe} onChange={this.onChange.bind(this)} />
                                <Checkbox name="l_metatarsal_1" label="Metatarsal 1" checked={this.state.l_metatarsal_1} onChange={this.onChange.bind(this)} />
                                <Checkbox name="l_metatarsal_2_4" label="Metatarsals 2-4" checked={this.state.l_metatarsal_2_4} onChange={this.onChange.bind(this)} />
                                <Checkbox name="l_metatarsal_5" label="Metatarsal 5" checked={this.state.l_metatarsal_5} onChange={this.onChange.bind(this)} />
                                <Checkbox name="l_medial_plantar" label="Medial Plantar" checked={this.state.l_medial_plantar} onChange={this.onChange.bind(this)} />
                                <Checkbox name="l_heel" label="Heel" checked={this.state.l_heel} onChange={this.onChange.bind(this)} />
                            </fieldset>
                            <hr />
                            <h4>PVD Adjustment</h4>
                            <div className="mb-4">
                            <small>
                                Algorithm accounts for difference in average foot temperature if patient has PVD. Increasing a foot PVD value will add or subtract to that foot's value.
                            </small>
                            </div>
                            <Input type="number" className="my-4" step="0.1" name="pvdL" label="PVD - Left Foot" value={this.state.pvdL || 0} onChange={this.onChangeText} />
                            <Input type="number" className="my-1" step="0.1" name="pvdR" label="PVD - Right Foot" value={this.state.pvdR || 0} onChange={this.onChangeText} />
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" color="primary" type="submit">Save</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </div>
        )
    }
}

export default FootConfig;
import React, {Component} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import {Form, Input, Button,Option,Select} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from './../../assets/DAPSwhitelogo.png';
import {DateTimePicker } from 'react-widgets';
import { relativeTimeThreshold } from 'moment';

const cookies = new Cookies();
const moment = require('moment');
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class LocationHourForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lid: props.match.params.lid,
            startTime: undefined,
            endTime: undefined,
            day: 'Monday',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
            let csrfToken = cookies.get('csrftoken');
            let authToken = cookies.get('auth_token')
            console.log(this.state.startTime)
            let hours = {
                csrfmiddlewaretoken: csrfToken,
                Location: this.state.lid,
                Day: this.state.day,
                StartTime: moment(this.state.startTime).format('h:mm:ss a'),
                EndTime: moment(this.state.endTime).format('h:mm:ss a'),
            }
            axios({
                method: 'post',
                url: `${base}/api/caregivers/locations/hours/${this.state.lid}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: hours
            }).then(response => {
                this.props.history.push(`/admin/locations/${this.state.lid}/view`);
            }).catch(error => {
                console.log(error);
            })
    }

    render() {

        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Add Hours</legend>
                        </CardHeader>
                        <CardBody>
                            <fieldset>
                                <Select label="Day" value={this.state.day} onChange={(e) => { this.setState({ day: e.target.value }) }}>
                                    <Option value="Monday" label="Monday" />
                                    <Option value="Tuesday" label="Tuesday" />
                                    <Option value="Wednesday" label="Wednesday" />
                                    <Option value="Thursday" label="Thursday" />
                                    <Option value="Friday" label="Friday" />
                                    <Option value="Saturday" label="Saturday" />
                                    <Option value="Sunday" label="Sunday" />

                                </Select>
                                <label htmlFor="hour-start-time">Start Time</label>
                                    <DateTimePicker 
                                    date={false}
                                    value = {this.state.startTime}
                                    onChange={value => {this.setState({startTime: value})}}
                                    name="startTime"
                                    />
                                <label htmlFor="hour-end-time">End Time</label>
                                    <DateTimePicker
                                    date={false}
                                    value = {this.state.endTime}
                                    onChange={value => {this.setState({endTime: value})}}
                                    name = "sndTime"
                                    />                            
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}
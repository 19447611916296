import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import 'react-widgets/dist/css/react-widgets.css';
import './index.css';
import 'muicss/dist/css/mui.min.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-quill/dist/quill.snow.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
// Font awesome global import
import '@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-regular.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';

import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

Moment.locale('en');
momentLocalizer();


ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();

import React, { Component } from 'react';
import Logo from '../../assets/DAPSdarklogo.png';
import ReactToPrint from 'react-to-print';
import ReactQuill from 'react-quill';
import {
    Col, Row,
    Card, CardHeader, CardBody,
    UncontrolledTooltip,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Select, Option } from 'muicss/react';
import axios from 'axios';
import moment from 'moment';

const cookies = new Cookies();

const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class LetterForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            printing: false,
            pid: props.match.params.pid,
            lid: props.match.params.lid,
            carePlan: '',
            letterType: 'patient-risk',
            atRisk: (props.location.state && props.location.state['AtRisk']) ? 'true' : 'false',
            blocks: {},
            highPriority: [],
            mediumPriority: [],
            lowPriority: [],
            flags: {},
            dangerFlags: props.location.state || {}
        }
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        this.loadPatient();
        this.loadBlocks();
        if (this.state.lid) this.loadLetter();
    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                patient: response.data[0],
                patientName: response.data[0].fields.FirstName + ' ' + response.data[0].fields.LastName
            });
        }).catch(error => {
            console.error(error);
            this.props.history.push("/admin/patients/list/")
        })
    }

    loadLetter() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/letter/${this.state.lid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                carePlan: response.data[0].Text,
                atRisk: response.data[0].AtRisk
            });
        }).catch(error => {
            console.error(error);
            this.props.history.push("/admin/patients/list/")
        })
    }

    loadBlocks() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/block/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let blocks = {}
            response.data.forEach(block => {
                blocks[block.type] = block.text;
            });
            this.setState({ blocks });
        }).catch(error => {
            console.error(error);
        })
    }

    onClick(name) {
        let update = {
            carePlan: this.state.carePlan + this.state.blocks[name],
            flags: this.state.flags
        };
        update.flags[name] = true;
        this.setState(update);
    }

    handleSubmit() {
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        let data = {
            Patient: this.state.pid,
            Text: this.state.carePlan,
            AtRisk: this.state.atRisk,
            Suicidal: this.state.flags['Depression'],
            RestPain: this.state.flags['Rest Pain'],
            Claudication: this.state.flags['Claudication'],
            Ulcer: this.state.flags['Ulcer'],
            Infected: this.state.flags['Infected'],
            PVD: this.state.flags['PVD']
        };
        axios({
            method: 'post',
            url: `${base}/api/patients/letter/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: data,
        }).then(response => {
            this.props.history.push(`/admin/patients/${this.state.pid}/view/`);
        }).catch(error => {
            console.error(error);
        })
    }

    render() {
        let blocks = {
            highPriority: [],
            mediumPriority: [],
            lowPriority: []
        };
        //Depression block
        if (this.state.blocks['Depression']) {
            let priority = this.state.dangerFlags['Depression'] ? 'mediumPriority' : 'lowPriority';
            if (this.state.dangerFlags['SelfHarm']) priority = 'highPriority';
            blocks[priority].push(
                <div href="#" name="Depression" className="p-3" onClick={(e) => this.onClick('Depression')}>
                    Depression
                <br />
                    {this.state.flags["Depression"] && <span className="float-right fa fa-2x fa-check text-success"></span>}
                    {!this.state.flags["Depression"] && this.state.dangerFlags['SelfHarm'] 
                    && <span><span className="float-right fa fa-exclamation-circle" id="depression-danger"></span><UncontrolledTooltip target="depression-danger">Patient has been flagged as possibly SUICIDAL. Make appropriate referral.</UncontrolledTooltip></span>
                    }
                    <small>If patient has flagged score for depression screening.</small>
                </div>
            )
        }

        //Rest pain Block
        if (this.state.blocks['Rest Pain']) {
            let priority = this.state.dangerFlags['RestPain'] === "yes" ? 'mediumPriority' : 'lowPriority'
            blocks[priority].push(
                <div href="#" name="RestPain"  className="p-3" onClick={(e) => this.onClick('Rest Pain')}>
                    Rest Pain
                    <br />
                    {this.state.flags["Rest Pain"] && <span className="float-right fa fa-2x fa-check text-success"></span>}
                    <small>If patient has leg or foot pains while at rest.</small>
                </div>
            )
        }

        //Intermittent Claudication block
        if (this.state.blocks['Claudication']) {
            blocks['mediumPriority'].push(
                <div href="#" name="Claudication" className="p-3" onClick={(e) => this.onClick('Claudication')}>
                    Intermittent Claudication
                    <br />
                    {this.state.flags["Claudication"] && <span className="float-right fa fa-2x fa-check text-success"></span>}
                    {!this.state.flags["Claudication"] && this.state.dangerFlags['Claudication'] && <span className="float-right fa fa-exclamation-circle"></span>}
                    <small>If patient has a histor of claudication</small>
                </div>
            )
        }

        //Ulcer block
        if (this.state.blocks['Ulcer']) {
            blocks['lowPriority'].push(
                <div href="#" name="Ulcer"  className="p-3" onClick={(e) => this.onClick('Ulcer')}>
                    Ulcer
                    <br />
                    {this.state.flags["Ulcer"] && <span className="float-right fa fa-2x fa-check text-success"></span>}
                    {!this.state.flags["Ulcer"] && this.state.dangerFlags['Ulcer'] && <span className="float-right fa fa-exclamation-circle"></span>}
                    <small>If patient has an ulcer or may have an ulcer.</small>
                </div>
            )
        }

        //Infected ulcer block
        if (this.state.blocks['Infected']) {
            blocks['mediumPriority'].push(
                <div href="#" name="Infected" className="p-3" onClick={(e) => this.onClick('Infected')}>
                    Infected Ulcer
                    <br />
                    {this.state.flags["Infected"] && <span className="float-right fa fa-2x fa-check text-success"></span>}
                    {!this.state.flags["Infected"] && this.state.dangerFlags['Infected'] && <span className="float-right fa fa-exclamation-circle"></span>}
                    <small>If patient has an existing ulcer that is infected.</small>
                </div>
            )
        }
        
        // //PVD block **REMOVED 8/28/2018**
        // if (this.state.blocks['PVD']) {
        //     blocks['lowPriority'].push(
        //         <div href="#" name="PVD"  className="p-3" onClick={(e) => this.onClick('PVD')}>
        //             PVD
        //             <br />
        //             {this.state.flags["PVD"] && <span className="float-right fa fa-2x fa-check text-success"></span>}
        //             {!this.state.flags["PVD"] && this.state.dangerFlags['PVD'] && <span className="float-right fa fa-exclamation-circle"></span>}
        //             <small>If patient has peripheral vascular disease in one foot.</small>
        //         </div>
        //     )
        // }

        return (
            <div className="m-3">
                <Row>
                    {!this.state.lid &&
                        <Col lg={3}>
                            <Card body outline color="primary" className="my-3">
                                <legend>Add to Letter</legend>
                                {blocks.highPriority.map((block, i) => (<div key={'block-high-' + i} className="btn-danger alert alert-danger my-1 p-0">{block}</div>))}
                                {blocks.mediumPriority.map((block, i) => (<div key={'block-medium-' + i} className=" btn-warning alert alert-warning my-1 p-0">{block}</div>))}
                                {blocks.lowPriority.map((block, i) => (<div key={'block-low-' + i} className="btn-primary alert alert-primary my-1 p-0">{block}</div>))}
                            </Card>
                            <Card body outline color="primary" className="my-3">
                                <legend>Add Appointments</legend>
                                {(this.state.appointments && this.state.appointments.length > 0) ? this.state.appointments : <span>No upcoming appointments found. <a href="/admin/appointments" className="text-success" target="_blank">Create appointment <span className="fa fa-plus"></span></a></span>}
                            </Card>
                        </Col>
                    }
                    <Col className="my-3">
                        <Card>
                            <CardHeader><legend>Letter Creation</legend></CardHeader>
                            <CardBody>
                                <div className="mb-3">
                                    <Select label="Letter Type" value={this.state.letterType} onChange={(e) => { this.setState({ letterType: e.target.value }) }}>
                                        <Option value='patient-risk' label='Risk Letter to Patient' />
                                        <Option value='doctor-risk' label='Risk Letter to Doctor' />
                                        <Option value='rationale' label='Rationale Letter for program' />
                                    </Select>
                                    {!this.state.lid &&
                                        <span>
                                            {
                                                (this.state.letterType == 'patient-risk' || this.state.letterType === 'doctor-risk') &&
                                                <Select label="Patient at Risk?" value={this.state.atRisk} onChange={(e) => { this.setState({ atRisk: e.target.value }) }}>
                                                    <Option value={'true'} label='At Risk' />
                                                    <Option value={'false'} label='Not At Risk' />
                                                </Select>
                                            }
                                            {
                                                (this.state.letterType === 'patient-risk' || this.state.letterType === 'doctor-risk') &&
                                                <ReactQuill
                                                    theme="snow"
                                                    className="text-dark"
                                                    value={this.state.carePlan}
                                                    onChange={(value) => { this.setState({ carePlan: value }) }}
                                                />
                                            }
                                            <span id="save-button">
                                                <button className="mui-btn mui-btn--primary pull-right" disabled={!this.state.printed} onClick={this.handleSubmit.bind(this)}>
                                                    Save & Quit
                                                </button>
                                            </span>
                                            {!this.state.printed && <UncontrolledTooltip target="save-button">Print letter before saving</UncontrolledTooltip>}
                                        </span>
                                    }

                                </div>
                                <hr />
                                {this.state.letterType && <div>
                                    <h3>Letter Preview</h3>
                                    <div style={{ border: "solid", borderColor: "#000000" }}>
                                        <ReactToPrint
                                            trigger={() => <a className="mui-btn mui-btn--primary float-right" href="#">Print <span className="fa fa-print" /></a>}
                                            content={() => this.componentRef}
                                            onBeforePrint={() => { this.setState({ printing: true }); }}
                                            onAfterPrint={() => { this.setState({ printed: true }); }}
                                            pageStyle="@page { margin: 68px; margin-bottom: 96px;} @media print { body { -webkit-print-color-adjust: exact;} }"
                                        />
                                        <div className="m-5">
                                            {this.state.letterType === 'patient-risk' &&
                                                <PatientRiskLetter
                                                    atRisk={this.state.atRisk === 'true'}
                                                    carePlan={this.state.carePlan}
                                                    visitDate={new Date().toDateString()}
                                                    patientName={this.state.patientName}
                                                    shortTerm={this.state.dangerFlags['shortTerm']}
                                                    ref={el => (this.componentRef = el)}
                                                />}
                                            {this.state.letterType === 'doctor-risk' &&
                                                <DoctorRiskLetter
                                                    atRisk={this.state.atRisk === 'true'}
                                                    visitDate={new Date().toDateString()}
                                                    carePlan={this.state.carePlan}
                                                    patientName={this.state.patientName}
                                                    shortTerm={this.state.dangerFlags['shortTerm']}
                                                    ref={el => (this.componentRef = el)}
                                                />}
                                            {this.state.letterType === 'rationale' &&
                                                <RationaleLetter
                                                    atRisk={true}
                                                    patientName={this.state.patientName}
                                                    ref={el => (this.componentRef = el)}
                                                />}
                                        </div>
                                    </div>
                                </div>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(LetterForm);

export class RationaleLetter extends Component {
    render() {
        return (
            <div className="text-dark" style={{ fontSize: '135%' }} >
                <img src={Logo} alt="DAPS Health Logo" style={{ height: '100px', marginBottom: '20px' }} />
                <p>Dear Doctor,</p>
                <p>
                    IMPORTANCE of a TEMPERATURE HOME MONITORING TECHNOLOGY TO
                    PREDICT AND PREVENT DIABETIC FOOT ULCERS
                </p>
                <p>
                    The importance of a home monitoring system to help reduce the devastating clinical and
                    financial impact due to diabetic lower extremity disease cannot be overstated. Significant
                    examples of the impact of diabetic lower extremity disease include:
                </p>
                <ol>
                    <li>Diabetics are 17 times more likely to develop gangrene</li>
                    <li>Diabetics are 30 times more likely to experience an amputation</li>
                    <li>Once an amputation occurs, a 2nd amputation occurs within 2 years in the majority
                    of cases</li>
                    <li>Within 5 years, 50% of this cohort dies</li>
                    <li>Up to 25% of all in-patient hospital days associated with diabetes are due to lower
                    extremity complications</li>
                    <li>75% of all admissions for a diabetic foot complication are readmitted within one
                    year for the same condition</li>
                    <li>5% to 9% die while hospitalized</li>
                    <li>19% require nursing home placement</li>
                </ol>
                <p>
                    Successful implementation of the temperature home monitoring technology will
                    significantly reduce these statistics
                </p>
                <h4>MECHANISM OF INJURY AND TREND LINES</h4>
                <p>
                    In order to accept the rationale for the use of a home temperature monitoring technology,
                    one must first understand the mechanism of injury that leads to the destruction of the
                    diabetic foot. Temperature biometric data provides constant assessment by patients and
                    nurses, as well as the patient’s primary care physicians and podiatrists.
                </p>
                <p>
                    A predictive awareness by the diabetic patient population of abnormal points of potential
                    ulceration or trend lines leading to breakdown of tissue will in turn allow for a proactive
                    approach to prevention that will eliminate the vast majority of lower extremity
                    complications in the diabetic population. This devastating complication is not
                    spontaneous. There is always a predictive warning, and thus the opportunity exists to use
                    the temperature monitoring technology and associated preventive tools to reduce primary
                    prevention and prevention of recurrence of acute/emergent episodes.
                </p>
                <p>
                    <b>
                        The mechanism of injury that leads to plantar ulceration can be articulated in a
                        simple formula:
                </b>
                </p>
                <p>
                    Neuropathy [insensitivity] + Abnormal Biomechanical Pressure → Inflammation [heat]
                    → Enzymatic Autolysis of Tissue [ulceration].
                    </p>
                <p>
                    An individual who has normal sensation, that is, can feel pain; will never ulcerate through
                    this mechanism because that individual will always protect the foot from continuous
                    walking, because the pain of the inflammation will be too great. A diabetic with
                    neuropathy will not feel the pain of the inflammation, and therefore will continue to walk
                    on the inflamed part, which ultimately results in ulcer development.
                    </p>
                <p>
                    Complications caused by this mechanism; inflammation leading to breakdown into
                    ulceration, can ALWAYS be predicted, and therefore prevented utilizing a <b>temperature
                    home monitoring technology</b>. Once this inflammation reaches a certain temperature
                    threshold, it is important to biomechanically off-load and rest the foot in order to reduce
                    the inflammation, which in turn reverses the mechanism of injury leading destruction of
                    tissue.
                    </p>
                <p>
                    Use of the temperature home monitoring methodology will predict a first acute event,
                    allow for accurate monitoring of an existing lesion, and will help prevent recurrence of
                    lesions that have previously developed.
                    </p>
                <h4>POTENTIAL CLINICAL IMPACT</h4>
                <p>
                    It is estimated that up to 20% of the diabetic population will have an urgent/emergent
                    event relating to their lower extremities. These include foot ulcers, infections, and
                    gangrene, which lead to multiple hospitalizations, surgeries and ultimately amputation. It
                    is known that recurrence rates among this cohort of diabetics approaches 75%.
                </p>
                <p>
                    Use of the combination of monitoring foot temperatures with preventive tools such as
                    protective shoes and inserts have shown significant outcomes in major payer diabetic
                    populations in the United States.
                </p>
                <p>
                    These outcomes, reported at the national meetings of the CDC Conference on Diabetes
                    Translations in New Orleans, and the American Diabetes Association in Philadelphia,
                    include:
                </p>
                <p>
                    Reduction in amputation rates by 70%
                    Reduction in hospitalization rates by 70%
                    Reduction in LOS by 65%
                </p>
                <p>
                    We appreciate your support in this very important predictive approach to reducing a
                    horrific complication, and request that you reinforce use of this important preventive
                    technology every time you and your staff see your patients who are enrolled in the DAPS
                    Health program. We will keep you closely apprised of your patients status, and will work
                    very closely with you for the benefit of this very needy patient population.
                </p>
                <p>
                    With warm personal regards,
                </p>
                <p>
                    Mark Sussman DPM
                    <br />
                    Chief Clinical Officer
                    <br />
                    DAPS Health
                    <br />
                </p>
            </div>
        )
    }
}

export class DoctorRiskLetter extends Component {
    render() {
        return (
            <div className="text-dark" style={{ fontSize: '135%' }} >
                <img src={Logo} alt="DAPS Health Logo" style={{ height: '100px', marginBottom: '20px' }} />
                <p>Dear Doctor,</p>
                <p>
                    {this.props.patientName} was seen on {moment(this.props.visitDate).format('MMM DD, YYYY')} for diabetic foot screening to determine if they are at risk for complications of the lower extremity associated with diabetes.
                    An education session regarding diabetic lower extremity disease is also planned for {this.props.patientName}.
                </p>
                <p>
                    Screening evaluation included:
                </p>
                <ul>
                    <li>Vibration threshold sensation meter to determine if you have lost the ability to feel to protect yourself from injury (neuropathy)</li>
                    <li>Skin Perfusion Pressure and Ankle brachial index to determine if peripheral vascular disease is present</li>
                    <li>Temperature (inflammation) monitor of extremities if neuropathy present</li>
                    <li>Visual inspection of lower extremities</li>
                </ul>
                {
                    this.props.atRisk ?
                        <div>
                            <p>
                                Based on our findings, {this.props.patientName} is presently <b style={{color: "red"}}><u>at risk</u></b> for foot complications and should
                                be seen for further follow-up for distribution of protective shoes and insoles, and temperature monitoring technology to help insure against acute episodes 
                                and mobile total contact cast to help reverse the mechanism of injury leading to tissue breakdown.
                            </p>
                            <p>
                                We will continue to follow {this.props.patientName}'s status closely.
                            </p>
                        </div> :
                        <div>
                            <p>
                                Based on our findings, {this.props.patientName} is presently <b style={{color: "red"}}><u>not at risk</u></b> for one of the mechanisms of
                                injury that leads to complications of the diabetic foot.
                            </p>
                            <p>
                                The next screening will be scheduled for <b>{this.props.shortTerm ? "six months" : "one year"}</b>. If any problems should arise before
                                then, please inform us. We will encourage {this.props.patientName} to do the same.
                            </p>
                        </div>
                }
                <p>
                    Thank you for the opportunity to share in the care of this most pleasant individual.
                </p>
                <p>
                    Sincerely,
                </p>
                <p>
                    Mark Sussman DPM
                    <br />
                    Chief Clinical Officer
                    <br />
                    DAPS Health
                    <br />
                </p>
                {this.props.carePlan &&
                    <div>
                        <hr />
                        <h3>Care Plan</h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: this.props.carePlan }}
                        />
                    </div>
                }
            </div>
        )
    }
}

export class PatientRiskLetter extends Component {
    render() {
        return (
            <div className="text-dark" style={{ fontSize: '135%' }} >
                <img src={Logo} alt="DAPS Health Logo" style={{ height: '100px', marginBottom: '20px' }} />
                <p>Dear {this.props.patientName},</p>
                <p>
                    You have had a diabetic foot evaluation and screening to determine if you are at risk for a
                    foot complication that may lead to hospitalization, surgery, and even amputation. <b> All OF
                    THESE CAN BE PREVENTED WITH GOOD FOLLOW-UP CARE AND
                    COMPLIANCE ON YOUR PART</b>
                </p>
                <p>
                    The elements of the screening included:
                </p>
                <ul>
                    <li>Vibration Threshold sensation meter – to determine if you have lost the ability to feel to protect yourself from injury (neuropathy)</li>
                    <li>Skin Perfusion Pressure and Ankle brachial index – to determine if you have
                        circulation problems (peripheral vascular disease)</li>
                    <li>Foot temperature monitoring – to determine if you are in danger for developing foot ulcers if neuropathy is present.</li>
                    <li>Foot visual exam – to determine if your skin has any openings that may cause infection.</li>
                </ul>
                <p>
                    If the screening exam shows any abnormality, <b>in almost all cases we can protect the
                    foot and prevent the complications that lead to hospitalization, surgery and
                    amputation.</b> If you have had any complications, often times we can help prevent the next
                    complication that may lead to further hospitalization and surgery.
                </p>
                {
                    this.props.atRisk ?
                        <div>
                            <p>
                                Your screening evaluation showed you to be <b style={{ color: "red" }}><u>at risk</u></b> for a complication of your foot.
                                Further evaluation is necessary and we will refer you to a podiatrist and/or other medical specialist who is highly trained
                                in prevention and treatment of diabetic foot disease.
                            </p>
                            <p>
                                We will make an appointment for you to distribute durable medical equipment including home monitoring technology 
                                to help prevent complications of lower extremity disease.
                            </p>
                        </div> :
                        <div>
                            <p>
                                Your screening evaluation showed you to be <b style={{color: "red"}}><u>not at risk</u></b> for a complication of your foot
                                at this time. Because of potential dangers in all feet of diabetics, you should see a
                                podiatrist and/or other medical specialist on a regular basis. We recommend that you be seen for another screening
                                evaluation in  <b>{this.props.shortTerm ? "six months" : "one year"}</b>. If you have any issues prior to that time, please do not hesitate to
                                let us know and we will be happy to direct you to the appropriate specialist.
                            </p>
                        </div>
                }
                <p>
                    A report of these findings will be sent to your primary care physician. Together, we are
                    committed to preventing complications from developing.
                </p>
                <p>
                    Thank you for allowing us to see you today. {this.props.atRisk && "We will be in close touch with you in the coming days and weeks and will help facilitate your continued care."}
                </p>
                <p>
                    Sincerely,
                </p>
                <p>
                    Mark Sussman DPM
                    <br />
                    Chief Clinical Officer
                    <br />
                    DAPS Health
                    <br />
                </p>
                {this.props.carePlan &&
                    <div>
                        <hr />
                        <h3>Care Plan</h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: this.props.carePlan }}
                        />
                    </div>
                }
            </div>
        )
    }
}
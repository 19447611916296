import React, { Component } from 'react';
import { Container, Card, CardHeader, CardBody, CardFooter, Alert } from 'reactstrap';
import { Form, Input, Button, Option, Select} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class PersonnelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLocations: [],
            locations: [],
            newLocation: '',
            FirstName: '',
            LastName: '',
            Address: '',
            HomePhone: '',
            CellPhone: '',
            WorkPhone: '',
            PrimaryEmail: '',
            username: '',
            password: '',
            alertColor: 'danger',
            alertMessage: '',
            Role: 'medtech',
            alertShow: false,
            pid: props.match.params.pid,
            passwordComplexity: 0,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.loadPersonnel = this.loadPersonnel.bind(this);
        this.getLocations();
        if (props.match.params.pid) this.loadPersonnel(props.match.params.pid);
    }

    getLocations() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/locations/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,

        }).then(response => {
            let locations = [];
            locations.push({pk: '', Name: ''});
            ;
            locations = locations.concat(response.data);
            if (response.data.length > 0) {
                this.setState({ allLocations:  locations });
            }
        }).catch(error => {
            console.error(error);
        });
    }

    loadLocations() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/caregiverLocation/${this.state.pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },withCredentials: true,
        }).then(response => {
            this.setState({
                locations: response.data[this.state.pid]
            });
        }).catch(error => {
            console.log(error);
        })
    }

    loadPersonnel(pid){
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/personnel/${pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            if (response.data.length > 0) {
                console.log(response.data[0].fields);
                this.setState(response.data[0].fields, this.loadLocations);

            }
        }).catch(error => {
            console.error(error);
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let passwordComplexity = this.state.passwordComplexity;
        if (name === 'password') {
            if (value.length < 8) {
                passwordComplexity = 0;
            } else {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasNumbers = /\d/.test(value);
                const hasNonAlphas = /\W/.test(value);
                passwordComplexity = 1 + hasUpperCase + hasLowerCase + hasNumbers + hasNonAlphas;
            }
        }
        this.setState({
            [name]: value,
            passwordComplexity: passwordComplexity,
        });
    }

    toggleCheckbox(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let set = this.state[name];
        set[value] = !set[value];
        this.setState({
            [name]: set,
        })
    }

    handleLocationInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let locations = this.state.locations;
        if (name === 'newLocation') {
            let allLocations = this.state.allLocations;
            let included = value === '';
            let locationIdx;
            allLocations.forEach((location, index) => {
                if (locations[location.pk] && location.pk == value) {
                    included = true;
                } else if(location.pk == value) {
                    locationIdx = index;
                }
            })
            if (!included) {
                locations[value] = allLocations[locationIdx];
            }
        }
        this.setState({
            [name]: value,
            locations: locations
        });
    }

    clearLocations(key) {
        let locations = this.state.locations;
        locations.forEach((location, idx) => {
            if (location.pk == key) {
                locations.splice(idx, 1);
            }
        })
        this.setState({
            locations: locations,
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        let newUser = {
            csrfmiddlewaretoken: csrfToken,
            username: this.state.username,
            password: this.state.password,
            email: this.state.PrimaryEmail
        }
        let locationsPK = [];
        this.state.locations.forEach(location => {
            locationsPK.push(location.pk);
        })
        let profile = {
            Locations: locationsPK,
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            HomePhone: this.state.HomePhone,
            CellPhone: this.state.CellPhone,
            WorkPhone: this.state.WorkPhone,
            Address: this.state.Address,
            PrimaryEmail: this.state.PrimaryEmail,
            Role: this.state.Role
        }

        if (!this.state.pid) {
            axios({
                method: 'post',
                url: `${base}/api/caregivers/user/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: newUser,
            }).then(response => {
                profile.User = response.data;
                axios({
                    method: 'post',
                    url: `${base}/api/caregivers/personnel/`,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken,
                        'Authorization': `Token ${authToken}`
                    },
                    withCredentials: true,
                    data: profile
                }).then(response => {
                    //TODO: Navigate to admin with alert props of success;
                    this.props.history.push(`/admin/personnel/${profile.User}/view`);
                }).catch(error => {
                    console.error(error);
                    this.setState({
                        alertMessage: error.response.data,
                        alertShow: true
                    });
                    window.scroll(0, 0);
                })
            }).catch(error => {
                console.error(error);
                this.setState({
                    alertMessage: error.response.data,
                    alertShow: true
                });
                window.scroll(0, 0);
            })
        } else {
            //Update user as there is an ID
            axios({
                method: 'put',
                url: `${base}/api/caregivers/personnel/${this.state.pid}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: profile
            }).then(response => {
                //TODO: Navigate to admin with alert props of success;
                this.props.history.push(`/admin/personnel/${this.state.pid}/view`);
            }).catch(error => {
                console.error(error);
                this.setState({
                    alertMessage: error.response.data,
                    alertShow: true
                });
                window.scroll(0, 0);
            })
        }
        
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <Alert color={this.state.alertColor || "danger"} isOpen={this.state.alertShow} toggle={() => this.setState({ alertShow: false })}>
                            {this.state.alertMessage}
                        </Alert>
                        <CardHeader>
                            <legend>Personnel</legend>
                        </CardHeader>
                        <CardBody>
                            <fieldset>
                                <legend>Personnel Information</legend>
                                <label htmlFor="personnel-new-first-name">First Name</label>
                                <Input type="text" name="FirstName" id="personnel-new-first-name" maxLength={50} value={this.state.FirstName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="personnel-new-last-name">Last Name</label>
                                <Input type="text" name="LastName" id="personnel-new-last-name" maxLength={50} value={this.state.LastName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="personnel-new-PrimaryEmail">PrimaryEmail</label>
                                <Input type="email" name="PrimaryEmail" id="personnel-new-PrimaryEmail" maxLength={50} value={this.state.PrimaryEmail} onChange={this.handleInputChange} />
                                <label htmlFor="personnel-new-address">Address</label>
                                <Input type="text" name="Address" id="personnel-new-address" maxLength={200} value={this.state.Address} onChange={this.handleInputChange} />
                                <br />
                                <fieldset>
                                    
                                    <legend>Telephone Numbers</legend>
                                    <label htmlFor="personnel-new-telephone-home">Home</label>
                                    <Input type="tel" name="HomePhone" id="personnel-new-telephone-home" maxLength={15} value={this.state.HomePhone} onChange={this.handleInputChange} />
                                    <label htmlFor="personnel-new-telephone-cell">Cell</label>
                                    <Input type="tel" name="CellPhone" id="personnel-new-telephone-cell" maxLength={15} value={this.state.CellPhone} onChange={this.handleInputChange} />
                                    <label htmlFor="personnel-new-telephone-work">Work</label>
                                    <Input type="tel" name="WorkPhone" id="personnel-new-telephone-work" maxLength={15} value={this.state.WorkPhone} onChange={this.handleInputChange} />
                                </fieldset>
                                <br />
                                <fieldset>
                                    <legend>Role</legend>
                                    <Select name="role-select" value={this.state.Role} onChange={(ev) => {this.setState({Role: ev.target.value})}}>
                                        <Option value='medtech' label='Medical Technician' />
                                        <Option value='reception' label='Reception' />
                                        <Option value='caremgr' label='Care Manager' />
                                        <Option value='admin' label='Administrator' />
                                        <Option value='dev' label='Developer' />
                                    </Select>
                                    </fieldset>
                                <fieldset>
                                    <legend>Locations</legend>
                                    <label htmlFor="staff-new-location">Location</label>
                                    <div>
                                        {this.state.locations.map(location => (
                                            <p key={location.pk}>{location.Name}<span className="float-right text-danger fa fa-times-circle" onClick={() => this.clearLocations(location.pk)} /></p>
                                        ))}
                                    </div>
                                    <Select name="newLocation" id="staff-new-location" value={this.state.newLocation} onChange={this.handleLocationInputChange} multiple={true}>
                                        {this.state.allLocations.map(location => {
                                            return (
                                                <Option key={"location" + location.pk} value={location.pk} label={location.Name} />
                                            )
                                        })}
                                    </Select>
                                </fieldset>
                            </fieldset>
                            {
                                this.state.pid ? null :  
                                <fieldset>
                                    <legend>Login Information</legend>
                                    <label htmlFor="personnel-username">Username</label>
                                    <Input required={true} type="text" name="username" id="personnel-username" value={this.state.username} onChange={this.handleInputChange} />
                                    <label htmlFor="personnel-password">Password</label>
                                    <Input required={true} type="password" name="password" id="personnel-password" value={this.state.password} onChange={this.handleInputChange} />
                                    <p>Complexity requirements: All passwords must be at least 8 characters, and contain a at a combination of at least three of the following: uppercase letters, lowercase letters, numbers or special characters.</p>
                                    <p>Complexity: <span style={{color: this.state.passwordComplexity < 2 ? 'red' : (this.state.passwordComplexity < 4 ? 'orange' : 'green')}}>{this.state.passwordComplexity < 2 ? 'Poor' : (this.state.passwordComplexity < 4 ? 'Moderate' : 'Strong')}</span></p>
                                </fieldset>
                            }

                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" color="primary" type="submit" disabled={this.state.passwordComplexity < 4}>Save</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}

export default withRouter(PersonnelForm);
import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Alert} from 'reactstrap';
import {Form, Input, Select, Option, Checkbox, Radio, Button, Textarea} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const moment = require('moment');
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientVitals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            patient: qs.parse(props.location.search, {ignoreQueryPrefix: true}).pid,
            vitals: qs.parse(props.location.search, {ignoreQueryPrefix: true}).vid,
            then: qs.parse(props.location.search, {ignoreQueryPrefix: true}).then,
            height: '',
            weight: '',
            rightSystolic: '',
            rightDiastolic: '',
            leftSystolic: '',
            leftDiastolic: '',
            rightBrachial: '',
            rightAnkle: '',
            rightCalculated: '',
            leftBrachial: '',
            leftAnkle: '',
            leftCalculated: '',
            rightThreshold: '',
            leftThreshold: '',
            rightFootGreatToe: '',
            rightFootMeta1: '',
            rightFootMeta2_4: '',
            rightFootMeta5: '',
            rightFootMedialPlantar: '',
            rightFootHeel: '',
            leftFootGreatToe: '',
            leftFootMeta1: '',
            leftFootMeta2_4: '',
            leftFootMeta5: '',
            leftFootMedialPlantar: '',
            leftFootHeel: '',
            rightFootDorsal1: '',
            rightFootDorsal2: '',
            rightFootPlantar1: '',
            rightFootPlantar2: '',
            rightFootPlantar3: '',
            rightFootPlantar4: '',
            rightFootPlantar5: '',
            rightFootPlantar6: '',
            leftFootDorsal1: '',
            leftFootDorsal2: '',
            leftFootPlantar1: '',
            leftFootPlantar2: '',
            leftFootPlantar3: '',
            leftFootPlantar4: '',
            leftFootPlantar5: '',
            leftFootPlantar6: '',
            avoidantHeart: 'No',
            history: '',
            pedorthist: '',
            a1c: '',
            creatinine: '',
            ldl: '',
            hdl: '',
            eyeLeft: '',
            eyeRight: '',
            basic: true,
            basicComp: false,
            bp: false,
            bpComp: false,
            vpt: false,
            vptComp: false,
            sw: false,
            swComp: false,
            abi: false,
            abiComp: false,
            hma: false,
            hmaComp: false,
            ped: false,
            pedComp: false,
            bw: false,
            bwComp: false,
            urine: false,
            urineComp: false,
            eye: false,
            eyeComp: false,
            complete: false,
            temp: false,
            tempComp: false,
            complete: false,
            errors: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveToSessionStorage = this.saveToSessionStorage.bind(this);
        this.stateFromSessionStorage = this.stateFromSessionStorage.bind(this);
        this.loadPatients();
        if (this.state.vitals) {
            this.loadVitals();
        }
    }

    componentDidMount() {
        if (!this.state.vitals) {
            this.stateFromSessionStorage();
        }
        window.addEventListener(
            'beforeunload', 
            this.saveToSessionStorage
        )
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveToSessionStorage
        )
    }

    saveToSessionStorage() {
        let data = JSON.stringify(this.state);
        sessionStorage.setItem(`vitals-form-patient-${this.state.patient}`, data);
    }

    stateFromSessionStorage() {
        let storage = sessionStorage.getItem(`vitals-form-patient-${this.state.patient}`);
        let data = JSON.parse(storage);
        for (let key in this.state) {
            try {
                this.setState({
                    [key]: data[key],
                })
            } catch (e) {
                this.setState({
                    [key]: this.state[key],
                })
            }
        }
    }

    loadPatients() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let patients = [];
            response.data.forEach((element) => {
                let patient = {
                    value: element.pk,
                    label: element.fields.LastName + ', ' + element.fields.FirstName,
                };
                patients.push(patient);
            });
            this.setState({
                patients: patients,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    loadVitals() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/vitals/${this.state.vitals}/show/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let vitals = response.data[0];
            let data = {
                patient: vitals.fields.patient,
                height: vitals.fields.height,
                weight: vitals.fields.weight,
                rightSystolic: vitals.fields.rightSystolic,
                rightDiastolic: vitals.fields.rightDiastolic,
                leftSystolic: vitals.fields.leftSystolic,
                leftDiastolic: vitals.fields.leftDiastolic,
                rightBrachial: vitals.fields.rightBrachial,
                rightAnkle: vitals.fields.rightAnkle,
                rightCalculated: vitals.fields.rightCalculated,
                leftBrachial: vitals.fields.leftBrachial,
                leftAnkle: vitals.fields.leftAnkle,
                leftCalculated: vitals.fields.leftCalculated,
                rightThreshold: vitals.fields.rightThreshold,
                leftThreshold: vitals.fields.leftThreshold,
                rightFootDorsal1: vitals.fields.rightFootDorsal1,
                rightFootDorsal2: vitals.fields.rightFootDorsal2,
                rightFootPlantar1: vitals.fields.rightFootPlantar1,
                rightFootPlantar2: vitals.fields.rightFootPlantar2,
                rightFootPlantar3: vitals.fields.rightFootPlantar3,
                rightFootPlantar4: vitals.fields.rightFootPlantar4,
                rightFootPlantar5: vitals.fields.rightFootPlantar5,
                rightFootPlantar6: vitals.fields.rightFootPlantar6,
                leftFootDorsal1: vitals.fields.leftFootDorsal1,
                leftFootDorsal2: vitals.fields.leftFootDorsal2,
                leftFootPlantar1: vitals.fields.leftFootPlantar1,
                leftFootPlantar2: vitals.fields.leftFootPlantar2,
                leftFootPlantar3: vitals.fields.leftFootPlantar3,
                leftFootPlantar4: vitals.fields.leftFootPlantar4,
                leftFootPlantar5: vitals.fields.leftFootPlantar5,
                leftFootPlantar6: vitals.fields.leftFootPlantar6,
                history: vitals.fields.history,
                avoidantHeart: vitals.fields.heart,
                pedorthist: vitals.fields.pedorthist,
                a1c: vitals.fields.a1c,
                creatinine: vitals.fields.creatinine,
                ldl: vitals.fields.ldl,
                hdl: vitals.fields.hdl,
                eyeLeft: vitals.fields.eyeLeft,
                eyeRight: vitals.fields.eyeRight,
                basic: false,
                basicComp: true,
                bp: false,
                bpComp: true,
                vpt: false,
                vptComp: true,
                sw: false,
                swComp: true,
                abi: false,
                abiComp: true,
                hma: false,
                hmaComp: true,
                ped: false,
                pedComp: true,
                bw: true,
                bwComp: false,
                urine: false,
                urineComp: true,
                eye: false,
                eyeComp: true,
                complete: true,
                temp: false,
                tempComp: true,
                errors: [],
                edit: true,
            }
            this.setState(data);
        }).catch(error => {
            console.log(error);
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    toggleCheckbox(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let step = name.replace('Comp', '');
        let next = 'basic';
        let errors = this.state.errors;
        switch(step) {
            case 'basic':
                if (this.state.height === '' || this.state.weight === '') {
                    let box = document.getElementById('patient-vitals-basic');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-basic');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'bp';
                break;
            case 'bp':
                if (this.state.rightSystolic === '' || this.state.rightDiastolic === '' || this.state.leftSystolic === '' || this.state.leftDiastolic === '') {
                    let box = document.getElementById('patient-vitals-bp');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-bp');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'vpt';
                break;
            case 'vpt':
                if (this.state.rightThreshold === '' || this.state.leftThreshold === '') {
                    let box = document.getElementById('patient-vitals-vpt');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-vpt');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                if (this.state.rightThreshold >= 25 || this.state.leftThreshold >= 25) {
                    next = 'temp';
                    break;
                }
                next = 'sw';
                break;
            case 'temp':
                if (this.state.rightFootGreatToe === '' || this.state.rightFootMeta1 === '' || this.state.rightFootMeta2_4 === '' || this.state.rightFootMeta5 === '' || this.state.rightFootMedialPlantar === '' || this.state.rightFootHeel === '' || this.state.leftFootGreatToe === '' || this.state.leftFootMeta1 === '' || this.state.leftFootMeta2_4 === '' || this.state.leftFootMeta5 === '' || this.state.leftFootMedialPlantar === '' || this.state.leftFootHeel === '') {
                    let box = document.getElementById('patient-vitals-temp');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-temp');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'sw';
                break;
            case 'sw':
                if (this.state.rightFootDorsal1 === '' || this.state.rightFootDorsal2 === '' || this.state.rightFootPlantar1 === '' || this.state.rightFootPlantar2 === '' || this.state.rightFootPlantar3 === '' || this.state.rightFootPlantar4 === '' || this.state.rightFootPlantar5 === '' || this.state.rightFootPlantar6 === '' || this.state.leftFootDorsal1 === '' || this.state.leftFootDorsal2 === '' || this.state.leftFootPlantar1 === '' || this.state.leftFootPlantar2 === '' || this.state.leftFootPlantar3 === '' || this.state.leftFootPlantar4 === '' || this.state.leftFootPlantar5 === '' || this.state.leftFootPlantar6 === '') {
                    let box = document.getElementById('patient-vitals-sw');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-sw');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'abi';
                break;
            case 'abi':
                if (this.state.leftAnkle === '' || this.state.leftBrachial === '' || this.state.leftCalculated === '' || this.state.rightAnkle === '' || this.state.rightBrachial === '' || this.state.rightCalculated === '' ) {
                    let box = document.getElementById('patient-vitals-abi');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-abi');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                if (this.state.leftCalculated <= 0.9 || this.state.rightCalculated <= 0.9 || this.state.leftCalculated >= 1.02 || this.state.rightCalculated >= 1.02) {
                    let ta = document.getElementById('patient-vitals-history');
                    let yes = document.getElementById('patient-vitals-avoidant-yes');
                    let no = document.getElementById('patient-vitals-avoidant-no');
                    ta.style.cssText = "color: red; border: 1px solid red;"
                    yes.style.cssText = "color: red; border: 1px solid red;"
                    no.style.cssText = "color: red; border: 1px solid red;"
                }
                next = 'hma';
                break;
            case 'hma': 
                if (this.state.history === '' || this.state.avoidantHeart === '') {
                    let box = document.getElementById('patient-vitals-hma');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-hma');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                if (this.state.leftCalculated <= 0.9 || this.state.rightCalculated <= 0.9 || this.state.leftCalculated >= 1.02 || this.state.rightCalculated >= 1.02) {
                    let ta = document.getElementById('patient-vitals-history');
                    let yes = document.getElementById('patient-vitals-avoidant-yes');
                    let no = document.getElementById('patient-vitals-avoidant-no');
                    ta.style.cssText = "color: red; border: 1px solid red;"
                    yes.style.cssText = "color: red; border: 1px solid red;"
                    no.style.cssText = "color: red; border: 1px solid red;"
                }
                next = 'ped';
                break;
            case 'ped': 
                if (this.state.pedorthist === '') {
                    let box = document.getElementById('patient-vitals-ped');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-ped');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'bw';
                break;
            case 'bw':
                if (this.state.a1c === '' || this.state.ldl === '' || this.state.hdl === '') {
                    let box = document.getElementById('patient-vitals-bw');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-bw');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'urine';
                break;
            case 'urine':
                if (this.state.creatinine === '') {
                    let box = document.getElementById('patient-vitals-urine');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-urine');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'eye';
                break;
            case 'eye': {
                if (this.state.eyeLeft === '' || this.state.eyeRight === '') {
                    let box = document.getElementById('patient-vitals-eye');
                    box.style.cssText = "color: red; border: 1px solid red;"
                    errors.push(step);
                } else {
                    let box = document.getElementById('patient-vitals-eye');
                    box.style.cssText = "color: inherit; border: none;"
                    errors = errors.filter(a => a !== step);
                }
                next = 'complete';
            }
            default:
                next = 'complete';
        }
        if (this.state.complete) {
            this.setState({
                [name]: !this.state[name],
                [step]: !this.state[step],
                errors: errors,
            })
        } else {
            this.setState({
                [name]: !this.state[name],
                [step]: !this.state[step],
                [next]: !this.state[next],
                errors: errors,
            })
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        let data = {
            csrfmiddlewaretoken: csrfToken,
            updated: moment().format('YYYY-MM-DD'),
            patient: this.state.patient,
            height: this.state.height,
            weight: this.state.weight,
            rightSystolic: this.state.rightSystolic,
            rightDiastolic: this.state.rightDiastolic,
            leftSystolic: this.state.leftSystolic,
            leftDiastolic: this.state.leftDiastolic,
            rightBrachial: this.state.rightBrachial,
            rightAnkle: this.state.rightAnkle,
            rightCalculated: this.state.rightCalculated,
            leftBrachial: this.state.leftBrachial,
            leftAnkle: this.state.leftAnkle,
            leftCalculated: this.state.leftCalculated,
            rightThreshold: this.state.rightThreshold,
            leftThreshold: this.state.leftThreshold,
            rightFootDorsal1: this.state.rightFootDorsal1,
            rightFootDorsal2: this.state.rightFootDorsal2,
            rightFootPlantar1: this.state.rightFootPlantar1,
            rightFootPlantar2: this.state.rightFootPlantar2,
            rightFootPlantar3: this.state.rightFootPlantar3,
            rightFootPlantar4: this.state.rightFootPlantar4,
            rightFootPlantar5: this.state.rightFootPlantar5,
            rightFootPlantar6: this.state.rightFootPlantar6,
            leftFootDorsal1: this.state.leftFootDorsal1,
            leftFootDorsal2: this.state.leftFootDorsal2,
            leftFootPlantar1: this.state.leftFootPlantar1,
            leftFootPlantar2: this.state.leftFootPlantar2,
            leftFootPlantar3: this.state.leftFootPlantar3,
            leftFootPlantar4: this.state.leftFootPlantar4,
            leftFootPlantar5: this.state.leftFootPlantar5,
            leftFootPlantar6: this.state.leftFootPlantar6,
            history: this.state.history,
            heart: this.state.avoidantHeart,
            pedorthist: this.state.pedorthist,
            a1c: this.state.a1c,
            creatinine: this.state.creatinine,
            ldl: this.state.ldl,
            hdl: this.state.hdl,
            eyeLeft: this.state.eyeLeft,
            eyeRight: this.state.eyeRight,
        }
        if (this.state.vitals) {
            axios({
                method: 'put',
                url: `${base}/api/patients/vitals/${this.state.vitals}/show/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: data
            }).then(response => {
                if (this.state.tempComp) {
                    let tempData = {
                        csrfmiddlewaretoken: csrfToken,
                        temperatures: {
                            '1': this.state.rightFootGreatToe,
                            '2': this.state.rightFootMeta1,
                            '3': this.state.rightFootMeta2_4,
                            '4': this.state.rightFootMeta5,
                            '5': this.state.rightFootMedialPlantar,
                            '6': this.state.rightFootHeel,
                            '7': this.state.leftFootGreatToe,
                            '8': this.state.leftFootMeta1,
                            '9': this.state.leftFootMeta2_4,
                            '10': this.state.leftFootMeta5,
                            '11': this.state.leftFootMedialPlantar,
                            '12': this.state.leftFootHeel,
                        }
                    }
                    axios({
                        method: 'post',
                        url: `${base}/api/patients/temp/${this.state.patient}/`,
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrfToken,
                            'Authorization': `Token ${authToken}`
                        },
                        withCredentials: true,
                        data: tempData,
                    }).then(response => {
                    }).catch(error => {
                        console.log(error);
                    })
                }
                sessionStorage.removeItem(`vitals-form-patient-${this.state.patient}`);
            }).catch(error => {
                console.log(error);
            }).then(() => {
                this.props.history.push(this.state.then ? this.state.then : `/admin/patients/${this.state.patient}/view`);
            })
        } else {
            axios({
                method: 'post',
                url: `${base}/api/patients/vitals/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: data
            }).then(response => {
                if (this.state.tempComp) {
                    let tempData = {
                        csrfmiddlewaretoken: csrfToken,
                        temperatures: {
                            '1': this.state.rightFootGreatToe,
                            '2': this.state.rightFootMeta1,
                            '3': this.state.rightFootMeta2_4,
                            '4': this.state.rightFootMeta5,
                            '5': this.state.rightFootMedialPlantar,
                            '6': this.state.rightFootHeel,
                            '7': this.state.leftFootGreatToe,
                            '8': this.state.leftFootMeta1,
                            '9': this.state.leftFootMeta2_4,
                            '10': this.state.leftFootMeta5,
                            '11': this.state.leftFootMedialPlantar,
                            '12': this.state.leftFootHeel,
                        }
                    }
                    axios({
                        method: 'post',
                        url: `${base}/api/patients/temp/${this.state.patient}/`,
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrfToken,
                            'Authorization': `Token ${authToken}`
                        },
                        withCredentials: true,
                        data: tempData,
                    }).then(response => {
                    }).catch(error => {
                        console.log(error);
                    })
                }
                sessionStorage.removeItem(`vitals-form-patient-${this.state.patient}`);
            }).catch(error => {
                console.log(error);
            }).then(() => {
                this.props.history.push(this.state.then ? this.state.then : `/admin/patients/${this.state.patient}/view`);
            })
        }
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Patient Vitals</legend>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <fieldset>
                                        <legend>Vitals Tasks</legend>
                                        <Checkbox name="basicComp" id="patient-vitals-basic" label="Height and Weight" value="completed" checked={this.state.basicComp} onChange={this.toggleCheckbox} disabled={!this.state.basic && !this.state.complete} />
                                        <Checkbox name="bpComp" id="patient-vitals-bp" label="Blood Pressure" value="completed" checked={this.state.bpComp} onChange={this.toggleCheckbox} disabled={!this.state.bp && !this.state.complete} />
                                        <Checkbox name="vptComp" id="patient-vitals-vpt" label="Vibratory Perception Threshold" value="completed" checked={this.state.vptComp} onChange={this.toggleCheckbox} disabled={!this.state.vpt && !this.state.complete} />
                                        <Checkbox name="tempComp" id="patient-vitals-temp" label="Intake Foot Temperatures (If Indicated)" value="completed" checked={this.state.tempComp} onChange={this.toggleCheckbox} disabled={!this.state.temp && !this.state.complete} />
                                        <Checkbox name="swComp" id="patient-vitals-sw" label="Semmes/Weinstein Monofilaments" value="completed" checked={this.state.swComp} onChange={this.toggleCheckbox} disabled={!this.state.sw && !this.state.complete} />
                                        <Checkbox name="abiComp" id="patient-vitals-abi" label="Ankle Brachial Index" value="completed" checked={this.state.abiComp} onChange={this.toggleCheckbox} disabled={!this.state.abi && !this.state.complete} />
                                        <Checkbox name="hmaComp" id="patient-vitals-hma" label="History and Medication Assessment" value="completed" checked={this.state.hmaComp} onChange={this.toggleCheckbox} disabled={!this.state.hma  && !this.state.complete} />
                                        <Checkbox name="pedComp" id="patient-vitals-ped" label="Pedorthist Sizing Appointment (If Indicated)" value="completed" checked={this.state.pedComp} onChange={this.toggleCheckbox} disabled={!this.state.ped && !this.state.complete} />
                                        <Checkbox name="bwComp" id="patient-vitals-bw" label="Blood Draw" value="completed" checked={this.state.bwComp} onChange={this.toggleCheckbox} disabled={!this.state.bw  && !this.state.complete} />
                                        <Checkbox name="urineComp" id="patient-vitals-urine" label="Urine Sample" value="completed" checked={this.state.urineComp} onChange={this.toggleCheckbox} disabled={!this.state.urine  && !this.state.complete} />
                                        <Checkbox name="eyeComp" id="patient-vitals-eye" label="Retinopathy Examination (Optional)" value="completed" checked={this.state.eyeComp} onChange={this.toggleCheckbox} disabled={!this.state.eye  && !this.state.complete} />
                                    </fieldset>
                                </Col>
                                <Col md="6">
                                    <fieldset hidden={!this.state.basic}>
                                        <legend>Basic Information</legend>
                                        <label htmlFor="patient-vitals-patient">Patient</label>
                                        <Select id="patient-vitals-patient" name="patient" value={this.state.patient} onChange={this.handleInputChange}>
                                            <Option value="" label="" />
                                            {
                                                this.state.patients.map((patient) => (
                                                    <Option key={patient.value} value={patient.value} label={patient.label} />
                                                ))
                                            }
                                        </Select>
                                        <label htmlFor="patient-vitals-height">Height</label>
                                        <Input type="text" name="height" id="patient-vitals-height" maxLength={15} value={this.state.height} onChange={this.handleInputChange} />
                                        <label htmlFor="patient-vitals-weight">Weight</label>
                                        <Input type="number" step="0.01" name="weight" id="patient-vitals-weight" value={this.state.weight} onChange={this.handleInputChange} />
                                    </fieldset>
                                    <fieldset hidden={!this.state.bp}>
                                        <legend>Blood Pressure</legend>
                                        <Row>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Right Side</legend>
                                                    <label htmlFor="patient-vitals-bpr-sys">Systolic</label>
                                                    <Input type="number" name="rightSystolic" id="patient-vitals-bpr-sys" value={this.state.rightSystolic} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-bpr-dias">Diastolic</label>
                                                    <Input type="number" name="rightDiastolic" id="patient-vitals-bpr-dias" value={this.state.rightDiastolic} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Left Side</legend>
                                                    <label htmlFor="patient-vitals-bpl-sys">Systolic</label>
                                                    <Input type="number" name="leftSystolic" id="patient-vitals-bpl-sys" value={this.state.leftSystolic} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-bpl-dias">Diastolic</label>
                                                    <Input type="number" name="leftDiastolic" id="patient-vitals-bpl-dias" value={this.state.leftDiastolic} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <fieldset hidden={!this.state.abi}>
                                        <legend>Ankle Brachial Index</legend>
                                        <Row>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Right Side</legend>
                                                    <label htmlFor="patient-vitals-abir-brach">Brachial</label>
                                                    <Input type="number" name="rightBrachial" id="patient-vitals-abir-brach" value={this.state.rightBrachial} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-abir-ankle">Ankle</label>
                                                    <Input type="number" name="rightAnkle" id="patient-vitals-abir-ankle" value={this.state.rightAnkle} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-abir-calc">Calculated ABI</label>
                                                    <Input type="number" step="0.01" name="rightCalculated" id="patient-vitals-abir-calc" value={this.state.rightCalculated} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Left Side</legend>
                                                    <label htmlFor="patient-vitals-abil-brach">Brachial</label>
                                                    <Input type="number" name="leftBrachial" id="patient-vitals-abil-brach" value={this.state.leftBrachial} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-abil-ankle">Ankle</label>
                                                    <Input type="number" name="leftAnkle" id="patient-vitals-abil-ankle" value={this.state.leftAnkle} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-abil-calc">Calculated ABI</label>
                                                    <Input type="number" step="0.01" name="leftCalculated" id="patient-vitals-abil-calc" value={this.state.leftCalculated} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <fieldset hidden={!this.state.vpt}>
                                        <legend>Biothesiometer</legend>
                                        <Row>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Right Side</legend>
                                                    <label htmlFor="patient-vitals-bior-threshold">Voltage</label>
                                                    <Input type="number" name="rightThreshold" id="patient-vitals-bior-threshold" value={this.state.rightThreshold} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Left Side</legend>
                                                    <label htmlFor="patient-vitals-biol-threshold">Voltage</label>
                                                    <Input type="number" name="leftThreshold" id="patient-vitals-biol-threshold" value={this.state.leftThreshold} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <fieldset hidden={!this.state.temp}>
                                        <legend>Foot Temperatures</legend>
                                        <Row>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Right Side</legend>
                                                    <label htmlFor="patient-vitals-tempr-gt">Big Toe</label>
                                                    <Input type="number" step="0.01" name="rightFootGreatToe" id="patient-vitals-tempr-gt" value={this.state.rightFootGreatToe} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-tempr-mth1">Metatarsal Head 1</label>
                                                    <Input type="number" step="0.01" name="rightFootMeta1" id="patient-vitals-tempr-mth1" value={this.state.rightFootMeta1} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-tempr-mth2-4">Metatarsal Heads 2-4</label>
                                                    <Input type="number" step="0.01" name="rightFootMeta2_4" id="patient-vitals-tempr-mth2-4" value={this.state.rightFootMeta2_4} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-tempr-mth5">Metatarsal Head 5</label>
                                                    <Input type="number" step="0.01" name="rightFootMeta5" id="patient-vitals-tempr-mth5" value={this.state.rightFootMeta5} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-tempr-mp">Medial Plantar</label>
                                                    <Input type="number" step="0.01" name="rightFootMedialPlantar" id="patient-vitals-tempr-mp" value={this.state.rightFootMedialPlantar} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-tempr-heel">Heel</label>
                                                    <Input type="number" step="0.01" name="rightFootHeel" id="patient-vitals-tempr-heel" value={this.state.rightFootHeel} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Left Side</legend>
                                                    <label htmlFor="patient-vitals-templ-gt">Big Toe</label>
                                                    <Input type="number" step="0.01" name="leftFootGreatToe" id="patient-vitals-templ-gt" value={this.state.leftFootGreatToe} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-templ-mth1">Metatarsal Head 1</label>
                                                    <Input type="number" step="0.01" name="leftFootMeta1" id="patient-vitals-templ-mth1" value={this.state.leftFootMeta1} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-templ-mth2-4">Metatarsal Heads 2-4</label>
                                                    <Input type="number" step="0.01" name="leftFootMeta2_4" id="patient-vitals-templ-mth2-4" value={this.state.leftFootMeta2_4} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-templ-mth5">Metatarsal Head 5</label>
                                                    <Input type="number" step="0.01" name="leftFootMeta5" id="patient-vitals-templ-mth5" value={this.state.leftFootMeta5} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-templ-mp">Medial Plantar</label>
                                                    <Input type="number" step="0.01" name="leftFootMedialPlantar" id="patient-vitals-templ-mp" value={this.state.leftFootMedialPlantar} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-templ-heel">Heel</label>
                                                    <Input type="number" step="0.01" name="leftFootHeel" id="patient-vitals-templ-heel" value={this.state.leftFootHeel} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <fieldset hidden={!this.state.sw}>
                                        <legend>Semmes/Weinstein Monofilaments</legend>
                                        <Row>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Right Side</legend>
                                                    <label htmlFor="patient-vitals-swmr-fd1">Foot Dorsal 1</label>
                                                    <Input type="number" step="0.01" name="rightFootDorsal1" id="patient-vitals-swmr-fd1" value={this.state.rightFootDorsal1} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swmr-fd2">Foot Dorsal 2</label>
                                                    <Input type="number" step="0.01" name="rightFootDorsal2" id="patient-vitals-swmr-fd2" value={this.state.rightFootDorsal2} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swmr-fp1">Foot Plantar 1</label>
                                                    <Input type="number" step="0.01" name="rightFootPlantar1" id="patient-vitals-swmr-fp1" value={this.state.rightFootPlantar1} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swmr-fp2">Foot Plantar 2</label>
                                                    <Input type="number" step="0.01" name="rightFootPlantar2" id="patient-vitals-swmr-fp2" value={this.state.rightFootPlantar2} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swmr-fp3">Foot Plantar 3</label>
                                                    <Input type="number" step="0.01" name="rightFootPlantar3" id="patient-vitals-swmr-fp3" value={this.state.rightFootPlantar3} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swmr-fp4">Foot Plantar 4</label>
                                                    <Input type="number" step="0.01" name="rightFootPlantar4" id="patient-vitals-swmr-fp4" value={this.state.rightFootPlantar4} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swmr-fp5">Foot Plantar 5</label>
                                                    <Input type="number" step="0.01" name="rightFootPlantar5" id="patient-vitals-swmr-fp5" value={this.state.rightFootPlantar5} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swmr-fp6">Foot Plantar 6</label>
                                                    <Input type="number" step="0.01" name="rightFootPlantar6" id="patient-vitals-swmr-fp6" value={this.state.rightFootPlantar6} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                            <Col sm="6">
                                                <fieldset>
                                                    <legend>Left Side</legend>
                                                    <label htmlFor="patient-vitals-swml-fd1">Foot Dorsal 1</label>
                                                    <Input type="number" step="0.01" name="leftFootDorsal1" id="patient-vitals-swml-fd1" value={this.state.leftFootDorsal1} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swml-fd2">Foot Dorsal 2</label>
                                                    <Input type="number" step="0.01" name="leftFootDorsal2" id="patient-vitals-swml-fd2" value={this.state.leftFootDorsal2} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swml-fp1">Foot Plantar 1</label>
                                                    <Input type="number" step="0.01" name="leftFootPlantar1" id="patient-vitals-swml-fp1" value={this.state.leftFootPlantar1} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swml-fp2">Foot Plantar 2</label>
                                                    <Input type="number" step="0.01" name="leftFootPlantar2" id="patient-vitals-swml-fp2" value={this.state.leftFootPlantar2} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swml-fp3">Foot Plantar 3</label>
                                                    <Input type="number" step="0.01" name="leftFootPlantar3" id="patient-vitals-swml-fp3" value={this.state.leftFootPlantar3} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swml-fp4">Foot Plantar 4</label>
                                                    <Input type="number" step="0.01" name="leftFootPlantar4" id="patient-vitals-swml-fp4" value={this.state.leftFootPlantar4} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swml-fp5">Foot Plantar 5</label>
                                                    <Input type="number" step="0.01" name="leftFootPlantar5" id="patient-vitals-swml-fp5" value={this.state.leftFootPlantar5} onChange={this.handleInputChange} />
                                                    <label htmlFor="patient-vitals-swml-fp6">Foot Plantar 6</label>
                                                    <Input type="number" step="0.01" name="leftFootPlantar6" id="patient-vitals-swml-fp6" value={this.state.leftFootPlantar6} onChange={this.handleInputChange} />
                                                </fieldset>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <fieldset hidden={!this.state.hma}>
                                        <Alert isOpen={this.state.leftCalculated <= 0.9 || this.state.rightCalculated <= 0.9 || this.state.leftCalculated >= 1.02 || this.state.rightCalculated >= 1.02} color="danger">Patient is at risk for heart attack or stroke. Please conduct a thorough medication analysis.</Alert>
                                        <legend>History and Medication Assessment</legend>
                                        <label htmlFor="patient-vitals-history">Notes</label>
                                        <Textarea name="history" id="patient-vitals-history" value={this.state.history} onChange={this.handleInputChange} />
                                        <label>Avoidant Heart Medication?</label>
                                        <Radio name="avoidantHeart" id="patient-vitals-avoidant-yes" label="Yes" value="Yes" checked={this.state.avoidantHeart === 'Yes'} onChange={this.handleInputChange} />
                                        <Radio name="avoidantHeart" id="patient-vitals-avoidant-no" label="No" value="No" checked={this.state.avoidantHeart === 'No'} onChange={this.handleInputChange} />
                                    </fieldset>
                                    <fieldset hidden={!this.state.ped}>
                                        <legend>Pedorthist Sizing Appointment</legend>
                                        <label htmlFor="patient-vitals-pedorthist">Notes</label>
                                        <Textarea name="pedorthist" id="patient-vitals-pedorthist" value={this.state.pedorthist} onChange={this.handleInputChange} />
                                    </fieldset>
                                    <fieldset hidden={!this.state.bw}>
                                        <legend>Blood Draw</legend>
                                        <label htmlFor="patient-vitals-a1c">Hemoglobin A1C</label>
                                        <Input type="number" step="0.01" name="a1c" id="patient-vitals-a1c" value={this.state.a1c} onChange={this.handleInputChange} />
                                        <label htmlFor="patient-vitals-ldl">LDL</label>
                                        <Input type="number" step="0.01" name="ldl" id="patient-vitals-ldl" value={this.state.ldl} onChange={this.handleInputChange} />
                                        <label htmlFor="patient-vitals-hdl">HDL</label>
                                        <Input type="number"step="0.01" name="hdl" id="patient-vitals-hdl" value={this.state.hdl} onChange={this.handleInputChange} />
                                    </fieldset>
                                    <fieldset hidden={!this.state.urine}>
                                        <legend>Urine Sample</legend>
                                        <label htmlFor="patient-vitals-creatinine">Creatinine</label>
                                        <Input type="number" step="0.01" name="creatinine" id="patient-vitals-creatinine" value={this.state.creatinine} onChange={this.handleInputChange} />
                                    </fieldset>
                                    <fieldset hidden={!this.state.eye}>
                                        <legend>Retinopathy Examination</legend>
                                        <label htmlFor="patient-vitals-eye-left">Left Eye</label>
                                        <Input type="text" name="eyeLeft" id="patient-vitals-eye-left" value={this.state.eyeLeft} onChange={this.handleInputChange} />
                                        <label htmlFor="patient-vitals-eye-right">Right Eye</label>
                                        <Input type="text" name="eyeRight" id="patient-vitals-eye-right" value={this.state.eyeRight} onChange={this.handleInputChange} />
                                    </fieldset>
                                    <div hidden={this.state.edit || !this.state.complete || this.state.errors.length !== 0} style={{textAlign: 'center', padding: '10%'}}>
                                        <i className="fa fa-check-circle fa-5x" style={{color: 'green', padding: '5%'}}></i>
                                        <h3>Tests Complete</h3>
                                        <p>Please submit your results by pressing the button at the bottom</p>
                                    </div>
                                    <div hidden={!this.state.complete || this.state.errors.length === 0} style={{textAlign: 'center', padding: '10%'}}>
                                        <i className="fa fa-times-circle fa-5x" style={{color: 'red', padding: '5%'}}></i>
                                        <h3>Some Tests Have Been Skipped</h3>
                                        <p>Please enter a value for all fields before continuing</p>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit" disabled={!this.state.complete} >Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}
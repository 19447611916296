import React, {Fragment, Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, Row, Col, UncontrolledTooltip} from 'reactstrap';
import axios from 'axios';
import { CloudWatchLogsClient, CreateLogGroupCommand, CreateLogStreamCommand, DescribeLogStreamsCommand, PutLogEventsCommand } from "@aws-sdk/client-cloudwatch-logs";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;


export default class PatientVitals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            vid: props.match.params.vid,
            patient: {fields: {}},
            vitals: {fields: {}},
            pageLoading: true,
        }
        this.loadPatient();
        this.writeLogs = this.writeLogs.bind(this)

    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.writeLogs(response.data[0])
            this.setState({
                patient: response.data[0],
            }, this.loadVitals);
        }).catch(error => {
            console.log(error);
        })
    }

    async writeLogs(patient) {
        const logKey = localStorage.getItem('logKey');
        const client = new CloudWatchLogsClient({region: 'us-east-1',   credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'us-east-1' },
            identityPoolId: 'us-east-1:671651f5-c5ab-497b-8966-5baae2389b1a',
          })})
        const logGroupName = 'daps_access_logs'
        const logStreamName = `access_logs_${logKey}`
        const logGroupCommand = new CreateLogGroupCommand({logGroupName: logGroupName})
        const logStreamCommand = new CreateLogStreamCommand({logGroupName: logGroupName, logStreamName: logStreamName})
        try {
            const createLogGroup = await client.send(logGroupCommand)
        } catch (error) {
            if (!error.name === 'ResourceAlreadyExistsException') {
                console.log('log group error', error)
            }
        } finally {
            try {
                const createLogStream = await client.send(logStreamCommand)
            } catch (error) {
                if (!error.name === 'ResourceAlreadyExistsException') {
                    console.log('log stream error', error)
                }
            } finally {
                try  {
                    const describeLogStreams = new DescribeLogStreamsCommand({logGroupName: logGroupName, logStreamNamePrefix: logStreamName, limit: 1})
                    const currentStreams = await client.send(describeLogStreams)
                    const sequenceToken = currentStreams.logStreams[0].uploadSequenceToken
                    const logEventCommand = new PutLogEventsCommand({logGroupName: logGroupName, logStreamName: logStreamName, logEvents: [{message: `user ${localStorage.getItem('user_name')} has accessed patient ${patient.fields.FirstName} ${patient.fields.LastName}: ${patient.fields.PatientId} vital records on ${window.location.href}`, timestamp: Date.now()}], sequenceToken: sequenceToken})
                    const data = await client.send(logEventCommand)
                } catch (error) {
                    console.log('log event error', error)
                }
            }
        }
    }

    loadVitals() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/vitals/${this.state.vid}/show/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                vitals: response.data[0],
                pageLoading: false,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        let patient = this.state.patient ? this.state.patient.fields : {};
        let vitals = this.state.vitals ? this.state.vitals.fields : {};
        return (
            <Fragment>
                {this.state.pageLoading ? 
                <Container></Container> :
                <Container>
                    <Card>
                        <CardHeader>
                            <h2 className="float-left">{patient.LastName + ', ' + patient.FirstName}</h2>
                        </CardHeader>
                        <CardBody>
                            <span className="h6 float-right">Last Updated: {vitals.updated}</span>
                            <h3>Patient Vitals</h3>
                            <div className="mx-4">
                                <Row>
                                    <Col md="6">
                                        <p className="h4">Height and Weight</p>
                                        <div className="ml-4">
                                            <p className="h5">Height: {vitals.height}</p>
                                            <p className="h5">Weight: {vitals.weight}</p>
                                        </div>
                                        <p className="h4">Blood Pressure</p>
                                        <div className="ml-4">
                                            <p className="h5">Right Side: {vitals.rightSystolic + '/' + vitals.rightDiastolic}</p>
                                            <p className="h5">Left Side: {vitals.leftSystolic + '/' + vitals.leftDiastolic}</p>
                                        </div>
                                        <p className="h4">Ankle Brachial Index</p>
                                        <div className="ml-4">
                                            <p className="h5">Right Side: {vitals.rightCalculated}</p>
                                            <p className="h5">Left Side: {vitals.leftCalculated}</p>
                                        </div>
                                        <p className="h4" style={{ color: (vitals.rightThreshold >= 25 || vitals.leftThreshold >= 25) ? "red" : null }}>Biothesiometer
                                            {(vitals.rightThreshold >= 25 || vitals.leftThreshold >= 25) &&
                                            <span className="ml-3 fa fa-exclamation-circle" id="biothesiometer-warning">
                                                <UncontrolledTooltip placement="top" target="biothesiometer-warning">
                                                    AT RISK: =&gt; 25 volts. Recommended to enroll in home technology program.
                                                </UncontrolledTooltip>
                                            </span>}
                                        </p>
                                        <div className="ml-4">
                                            <p className="h5" style={{ color: (vitals.rightThreshold >= 25) ? "red" : null }}>Right Side: {vitals.rightThreshold}</p>
                                            <p className="h5" style={{ color: (vitals.leftThreshold >= 25) ? "red" : null }}>Left Side: {vitals.leftThreshold}</p>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <p className="h4">History and Medication Assesment</p>
                                        <div className="ml-4">
                                            <p className="h5">Notes: {vitals.history}</p>
                                        </div>
                                        <p className="h4">Pedorthist Sizing</p>
                                        <div className="ml-4">
                                            <p className="h5">Notes: {vitals.pedorthist}</p>
                                        </div>
                                        <p className="h4">Lab Work Results</p>
                                        <div className="ml-4">
                                            <p className="h5">Hemoglobin A1C: {vitals.a1c}</p>
                                            <p className="h5">Creatinine: {vitals.creatinine}</p>
                                            <p className="h5">LDL: {vitals.ldl}</p>
                                            <p className="h5">HDL: {vitals.hdl}</p>
                                        </div>
                                        <p className="h4">Retinopathy Examination Results</p>
                                        <div className="ml-4">
                                            <p className="h5">Left Eye: {vitals.eyeLeft}</p>
                                            <p className="h5">Right Eye: {vitals.eyeRight}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <p className="h4">Semmes/Weinstein Monofilaments</p>
                                <div className="ml-4">
                                    <Row>
                                        <Col md="6">
                                            <p className="h5">Right Foot - Dorsal 1: {vitals.rightFootDorsal1}</p>
                                            <p className="h5">Right Foot - Dorsal 2: {vitals.rightFootDorsal2}</p>
                                            <p className="h5">Right Foot - Plantar 1: {vitals.rightFootPlantar1}</p>
                                            <p className="h5">Right Foot - Plantar 2: {vitals.rightFootPlantar2}</p>
                                            <p className="h5">Right Foot - Plantar 3: {vitals.rightFootPlantar3}</p>
                                            <p className="h5">Right Foot - Plantar 4: {vitals.rightFootPlantar4}</p>
                                            <p className="h5">Right Foot - Plantar 5: {vitals.rightFootPlantar5}</p>
                                            <p className="h5">Right Foot - Plantar 6: {vitals.rightFootPlantar6}</p>
                                        </Col>
                                        <Col md="6">
                                            <p className="h5">Left Foot - Dorsal 1: {vitals.leftFootDorsal1}</p>
                                            <p className="h5">Left Foot - Dorsal 2: {vitals.leftFootDorsal2}</p>
                                            <p className="h5">Left Foot - Plantar 1: {vitals.leftFootPlantar1}</p>
                                            <p className="h5">Left Foot - Plantar 2: {vitals.leftFootPlantar2}</p>
                                            <p className="h5">Left Foot - Plantar 3: {vitals.leftFootPlantar3}</p>
                                            <p className="h5">Left Foot - Plantar 4: {vitals.leftFootPlantar4}</p>
                                            <p className="h5">Left Foot - Plantar 5: {vitals.leftFootPlantar5}</p>
                                            <p className="h5">Left Foot - Plantar 6: {vitals.leftFootPlantar6}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Container>}
            </Fragment>
        )
    }
}
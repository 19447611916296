import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import {
    Card, CardHeader, CardBody,
    Row, Col,
    Nav, NavItem, Navbar,
    TabContent, TabPane, NavLink
} from 'reactstrap';
import {
    Select, Option
} from 'muicss/react';

import axios from 'axios';
import AppointmentForm from './appointmentForm';

import moment from 'moment-timezone';
import PatientInfoCard from './patientInfoCard';

import Cookies from 'universal-cookie';

const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;
const cookies = new Cookies();

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

const appointmentTypes = {
    "Shoe": "Shoe sizing and fitting.",
    "Initial": "Initial Appointment - screening and checkup.",
    "Tech": "Distribution of home monitoring technology.",
    "Cast": "Distribution of ambulatory total contact cast.",
    "Education": "Diabetes education session."
}

const serviceColors = {
    "Shoe": '#eb8258',
    "Initial": '#995d81',
    "Tech": '#52aa8a',
    "Cast": '#279af1',
    "Education": '#f2dc5d'
}

class AppointmentView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            locationFilter: '',
            personnelFilter: '',
            personnel: [],
            services: [],
            appointments: [],
            events: [],
            checkIns: [],
            formOpen: false,
            activeTab: 'calendar'
        }
        this.eventStyle = this.eventStyle.bind(this);
        this.EventAgenda = this.EventAgenda.bind(this);

    }

    componentDidMount() {
        this.getLocations();
        this.getCheckins();
    }

    getLocationData() {
        this.getServices();
        this.getPersonnel();
    }

    getLocations() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/locations/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,

        }).then(response => {
            if (response.data.length > 0) {
                this.setState({ locations: response.data, locationFilter: response.data[0].pk }, this.getLocationData);
            }
        }).catch(error => {
            console.error(error);
        });
    }

    getPersonnel() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/locations/${this.state.locationFilter}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            if (response.data.length > 0 && response.data[0].Staff) {
                let reduced = response.data[0].Staff.reduce((filtered, personnel) => {
                    // if (personnel.Role == 'caremgr')
                    if (personnel.Role !== 'reception')
                        filtered.push(personnel);
                    return filtered;
                }, []);
                this.setState({ personnel: reduced, personnelFilter: 'all' }, this.getAppointments);
            } else {
                this.setState({ personnel: [], events: [], appointments: [] });
            }
        }).catch(error => {
            console.error(error);
        });
    }

    getServices() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url:`${base}/api/caregivers/locations/services/${this.state.locationFilter}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            if (response.data.length > 0) {
                let services = [];
                response.data.map(service => {
                    for (let index = 0; index < service.Count; index++) {
                        services.push({
                            resourceId: service.Type + (index + 1),
                            resourceTitle: (<div style={{ background: serviceColors[service.Type], color: 'black'}}>{service.Type} {index+1}</div>),
                            data: service
                        })
                    }
                });
                this.setState({
                    services: services,
                    serviceConfig: response.data
                })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    getAppointments() {
        let url = `${base}/api/patients/appointments/`;
        if (this.state.personnelFilter == 'all')
            url += (Number.parseInt(this.state.locationFilter) > 0) ? 'location/' + this.state.locationFilter + '/' : '';
        else
            url += (Number.parseInt(this.state.personnelFilter) > 0) ? 'caregiver/' + this.state.personnelFilter + '/' : '';

        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let events = [];
            let bucket = {}
            response.data.map(event => {
                let fields = event.fields;
                let key = fields.Type + '-' + new Date(fields.TimeIn).getTime();
                
                let resourceId = bucket[key] || 1;
                bucket[key] = resourceId + 1; 

                events.push({
                    start: moment(fields.TimeIn).toDate(),
                    end: moment(fields.TimeOut).toDate(),
                    title: fields.Type,
                    data: event,
                    resourceId: fields.Type + resourceId
                })
            });
            this.setState({
                appointments: response.data,
                events: events
            })
        }).catch(error => {
            console.error(error);
        });
    }

    getCheckins() {
        let authToken = cookies.get('auth_token')
        let url = `${base}/api/patients/appointments/waiting/`;
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                checkIns: response.data,
            })
        }).catch(error => {
            console.error(error);
        })
    }

    postCheckout(e, checkIn) {
        e.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let data = {
            csrfmiddlewaretoken: csrfToken,
            Type: checkIn.fields.Type,
            Patient: checkIn.fields.Patient,
            Location: checkIn.fields.Location,
            Caregiver: checkIn.fields.Caregiver,
            TimeIn: moment(checkIn.fields.TimeIn).format(),
            TimeOut: moment(checkIn.fields.TimeOut).format(),
            CheckInTime: moment(checkIn.fields.CheckInTime).format(),
            CheckOutTime: moment(this.state.TimeIn).format()
        }
        let checkInId = checkIn.pk;

        let authToken = cookies.get('auth_token')
        axios({
            method: 'put',
            url: `${base}/api/patients/appointments/${checkInId + '/'}`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: data
        }).then(response => {
            this.getCheckins();
        }).catch(error => {
            console.error(error.response.data);
        });
    }

    onFormSuccess() {
        this.setState({ formOpen: false, openAppointment: false, patientId: undefined, patientSelect: false }, this.getAppointments);
    }

    
    openEvent(e) {
        // May want to change to using fully controlled component
        this.setState({ openAppointment: e.data, formOpen: true });

    }

    eventStyle(event, start, end, isSelected) {
        let style = {
            color: 'black'
        }
        if (event.data.fields.Type == "Initial") style.backgroundColor = '#995d81'
        if (event.data.fields.Type == "Shoe") style.backgroundColor = '#eb8258'
        if (event.data.fields.Type == "Tech") style.backgroundColor = '#52aa8a'
        if (event.data.fields.Type == "Cast") style.backgroundColor = '#279af1'
        if (event.data.fields.Type== "Education") style.backgroundColor = '#f2dc5d';
        if (this.state.openAppointment && event.data.pk == this.state.openAppointment.pk) style.backgroundColor = '#00CF71'

        return {
            style: style,
        }
    }

    EventAgenda(event) {
        return (
            <div style={{cursor: 'pointer'}} className="100w" onClick={() => { this.openEvent(event.event) }}>
                <em>{event.event.data.fields.Patient.LastName}</em>
                <br />
                {event.title}
            </div>
        )
    }

    CustomEventInfo(event) {
        return (
            <span>
                {event.event.data.fields.Patient.LastName + ", " + event.event.data.fields.Patient.FirstName}
            </span>
        )
    }

    render() {
        return (
            <div className="AppointmentView px-3">
                <Card>
                    <CardHeader>
                        <h2 className="float-left">Appointments</h2>
                        {this.state.activeTab === 'calendar' && <button className="mui-btn mui-btn--raised float-right" onClick={() => { this.setState({ openAppointment: undefined, formOpen: true }) }}>Add Appointment</button>}
                    </CardHeader>
                    <CardBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink active={this.state.activeTab === 'calendar'} onClick={() => { this.setState({ activeTab: 'calendar' }) }}>Calendar</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={this.state.activeTab === 'checkin'} onClick={() => { this.setState({ activeTab: 'checkin' }) }}>Check Ins</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="calendar" className="py-2">
                                <Row>
                                    <Col md={4}>
                                        <Select label="Location" value={this.state.locationFilter} onChange={(e) => { this.setState({ locationFilter: e.target.value, locationName: e.target.label }, this.getLocationData) }}>
                                            {this.state.locations.map(location => {
                                                return (
                                                    <Option key={"location" + location.pk} value={location.pk} label={location.Name} />
                                                )
                                            })}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className="flex-row-reverse">
                                    {
                                        this.state.formOpen &&
                                        <Col style={{ minWidth: '240px' }}>
                                            <div className="p-3">
                                                <AppointmentForm
                                                    serviceConfig={this.state.serviceConfig}
                                                    locationId={this.state.locationFilter}
                                                    personnelId={this.state.personnelFilter}
                                                    personnel={this.state.personnel}
                                                    locationLabel={this.state.locationLabel}
                                                    appointment={this.state.openAppointment}
                                                    onSubmit={this.onFormSuccess.bind(this)}
                                                    onCancel={() => { this.setState({ formOpen: false, openAppointment: undefined, patientId: undefined }) }}
                                                />
                                            </div>
                                        </Col>
                                    }
                                    <Col>
                                        <BigCalendar
                                            selectable={true}
                                            defaultDate={new Date()}
                                            scrollToTime = {new Date()}
                                            defaultView="day"
                                            showMultiDayView
                                            views={['month', 'day', 'agenda']}
                                            step={5}
                                            timeslots={12}
                                            resources={this.state.services}
                                            resourceIdAccessor="resourceId"
                                            resourceTitleAccessor="resourceTitle"
                                            onSelectEvent={(e) => { this.openEvent(e) }}
                                            events={this.state.events}
                                            style={{ height: '65vh' }}
                                            components={{
                                                event: this.CustomEventInfo,
                                                agenda: {
                                                    event: this.EventAgenda
                                                }
                                            }}
                                            formats={{
                                                eventTimeRangeFormat: () => {return ""}
                                            }}
                                            eventPropGetter={this.eventStyle}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="checkin" className="py-2">
                                {this.state.checkIns.map(checkIn => {
                                    return (
                                        <div key={"checkIn" + checkIn.pk}>
                                            <Card outline color="primary">
                                                <Row>
                                                    <Col>
                                                        <PatientInfoCard patientId={checkIn.fields.Patient} mini />
                                                    </Col>
                                                    <Col>
                                                        <Card outline color="success" style={{ height: "100%" }}>
                                                            <CardHeader>Appointment Info<button className="text-button" style={{ float: "right" }} onClick={(e) => { this.postCheckout(e, checkIn) }}>Check Out</button></CardHeader>
                                                            <CardBody>
                                                                Check in Time: {moment(checkIn.fields.CheckInTime).format("h:mm a - DD MMM YYYY")}
                                                                <br / >
                                                                Appointment Type: {appointmentTypes[checkIn.fields.Type]}
                                                                <hr />
                                                                Scheduled Time In: {moment(checkIn.fields.TimeIn).format("h:mm a - DD MMM YYYY")}
                                                                <br />
                                                                Scheduled Time Out: {moment(checkIn.fields.TimeOut).format("h:mm a - DD MMM YYYY")}
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    )
                                })}
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default AppointmentView

import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, Alert} from 'reactstrap';
import {Form, Input, Textarea, Button, Select, Option} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const qs = require('qs');
const moment = require('moment');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class ServiceForm extends Component {
    constructor(props) {
        super(props);
        let types = [
            {value: '', label: ''},
            {value: 'Initial', label: 'Intial Appointment'},
            {value: 'Shoe', label: 'Shoe sizing and fitting'},
            {value: 'Tech', label: 'Distribution of home monitoring technology'},
            {value: 'Cast', label: 'Distribution of ambulatory total contact cast'},
            {value: 'Education', label: 'Diabetes education sessions'},
        ]
        this.state = {
            types: types,
            alertMessage: '',
            alertStyle: 'danger',
            alertShow: false,
            loading: false,
            location: props.match.params.lid,
            services: [],
            type: '',
            length: '',
            gap: '',
            count: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadServices();
    }

    loadServices() {
        let location = this.state.location;
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/locations/services/${location}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            console.log(response);
            this.setState({
                services: response.data,
            })
        }).catch(error => {
            console.log(error);
            this.setState({
                text: '',
            })
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name === 'type') {
            let services = this.state.services;
            let service = {};
            services.forEach(item => {
                if (item.Type === value) {
                    service = item;
                }
            })
            this.setState({
                [name]: value,
                length: service.Length,
                gap: service.Gap,
                count: service.Count,
            })
        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        let service = {
            csrfmiddlewaretoken: csrfToken,
            Location: this.state.location,
            Type: this.state.type,
            Length: this.state.length,
            Gap: this.state.gap,
            Count: this.state.count,
        }
        let method = 'post';
        this.state.services.forEach((item) => {
            if (item.Type === service.Type) {
                method = 'put';
            }
        })
        axios({
            method: method,
            url: `${base}/api/caregivers/locations/services/${this.state.location}/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: service
        }).then(response => {

            this.setState({
                alertShow: true,
                alertMessage: `Service ${service.Type} saved successfully.`,
                alertStyle: 'success'
            })
            this.props.history.push(`/admin/locations/${this.state.location}/view`);
        }).catch(error => {
            this.setState({
                alertShow: true,
                alertMessage: `Failed to save service ${service.Type}.`,
                alertStyle: 'danger'
            })
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>Edit Location Services</CardHeader>
                        <CardBody>
                            <Alert isOpen={this.state.alertShow} toggle={() => this.setState({alertShow: false})} color={this.state.alertStyle}>{this.state.alertMessage}</Alert>
                            <fieldset>
                                <label htmlFor="letter-block-type">Type of Service</label>
                                <Select id="letter-block-type" name="type" value={this.state.type} onChange={this.handleInputChange}>
                                    {this.state.types.map((type, idx) => (
                                        <Option key={idx} value={type.value} label={type.label} />
                                    ))}
                                </Select>
                                <label htmlFor="letter-block-length">Length of Appointment</label>
                                <Input type="number" id="letter-block-length" name="length" value={this.state.length} onChange={this.handleInputChange} />
                                <label htmlFor="letter-block-gap">Gap between Appointments</label>
                                <Input type="number" id="letter-block-gap" name="gap" value={this.state.gap} onChange={this.handleInputChange} />
                                <label htmlFor="letter-block-count">Number of Concurrent Appointments</label>
                                <Input type="number" id="letter-block-count" name="count" value={this.state.count} onChange={this.handleInputChange} />
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>

                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}
import React, { Component } from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import Logo from './../../assets/DAPSwhitelogo.png';

const qs = require('qs');

export default class Splash extends Component {
    constructor(props)       {
        super(props);
        this.state = {
            registered: qs.parse(props.location.search, {ignoreQueryPrefix: true}).registered
        }
    }

    render() {
        return (
            <Container style={{textAlign: 'center'}} >
                {this.state.registered ? <Alert color="info">Thank you for registering. Please hand in your iPad and you will be seen shortly</Alert> : null}
                <Row className="p-4">
                    <Col sm="12">
                        <img src={Logo} alt="DAPS Health Logo" style={{maxHeight: '100%', maxWidth: '80%'}}/>
                    </Col>
                </Row>
                <Row className="p-4">
                    <Col sm='12'>
                        <a href="/login" className="mui-btn mui-btn--raised py-4 px-2" style={{width: '300px', height: '150px', backgroundColor: '#f4f4f4'}} >
                            <h3 className="my-3" >
                                Provider
                                <br />
                                Login
                            </h3>
                        </a>
                    </Col>
                    {/* <Col sm='4'>
                        <a href="/register" className="mui-btn mui-btn--raised py-4 px-2" style={{minWidth: '100%', height: '150px', backgroundColor: '#f4f4f4'}} >
                            <h3 className="my-3" >
                                Patient
                                <br />
                                Registration
                            </h3>
                        </a>
                    </Col>
                    <Col sm="4">
                        <a href="/checkin" className="mui-btn mui-btn--raised py-4 px-2" style={{minWidth: '100%', height: '150px', backgroundColor: '#f4f4f4'}} >
                            <h3 className="my-3" >
                                Patient
                                <br />
                                Check In
                            </h3>
                        </a>
                    </Col> */}
                </Row>
            </Container>
        );
    }
}
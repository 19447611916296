import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import {Form, Input, Select, Option, Button} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const moment = require('moment');
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class RegisterNewPatient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            then: qs.parse(props.location.search, {ignoreQueryPrefix: true}).then,
            firstName: '',
            lastName: '',
            dob: '',
            address: '',
            telephoneHome: '',
            telephoneCell: '',
            telephoneWork: '',
            email: '',
            emergencyContact: '',
            insurance: '',
            pcp: '',
            patientId: '',
            username: '',
            password: '',
            user: localStorage.getItem('user_id'),
            passwordComplexity: 0,
            requiredFields: {
                patientId: false,
                username: false,
                password: false,
                firstName: false,
                lastName: false,
                dob: false,
            },
            practice: '',
            practices: [],
            valid: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadPractices()
    }

    loadPractices() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/practices/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let practices = [];
            response.data.forEach(practice => {
                let row = {
                    value: practice.pk,
                    label: practice.fields.name,
                }
                practices.push(row);
            })
            this.setState({
                practices: practices,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let passwordComplexity = this.state.passwordComplexity;
        const requiredFields = this.state.requiredFields;
        if (name === 'password') {
            if (value.length < 8) {
                passwordComplexity = 0;
            } else {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasNumbers = /\d/.test(value);
                const hasNonAlphas = /\W/.test(value);
                passwordComplexity = 1 + hasUpperCase + hasLowerCase + hasNumbers + hasNonAlphas;
            }
        }
        if (name === 'patientId' || name === 'username' || name === 'password' || name === 'firstName' || name === 'lastName' || name === 'dob')
        {
            if (value.length > 0) {
                requiredFields[name] = true;
            } else {
                requiredFields[name] = false;
            }
        }
        this.setState({
            [name]: value,
            passwordComplexity: passwordComplexity,
            requiredFields: requiredFields,
        });
    }

    handleSubmit(event) {
        let authToken = cookies.get('auth_token')
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let patientInfo = {
            csrfmiddlewaretoken: csrfToken,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            dob: this.state.dob,
            Address: this.state.address,
            HomePhone: this.state.telephoneHome,
            CellPhone: this.state.telephoneCell,
            WorkPhone: this.state.telephoneWork,
            PrimaryEmail: this.state.email,
            EmergencyContact: this.state.emergencyContact,
            Insurance: this.state.insurance,
            PrimaryCarePhysician: this.state.pcp,
            PatientId: this.state.patientId,
            Active: false,
            Personnel: this.state.user,
            Practice: this.state.practice,
            HasCareManager: true
        }
        let userInfo = {
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
        }
        let controlMethod = [];
        for (let key in this.state.controlMethod) {
            if (this.state.controlMethod[key]) {
                controlMethod.push(key);
            }
        }
        axios({
            method: 'post',
            url: `${base}/api/patients/patient/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: patientInfo,
        }).then(response => {
            let pid = response.data
            userInfo['pid'] = pid
            axios({
                method: 'post',
                url: `${base}/api/patients/user/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: userInfo,
            }).then(response => {
                this.props.history.push('/admin/patients/list')
            }).catch(error => {
                console.log(error);
            })
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        const formComplete = Object.values(this.state.requiredFields).every(V =>  V === true) && this.state.passwordComplexity > 3;
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Patient Registration</legend>
                        </CardHeader>
                        <CardBody>
                            <fieldset>
                                <legend>Patient Information</legend>
                                <label htmlFor="patient-system-id">Patient ID</label>
                                <Input type="text" name="patientId" id="patient-system-id" maxLength={50} value={this.state.patientId} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-system-username">Patient Username</label>
                                <Input type="text" name="username" id="patient-system-username" maxLength={50} value={this.state.username} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-system-password">Patient Password</label>
                                <Input type="text" name="password" id="patient-system-password" maxLength={50} value={this.state.password} onChange={this.handleInputChange} required={true} />
                                <p>Complexity requirements: All passwords must be at least 8 characters, and contain a at a combination of at least three of the following: uppercase letters, lowercase letters, numbers or special characters.</p>
                                <p>Complexity: <span style={{color: this.state.passwordComplexity < 2 ? 'red' : (this.state.passwordComplexity < 4 ? 'orange' : 'green')}}>{this.state.passwordComplexity < 2 ? 'Poor' : (this.state.passwordComplexity < 4 ? 'Moderate' : 'Strong')}</span></p>
                                <label htmlFor="patient-new-first-name">First Name</label>
                                <Input type="text" name="firstName" id="patient-new-first-name" maxLength={50} value={this.state.firstName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-last-name">Last Name</label>
                                <Input type="text" name="lastName" id="patient-new-last-name" maxLength={50} value={this.state.lastName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-dob">Date of Birth</label>
                                <Input type="date" name="dob" id="patient-new-dob" value={this.state.dob} onChange={this.handleInputChange} required={true}/>
                                <label htmlFor="patient-new-address">Address</label>
                                <Input type="text" name="address" id="patient-new-address" maxLength={200} value={this.state.address} onChange={this.handleInputChange} />
                                <fieldset>
                                    <legend>Telephone Numbers</legend>
                                    <label htmlFor="patient-new-telephone-home">Home</label>
                                    <Input type="tel" name="telephoneHome" id="patient-new-telephone-home" maxLength={15} value={this.state.telephoneHome} onChange={this.handleInputChange} />
                                    <label htmlFor="patient-new-telephone-cell">Cell</label>
                                    <Input type="tel" name="telephoneCell" id="patient-new-telephone-cell" maxLength={15} value={this.state.telephoneCell} onChange={this.handleInputChange} />
                                    <label htmlFor="patient-new-telephone-work">Work</label>
                                    <Input type="tel" name="telephoneWork" id="patient-new-telephone-work" maxLength={15} value={this.state.telephoneWork} onChange={this.handleInputChange} />
                                </fieldset>
                                <label htmlFor="patient-new-email">Email</label>
                                <Input type="email" name="email" id="patient-new-email" maxLength={50} value={this.state.email} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-emergency-contact">Significant Other</label>
                                <Input type="tel" name="emergencyContact" id="patient-new-emergency-contact" maxLength={15} value={this.state.emergencyContact} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-insurance">Insurance Information</label>
                                <Input type="text" name="insurance" id="patient-new-insurance" maxLength={50} value={this.state.insurance} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-pcp">Primary Care Physician</label>
                                <Input type="text" name="pcp" id="patient-new-pcp" maxLength={50} value={this.state.pcp} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-practice">Practice</label>
                                <Select id="patient-new-practice" name="practice" value={this.state.practice} onChange={this.handleInputChange}>
                                    <Option value="" label="" />
                                    {
                                        this.state.practices.map((practice) => (
                                            <Option key={practice.value} value={practice.value} label={practice.label} />
                                        ))
                                    }
                                </Select>
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit" disabled={!formComplete}>Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
       )
    }
}
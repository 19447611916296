/**
 * @desc Controls the state of being logged in or not. By default it is set to false (logged out)
 */

export default (state = false, action) => {
    switch (action.type) {
        case 'LOGIN':
            return true
        case 'LOGOUT':
            localStorage.removeItem('id_token');
            return false
        default:
            return state
    }
}
import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { Input, Button } from 'muicss/react';
import html2canvas from 'html2canvas';
import { Line } from 'react-chartjs-2';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const moment = require('moment');
const pdfConverter = require("jspdf");


const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class ExportGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.pid,
            name: props.pname,
            footPointsHistory: {},
            patient: {},
            threshold: 30,
            endDate: moment().format('YYYY-MM-DD'),
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.downloadRecord = this.downloadRecord.bind(this);
        this.loadFootPoints();
        this.loadPatient();
    }

    handleInputChange(event) {
        let name = event.target.name;
        this.setState({
            [name]: event.target.value
        }, this.loadFootPoints)
    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            console.log(response.data[0]);
            this.setState({
                patient: response.data[0],
            }, this.loadPatientHistory);
        }).catch(error => {
            console.log(error);
        })
    }

    loadFootPoints() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/temperature/temp/pointDifferenceThreshold/${this.state.pid}/?date=${this.state.endDate}&threshold=${this.state.threshold}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let points = [];
            let ubound = [];
            let lbound = [];
            let labels = [];
            let bgc = [];
            let upper = 4;
            let lower = -4
            let differences = response.data

            differences.forEach(set => {
                let length = 0;
                for (let key in set) {
                    let currLength = Object.keys(set[key]).length;
                    length = Math.max(currLength, length);
                }
                for (let j = 0; j < length; j++) {
                    for (let i = 1; i < 7; i++) {
                        let label = moment(set.date.split('T')[0]).format("MM/DD/YYYY") + " - ";
                        switch (i) {
                            case 1:
                                label += 'Big Toe'
                                break;
                            case 2:
                                label += 'First Metatarsal'
                                break;
                            case 3:
                                label += 'Second-Fourth Metatarsals'
                                break;
                            case 4:
                                label += 'Fifth Metatarsal'
                                break;
                            case 5:
                                label += 'Medial Plantar'
                                break;
                            case 6:
                                label += ('Heel')
                                break;
                            default:
                                console.log('Undefined')
                        }
                        if (set[i][j] || set[i][j] === 0.0) {
                            labels.push(label);
                            points.push(set[i][j]);
                            ubound.push(upper);
                            lbound.push(lower);
                            let diff = Math.abs(set[i][j]);
                            if (diff >= 4) {
                                bgc.push('#F72520');
                            } else if (diff >= 3.5) {
                                bgc.push('#F7A000');
                            } else {
                                bgc.push('#22BF2A');
                            }
                        }
                    }
                }
            });
            labels.push('extend');
            ubound.push(upper);
            lbound.push(lower);
            let data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Delta',
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'lightyellow',
                        borderColor: 'lightyellow',
                        borderWidth: 1,
                        pointBackgroundColor: bgc,
                        pointBorderColor: 'black',
                        pointBorderWidth: 1,
                        data: points,
                    },
                    {
                        label: 'Upper Bound',
                        fill: '+1',
                        lineTension: 0.1,
                        backgroundColor: 'rbga(211, 211, 211, 0.1)',
                        borderColor: 'darkgray',
                        pointRadius: 0,
                        data: ubound,
                    },
                    {
                        label: 'Lower Bound',
                        fill: false,
                        lineTension: 0.1,
                        borderColor: 'darkgray',
                        pointRadius: 0,
                        data: lbound,
                    },
                ]
            }
            this.setState({
                footPointsHistory: data,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    downloadRecord() {
        const patient = this.state.patient.fields;
        let input = window.document.getElementById('downloadableGraph');
        html2canvas(input).then(canvas => {
            const img = canvas.toDataURL("image/png");
            const pdf = new pdfConverter("l", "pt", [
                input.clientWidth + 20,
                input.clientHeight + 30]);
            pdf.addImage(
              img,
              "png",
              10,
              20,
              input.clientWidth,
              input.clientHeight
            );
            pdf.save(`patient_${this.state.patient.fields.PatientId}_${patient.FirstName}_${patient.LastName}_${this.state.endDate}.pdf`);
          });
    }

    render() {
        return <Fragment>
            <Container>
                <Row>
                    <Col md="4">
                        <Input type="number" name="threshold" id="threshold" value={this.state.threshold} onChange={this.handleInputChange} />  
                        <label htmlFor="threshold">Days Back</label>
                    </Col>
                    <Col md="4">
                        <Input type="date" name="endDate" id="endDate" value={this.state.endDate} onChange={this.handleInputChange} />  
                        <label htmlFor="endDate">From</label>
                    </Col>
                    <Col md="4">
                        <Button onClick={this.downloadRecord} variant="raised" color="primary">Download Chart</Button>
                    </Col>
                </Row>
            </Container>
            <div style={{width: 0, height: 0, overflow: 'hidden'}}>
                <div id='downloadableGraph' style={{height: '415pt', width: '792pt'}}>
                    <Line data={this.state.footPointsHistory}
                        options={{
                            legend: { display: false },
                            tooltips: { callbacks: { title: (tooltipItem, data) => { return data.labels[tooltipItem[0].index] || ''; } } },
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            suggestedMin: -7,
                                            suggestedMax: 7,
                                            stepSize: 1,
                                            callback: (label, index, labels) => {
                                                switch (label) {
                                                    case labels[labels.length - 1]:
                                                        return 'Left';
                                                    case labels[0]:
                                                        return 'Right';
                                                    default:
                                                        return label;
                                                }
                                            }
                                        }
                                    }
                                ],
                                xAxes: [
                                    {
                                        ticks: {
                                            autoSkip: false,
                                            callback: (label, index, labels) => {
                                                let date = label.split('-')[0];
                                                let idx = index % 6;
                                                if (idx === 0 && date !== 'extend') {
                                                    return date;
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        }} />
                </div>
            </div>
        </Fragment>
    }
}

import React, { Component } from 'react';
import { Container, Row, Col, Alert, Card, CardHeader, CardBody, CardFooter, Breadcrumb, BreadcrumbItem} from 'reactstrap';

const qs = require('qs');

export default class Admin extends Component {
    constructor(props)       {
        super(props);
        this.state = {
            registered: qs.parse(props.location.search, {ignoreQueryPrefix: true}).registered
        }
    }

    render() {
        return (
            <Container style={{textAlign: 'center'}} >
                <Card>
                    <CardHeader>
                        <h2>Admin Center</h2>
                    </CardHeader>
                    <CardBody>
                        <Row className="p-4">
                            <Col>
                                <a href="/admin/personnel/list">
                                    <h3  className="p-3 border border-primary rounded" style={{ minWidth: '200px', maxWidth: '275px' }}>
                                        <i className="fa fa-users-cog fa-lg" />
                                        <br />
                                        <span style={{ wordWrap: 'normal' }}>Manage Personnel</span>
                                    </h3>
                                </a>
                            </Col>
                            <Col>
                                <a href="/admin/locations/list">
                                    <h3  className="p-3 border border-primary rounded" style={{ minWidth: '200px', maxWidth: '275px' }}>
                                        <i className="fa fa-globe fa-lg" />
                                        <br />
                                        <span style={{ wordWrap: 'normal' }}>Manage Locations</span>
                                    </h3>
                                </a>
                            </Col>
                            <Col>
                                <a href="/admin/letters/blocks">
                                    <h3  className="p-3 border border-primary rounded" style={{ minWidth: '200px', maxWidth: '275px' }}>
                                        <i className="fa fa-envelope fa-lg" />
                                        <br />
                                        <span style={{ wordWrap: 'normal' }}>Manage Letter Content</span>
                                    </h3>
                                </a>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter></CardFooter>
                </Card>
            </Container>
        );
    }
}
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie'
import Login from './authentication/login';
import Splash from './navigation/splash';
//import LowExt from './low-ext';

import PatientRegistration from './forms/patientRegistration';
import PatientHistory from './forms/patientHistory';
import PatientScreening from './forms/patientScreening';
import PatientVitals from './forms/patientVitals';
import PatientUpdate from './forms/patientUpdate';
import PatientCheckIn from './forms/patientCheckIn';
import PatientReview from './patients/patientReview';
import ForgotPassword from './authentication/forgotPassword';
import ResetPassword from './authentication/resetPassword';
import MfaConfirm from './authentication/mfaConfirm';
import SelectMfa from './authentication/selectMfa';
import AddDetails from './authentication/addDetails';
import MfaQr from './authentication/MfaQr';

const cookies = new Cookies();
const mapStateToProps = state => {
    return { logged_in: state.logged_in }
}


class PublicContainer extends Component {
    render() {
        return (
            <div className="page"  style={{backgroundColor: '#02487b', minHeight: '100vh', width: '100%'}}>
                <br />
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/reset-password/:token" component={ResetPassword} />
                    <Route path="/mfa-confirm" component={MfaConfirm} />
                    <Route path="/enable-mfa" component={SelectMfa} />
                    <Route path="/add-details" component={AddDetails} />
                    <Route path="/mfa-confirm" component={MfaConfirm} />
                    <Route path="/mfa-qr" component={MfaQr} />
                    {/* <Route path="/register" component={PatientRegistration} />
                    <Route path="/checkin" component={PatientCheckIn} />
                    <Route path="/review" component={PatientReview} />
                    <Route path="/history" component={PatientHistory} />
                    <Route path="/screening" component={PatientScreening} />
                    <Route path="/vitals" component={PatientVitals} />
                    <Route path="/update" component={PatientUpdate} /> */}
                    <Route path="/" component={Splash} />
                </Switch>
                <br />
            </div>
        )
    }
}

export default connect(mapStateToProps)(PublicContainer);
import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import {Form, Input, Checkbox, Radio, Textarea, Button} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const moment = require('moment');
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            then: qs.parse(props.location.search, {ignoreQueryPrefix: true}).then,
            firstName: '',
            lastName: '',
            dob: '',
            address: '',
            telephoneHome: '',
            telephoneCell: '',
            telephoneWork: '',
            email: '',
            emergencyContact: '',
            insurance: '',
            pcp: '',
            referral: '',
            ulcerAmputation: '',
            circulation: '',
            wound: '',
            smoking: '',
            diagnosed: '',
            controlMethod: {diet: false, medication: false, insulin: false, pump: false, other: false},
            depression: '',
            eyeProblems: '',
            heartDisease: '',
            kidneyDisease: '',
            highBloodPressure: '',
            legFootBurning: '',
            legFootPain: '',
            skinColorLesions: '',
            legFootNumbness: '',
            surgeries: '',
            medications: '',
            recentA1c: '',
            morningGlucose: '',
            podiatricCare: '',
            additionalNotes: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    toggleCheckbox(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let set = this.state[name];
        set[value] = !set[value];
        this.setState({
            [name]: set,
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let patientInfo = {
            csrfmiddlewaretoken: csrfToken,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            dob: this.state.dob,
            Address: this.state.address,
            HomePhone: this.state.telephoneHome,
            CellPhone: this.state.telephoneCell,
            WorkPhone: this.state.telephoneWork,
            PrimaryEmail: this.state.email,
            EmergencyContact: this.state.emergencyContact,
            Insurance: this.state.insurance,
            PrimaryCarePhysician: this.state.pcp,
            ReferralSource: this.state.referral,
            Active: true,
        }
        let controlMethod = [];
        for (let key in this.state.controlMethod) {
            if (this.state.controlMethod[key]) {
                controlMethod.push(key);
            }
        }
        let medicalHistory = {
            csrfmiddlewaretoken: csrfToken,
            updated: moment().format('YYYY-MM-DD'),
            ulcerAmputation: this.state.ulcerAmputation,
            circulation: this.state.circulation,
            wound: this.state.wound,
            smoking: this.state.smoking,
            diagnosed: this.state.diagnosed,
            controlMethod: controlMethod.join(', '),
            depression: this.state.depression,
            eyeProblems: this.state.eyeProblems,
            heartDisease: this.state.heartDisease,
            kidneyDisease: this.state.kidneyDisease,
            highBloodPressure: this.state.highBloodPressure,
            legFootBurning: this.state.legFootBurning,
            legFootPain: this.state.legFootPain,
            skinColorLesions: this.state.skinColorLesions,
            legFootNumbness: this.state.legFootNumbness,
            surgeries: this.state.surgeries,
            medications: this.state.medications,
            recentA1c: this.state.recentA1c,
            morningGlucose: this.state.morningGlucose,
            podiatricCare: this.state.podiatricCare,
            additionalNotes: this.state.additionalNotes,
        }
        let authToken = cookies.get('auth_token')
        axios({
            method: 'post',
            url: `${base}/api/patients/patient/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: patientInfo,
        }).then(response => {
            medicalHistory['patient'] = response.data
            axios({
                method: 'post',
                url: `${base}/api/patients/history/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: medicalHistory
            }).then(response => {
                this.props.history.push(this.state.then ? this.state.then : '/?registered=true')
            }).catch(error => {
                console.log(error);
            })
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Patient Registration</legend>
                        </CardHeader>
                        <CardBody>
                            <fieldset>
                                <legend>Patient Information</legend>
                                <label htmlFor="patient-new-first-name">First Name</label>
                                <Input type="text" name="firstName" id="patient-new-first-name" maxLength={50} value={this.state.firstName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-last-name">Last Name</label>
                                <Input type="text" name="lastName" id="patient-new-last-name" maxLength={50} value={this.state.lastName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-dob">Date of Birth</label>
                                <Input type="date" name="dob" id="patient-new-dob" value={this.state.dob} onChange={this.handleInputChange} required={true}/>
                                <label htmlFor="patient-new-address">Address</label>
                                <Input type="text" name="address" id="patient-new-address" maxLength={200} value={this.state.address} onChange={this.handleInputChange} />
                                <fieldset>
                                    <legend>Telephone Numbers</legend>
                                    <label htmlFor="patient-new-telephone-home">Home</label>
                                    <Input type="tel" name="telephoneHome" id="patient-new-telephone-home" maxLength={15} value={this.state.telephoneHome} onChange={this.handleInputChange} />
                                    <label htmlFor="patient-new-telephone-cell">Cell</label>
                                    <Input type="tel" name="telephoneCell" id="patient-new-telephone-cell" maxLength={15} value={this.state.telephoneCell} onChange={this.handleInputChange} />
                                    <label htmlFor="patient-new-telephone-work">Work</label>
                                    <Input type="tel" name="telephoneWork" id="patient-new-telephone-work" maxLength={15} value={this.state.telephoneWork} onChange={this.handleInputChange} />
                                </fieldset>
                                <label htmlFor="patient-new-email">Email</label>
                                <Input type="email" name="email" id="patient-new-email" maxLength={50} value={this.state.email} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-emergency-contact">Significant Other</label>
                                <Input type="tel" name="emergencyContact" id="patient-new-emergency-contact" maxLength={15} value={this.state.emergencyContact} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-insurance">Insurance Information</label>
                                <Input type="text" name="insurance" id="patient-new-insurance" maxLength={50} value={this.state.insurance} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-pcp">Primary Care Physician</label>
                                <Input type="text" name="pcp" id="patient-new-pcp" maxLength={50} value={this.state.pcp} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-referral">Referral Source</label>
                                <Input type="text" name="referral" id="patient-new-referral" maxLength={50} value={this.state.referral} onChange={this.handleInputChange} />
                            </fieldset>
                            <fieldset>
                                <legend>Do you have a history of?</legend>
                                <fieldset>
                                    <legend>Leg/foot ulcer or lower leg amputation/surgery?</legend>
                                    <Radio name="ulcerAmputation" label="Yes" value="yes" checked={this.state.ulcerAmputation === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="ulcerAmputation" label="No" value="no" checked={this.state.ulcerAmputation === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Angioplasty, stent or leg bypass surgery?</legend>
                                    <Radio name="circulation" label="Yes" value="yes" checked={this.state.circulation === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="circulation" label="No" value="no" checked={this.state.circulation === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Foot wound?</legend>
                                    <Radio name="wound" label="Yes" value="yes" checked={this.state.wound === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="wound" label="No" value="no" checked={this.state.wound === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Smoking?</legend>
                                    <Radio name="smoking" label="Yes" value="yes" checked={this.state.smoking === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="smoking" label="No" value="no" checked={this.state.smoking === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>When were you first diagnosed with diabetes? How are you controlling it?</legend>
                                    <label htmlFor="patient-new-diagnosed">Age at which you were diagnosed:</label>
                                    <Input type="number" name="diagnosed" id="patient-new-diagnosed" value={this.state.diagnosed} onChange={this.handleInputChange} />
                                    <label>Control method:</label>
                                    <Checkbox name="controlMethod" label="Diet" value="diet" checked={this.state.controlMethod["diet"]} onChange={this.toggleCheckbox} />
                                    <Checkbox name="controlMethod" label="Oral Medication" value="medication" checked={this.state.controlMethod["medication"]} onChange={this.toggleCheckbox} />
                                    <Checkbox name="controlMethod" label="Insulin" value="insulin" checked={this.state.controlMethod["insulin"]} onChange={this.toggleCheckbox} />
                                    <Checkbox name="controlMethod" label="Pump" value="pump" checked={this.state.controlMethod["pump"]} onChange={this.toggleCheckbox} />
                                    <Checkbox name="controlMethod" label="Other" value="other" checked={this.state.controlMethod["other"]} onChange={this.toggleCheckbox} />
                                </fieldset>
                            </fieldset>
                            <fieldset>
                                <legend>Do you have:</legend>
                                <fieldset>
                                    <legend>Clinical depression?</legend>
                                    <Radio name="depression" label="Yes" value="yes" checked={this.state.depression === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="depression" label="No" value="no" checked={this.state.depression === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Problems with your eyes?</legend>
                                    <Radio name="eyeProblems" label="Yes" value="yes" checked={this.state.eyeProblems === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="eyeProblems" label="No" value="no" checked={this.state.eyeProblems === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Heart disease?</legend>
                                    <Radio name="heartDisease" label="Yes" value="yes" checked={this.state.heartDisease === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="heartDisease" label="No" value="no" checked={this.state.heartDisease === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Kidney disease?</legend>
                                    <Radio name="kidneyDisease" label="Yes" value="yes" checked={this.state.kidneyDisease === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="kidneyDisease" label="No" value="no" checked={this.state.kidneyDisease === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>High blood pressure?</legend>
                                    <Radio name="highBloodPressure" label="Yes" value="yes" checked={this.state.highBloodPressure === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="highBloodPressure" label="No" value="no" checked={this.state.highBloodPressure === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                            </fieldset>
                            <fieldset>
                                <legend>Do you have:</legend>
                                <fieldset>
                                    <legend>Burning or tingling in legs and feet?</legend>
                                    <Radio name="legFootBurning" label="Yes" value="yes" checked={this.state.legFootBurning === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="legFootBurning" label="No" value="no" checked={this.state.legFootBurning === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Leg or foot pain with activity or rest?</legend>
                                    <Radio name="legFootPain" label="Yes" value="yes" checked={this.state.legFootPain === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="legFootPain" label="No" value="no" checked={this.state.legFootPain === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Changes in skin color or skin lesions?</legend>
                                    <Radio name="skinColorLesions" label="Yes" value="yes" checked={this.state.skinColorLesions === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="skinColorLesions" label="No" value="no" checked={this.state.skinColorLesions === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Loss of sensation in legs and feet?</legend>
                                    <Radio name="legFootNumbness" label="Yes" value="yes" checked={this.state.legFootNumbness === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="legFootNumbness" label="No" value="no" checked={this.state.legFootNumbness === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                            </fieldset>
                            <fieldset>
                                <legend>Medical History:</legend>
                                <label htmlFor="patient-new-surgeries">Surgeries:</label>
                                <Textarea name="surgeries" id="patient-new-surgeries" value={this.state.surgeries} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-medications">What medications do you take?</label>
                                <Textarea name="medications" id="patient-new-medications" value={this.state.medications} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-a1c">What was your most recent hemoglobin A1c?</label>
                                <Input type="number" name="recentA1c" id="patient-new-a1c" maxLength={50} value={this.state.recentA1c} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-morning-glucose">What was your glucose this morning?</label>
                                <Input type="number" name="morningGlucose" id="patient-new-morning-glucose" maxLength={10} value={this.state.morningGlucose} onChange={this.handleInputChange} />
                                <fieldset>
                                    <legend>Have you established regular podiatric care?</legend>
                                    <Radio name="podiatricCare" label="Yes" value="yes" checked={this.state.podiatricCare === "yes"} onChange={this.handleInputChange} />
                                    <Radio name="podiatricCare" label="No" value="no" checked={this.state.podiatricCare === "no"} onChange={this.handleInputChange} />
                                </fieldset>
                            </fieldset>
                            <fieldset>
                                <legend>Additional Notes</legend>
                                <label htmlFor="patient-new-additional-notes">Is there anything else you want us to know about you?</label>
                                <Textarea name="additionalNotes" id="patient-new-additonal-notes" value={this.state.additionalNotes} onChange={this.handleInputChange} />
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Next Page</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
       )
    }
}
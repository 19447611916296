import React, { Component } from 'react';
import { Container, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';

import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host;
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class LetterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            letters: []
        };
    }

    componentDidMount() {
        this.loadLetters();
    }
    loadLetters() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/letter/patient/${this.state.pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                letters: response.data
            })
        }).catch(error => {
            console.error(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <CardHeader>
                        <h2 className="float-left">Letters</h2>
                    </CardHeader>
                    <CardBody>
                        {this.state.letters.map((letter) => (
                            <Link key={`letter-${letter.pk}`} to={`/admin/patients/${this.state.pid}/letter/${letter.pk}/`}>
                                <Card>
                                    <CardBody>
                                        <div className="float-right">{moment(letter.TimeCreated).format('DD MMM YY h:mm:ss')}</div>
                                        <div className="h5">{letter.Type || "Risk Letters"}</div>
                                        <div className="float-right">Created by {letter.Creator__LastName}, {letter.Creator__FirstName}</div>
                                        <div>{letter.Patient__LastName}, {letter.Patient__FirstName}</div>
                                    </CardBody>
                                </Card>
                            </Link>
                        ))}
                    </CardBody>
                </Card>
            </Container>
        )
    }
}

export default LetterList;
import React, { Component } from 'react';
import {
    Row, Col,
    Card, CardBody, CardHeader
} from 'reactstrap';
import axios from 'axios';
import marked from 'marked';

const ChangeLog = require('./CHANGELOG.md');

class AboutPage extends Component {
    render() {
        return (
            <div className="AboutPage">
                <Row>
                    <Col lg={5}>
                        <div className="p-3">
                            <ContactSupport />
                        </div>
                    </Col>
                    <Col>
                        <div className="p-3">
                            <PatchNotes />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

class ContactSupport extends Component {
    render() {
        return (
            <Card>
                <CardHeader><h2>Support</h2></CardHeader>
                <CardBody>
                    <div className="alert alert-primary">
                        <a href="mailto:Solutions@Quevera.com?subject=%5BDAPS%5D%20EHR%20Web%20App"><span className="fas fa-envelope-square"></span>&nbsp;Solutions@Quevera.com</a>
                    </div>
                    <div className="alert alert-primary">
                        <a href="tel:443-832-6690"><span className="fas fa-phone-square"></span>&nbsp;(443) 832-6690</a>
                        <hr />
                        Monday through Friday - 9am - 5pm
                    </div>
                </CardBody>
            </Card>
        )
    }
}

class PatchNotes extends Component {

    state = {
        changeLog: ''
    }

    componentWillMount() {
        axios.get(ChangeLog).then(response => {
            this.setState({ changeLog: response.data })
        }).catch(error => {
            this.setState({ changeLog: '####Could not load patch notes.' });
        })
    }

    render() {
        return (
            <Card>
                <CardHeader><h2>Patch Notes</h2></CardHeader>
                <CardBody>
                    <div style={{ color: "black" }} >
                        <div dangerouslySetInnerHTML={{ __html: marked(this.state.changeLog) }} />
                    </div>
                </CardBody>
            </Card>
        )
    }
}



export default AboutPage;
import React, { Component } from 'react';
import {
    Collapse,
    Navbar, NavbarToggler, NavbarBrand, Nav, NavLink, NavItem,
} from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { logout } from '../../actions';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from '../../assets/logo-no-text-inverted.png';

const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;
const cookies = new Cookies();
const cookieDomain = window.location.hostname
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(logout())
    }
}

class NavBar extends Component {

    constructor(props) {
        super(props);
        let location = window.location.href;
        let type = location.split('/')[4];
        let id = location.split('/')[5];
        this.state = {
            dropdownOpen: type === 'locations' || type === 'personnel' || type === 'letters',
            dropdown2Open: type === 'patients' && id !== 'list',
            user: {},
            id: id,
            type: type,
        }
        this.testDropdownOpen = this.testDropdownOpen.bind(this);
        this.logout = this.logout.bind(this);
        this.loadUser();
    }

    logout() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/auth/logout/`,
            headers: {
                'Content-Type': 'application/json',
                'Origin': prebase,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            console.log(response);
            cookies.remove('auth_token', { path: '/', domain: cookieDomain, expires: new Date(1970, 0, 1, 0, 0, 0, 0) })
            sessionStorage.clear();
            this.props.history.push("/");
        }).catch(error => {
            console.log(error);
        })
    }

    loadUser() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/isCare/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let user = response.data;
            this.setState({
                user: user,
            });
            let isCaregiver = false;
            for (let key in user) {
                isCaregiver = true;
            }
            if (!isCaregiver) {
                this.props.history.push('/admin/forbidden');
            }
        }).catch(error => {
            console.log(error);
        })
    }

    testDropdownOpen() {
        let location = window.location.href;
        let type = location.split('/')[4];
        let id = location.split('/')[5];
        this.setState({
            type: type,
            id: id,
            dropdownOpen: (type === 'locations' || type === 'personnel' || type === 'letters') && !this.state.dropdownOpen,
            dropdown2Open: (type === 'patients' && id !== 'list') && !this.state.dropdown2Open,
        })
    }

    loggedIn() {
        let authToken = cookies.get("auth_token");
        let isCaregiver = false;
        let user = this.state.user;
        for (let key in user) {
            isCaregiver = true;
        }
        return (authToken && isCaregiver);
    }

    // TODO: check the permissions from the coookies so only the right tabs show for x user
    render() {
        let loggedIn = this.loggedIn();
        return (
            <Nav className="float-left" style={{width: '100%', height: '100vh', overflowY: 'auto', backgroundColor: '#f4f4f4'}} navbar vertical>
                <NavItem hidden={!loggedIn}>
                    <NavLink className="p-0" tag={RRNavLink} exact to="/admin" activeClassName="active" onClick={this.testDropdownOpen}>
                        <div style={{backgroundColor: '#02487b', textAlign: 'center', clear: 'both'}}>
                            <img src={Logo} alt="DAPS Health Logo" style={{height: '200px'}}/>
                            <h3 className="w-100" style={{backgroundColor: '#a3a3a3', minHeight: '60px'}}>{this.state.user.first + " " + this.state.user.last}</h3>
                        </div>
                    </NavLink>
                </NavItem>
                <NavItem hidden={!loggedIn}>
                    <NavLink tag={RRNavLink} className="px-2" exact to="/admin/dash" activeClassName="active" onClick={this.testDropdownOpen}>
                        <span className="fa fa-tachometer-alt" />&nbsp;Dashboard
                    </NavLink>
                </NavItem>
                <NavItem hidden={!loggedIn}>
                    <NavLink tag={RRNavLink} className="px-2" to="/admin/appointments" activeClassName="active" onClick={this.testDropdownOpen}>
                        <span className="fa fa-users" />&nbsp;Appointments
                    </NavLink>
                </NavItem>
                <NavItem hidden={!loggedIn}>
                    <NavLink tag={RRNavLink} className="px-2" to="/admin/patients/list" activeClassName="active" onClick={this.testDropdownOpen} >
                        <span className="fa fa-th-list" />&nbsp;Patients<span className={"fa fa-caret-" + ((this.state.dropdown2Open) ? 'up' : 'down') + " float-right"} />
                    </NavLink>
                    <Collapse isOpen={this.state.dropdown2Open} navbar style={{backgroundColor: '#a3a3a3'}}>
                        <NavLink tag={RRNavLink} className="ml-3 px-2" to={`/admin/patients/${this.state.id}/history/list`} activeClassName="active">
                            <span className="fa fa-clipboard-list" />&nbsp;Patient History
                        </NavLink>
                        <NavLink tag={RRNavLink} className="ml-3 px-2" to={`/admin/patients/${this.state.id}/vitals/list`} activeClassName="active">
                            <span className="fa fa-clipboard-check" />&nbsp;Patient Vitals History
                        </NavLink>
                        <NavLink tag={RRNavLink} className="ml-3 px-2" to={`/admin/patients/${this.state.id}/screening/list`} activeClassName="active">
                            <span className="fa fa-clipboard" />&nbsp;Patient Screening History
                        </NavLink>
                    </Collapse>
                </NavItem>
                <NavItem hidden={!loggedIn}>
                    <NavLink tag={RRNavLink} className="px-2" to="/admin" activeClassName="active" onClick={this.testDropdownOpen} >
                        <span className="fa fa-clipboard" />&nbsp;Admin Portal<span className={"fa fa-caret-" + (this.state.dropdownOpen ? 'up' : 'down') + " float-right"} />
                    </NavLink>
                    <Collapse isOpen={this.state.dropdownOpen} navbar style={{backgroundColor: '#a3a3a3'}}>
                        <NavLink tag={RRNavLink} className="ml-3 px-2" to="/admin/personnel/list" activeClassName="active">
                            <span className="fa fa-users-cog" />&nbsp;Manage Personnel
                        </NavLink>
                        <NavLink tag={RRNavLink} className="ml-3 px-2" to="/admin/locations/list" activeClassName="active">
                            <span className="fa fa-globe" />&nbsp;Manage Locations
                        </NavLink>
                        <NavLink tag={RRNavLink} className="ml-3 px-2" to="/admin/letters/blocks" activeClassName="active">
                            <span className="fa fa-envelope" />&nbsp;Manage Letter Content
                        </NavLink>
                    </Collapse>
                </NavItem>
                <NavItem hidden={!loggedIn || true}>
                    <NavLink tag={RRNavLink} className="px-2" to="/admin/settings" activeClassName="active" onClick={this.testDropdownOpen}> 
                        <span className="fa fa-cog" />&nbsp;Settings
                    </NavLink>
                </NavItem>
                {/* <NavItem hidden={!loggedIn}>
                    <NavLink tag={RRNavLink} className="px-2" to="/admin/enable-mfa" activeClassName="active"> 
                        <span className="fa fa-lock" />&nbsp;Enable Multi Factor Authentication
                    </NavLink>
                </NavItem> */}
                <NavItem>
                    <NavLink className="px-2" href="#" onClick={this.logout}>
                        <i className="fa fa-sign-out-alt" />&nbsp;Logout
                    </NavLink>
                </NavItem>
                {/* TODO: Fix the behavior on navbar content being beyond scrollable area
                <NavItem style={{bottom: '0', position: 'fixed', width: '100%'}}>
                    <RRNavLink to="/admin/about" className="px-2 nav-link">
                        <i className="far fa-question-circle" />&nbsp;About
                    </RRNavLink>
                </NavItem> */}
            </Nav>
        );
    }
}

export default connect(null, mapDispatchToProps, null, { pure: false })(NavBar);

import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie'
import PrivateContainer from './privateContainer';
import PublicContainer from './publicContainer';
const cookies = new Cookies();
const mapStateToProps = state => {
    return { logged_in: state.logged_in }
}


class Container extends Component {
    render() {
        /**
        * @name PrivateRoute
        * @desc Handles private routing for Users; if not logged in, redirects to login page for admin side
        */
        const PrivateRoute = ({ component: Component, ...rest }) => (
            <Route {...rest} render={props => (
                (cookies.get('auth_token') && !cookies.get('mfa_configure')) ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{
                            pathname: '/login',
                            state: {
                                from: props.location,
                                redirectPath: props.location.pathname
                            }
                        }} />
                    )
            )} />
        )

        /**
        * @name PublicRoute
        * @desc Handles routing for if you are logged in. 
        */
        const PublicRoute = ({ component: Component, ...rest }) => (
            <Route {...rest} render={props => (
                !cookies.get('auth_token') || cookies.get('mfa_configure') ? (
                    <Component {...props} />
                ) : (
                        <Redirect to='/admin/patients/list' />
                    )
            )} />
        )

        return (
            <div className="MainContainer">
                <Router>
                    <Switch>
                        <PrivateRoute path="/admin" component={PrivateContainer} />
                        <PublicRoute path="/" component={PublicContainer} />
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Container);
import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card, CardHeader, CardBody, CardFooter,
    Alert
} from 'reactstrap';

import { Form, Input, Button } from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from './../../assets/DAPSwhitelogo.png';

const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            alertShow: false,
            alertMessage: '',
            color: 'danger'
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken')
        let data = {
            csrfmiddlewaretoken: csrfToken,
            email: this.state.email,
        }
        axios({
            method: 'post',
            url: `${base}/api/password-reset/`,
            headers: {
                'Content-Type': 'application/json',
                'Origin': prebase,
                'Upgrade-Insecure-Requests': 1,
            },
            withCredentials: true,
            data: data,
        }).then(response => {
            this.setState({ alertShow: true, alertMessage: 'Password reset request submitted. Please check your email.', color: 'success'})
        }).catch(error => {
            this.setState({ alertShow: true, alertMessage: error.response.data.email });
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Row className="p-4">
                    <Col sm="12" md="6" className="text-center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src={Logo} alt="DAPS Health Logo" style={{maxHeight: '80%', maxWidth: '80%'}} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md='6' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <Card>
                            <Form onSubmit={this.handleSubmit}>
                                <CardHeader>
                                    <legend>Forgot Password</legend>
                                </CardHeader>
                                <CardBody>
                                    <Alert className="m-2" color={this.state.color} isOpen={this.state.alertShow} toggle={() => { this.setState({ alertShow: false }) }}>
                                        {this.state.alertMessage}
                                    </Alert>
                                    <fieldset>
                                        <label htmlFor="user-email">Email</label>
                                        <Input type="text" name="email" id="user-email" value={this.state.email} onChange={this.handleInputChange} autoFocus/>
                                    </fieldset>
                                </CardBody>
                                <CardFooter>
                                    <Button className="bg-success" color="primary" variant="raised">Submit</Button>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default ForgotPassword;
import React, {Component} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Alert} from 'reactstrap';
import {Form, Input, Button} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from './../../assets/DAPSwhitelogo.png';

const cookies = new Cookies();
const moment = require('moment');
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientCheckIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dob: '',
            firstName: '',
            lastName: '',
            pid: '',
            alertShow: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let authToken = cookies.get('auth_token')
        let csrfToken = cookies.get('csrftoken');
        let patient = {
            csrfmiddlewaretoken: csrfToken,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: moment(this.state.dob).format('YYYY-MM-DD')
        }
        axios({
            method: 'post',
            url: `${base}/api/patients/checkin/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            data: patient
        }).then(response => {
            if (response.data.pid) {
                let query = {
                    pid: response.data.pid,
                }
                if (this.state.then) {
                    query['then'] = this.state.then;
                }
                this.props.history.push("/review?"+qs.stringify(query));
            } else {
                this.setState({
                    alertShow: true,
                    alertMessage: 'Could not find profile. Have you already registered?'
                });
            }

        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Row className="p-4">
                    <Col sm="12" md="6" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <a href="/">
                            <img src={Logo} alt="DAPS Health Logo" style={{ maxHeight: '80%', maxWidth: '80%' }} />
                        </a>
                    </Col>
                    <Col sm="12" md='6' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <Card>
                            <Form onSubmit={this.handleSubmit}>
                                <CardHeader>
                                    <legend>Patient Check In</legend>
                                </CardHeader>
                                <CardBody>
                                    <Alert color="danger" isOpen={this.state.alertShow} toggle={() => { this.setState({ alertShow: false }) }}>{this.state.alertMessage}</Alert>
                                    <fieldset>
                                        <label htmlFor="patient-check-in-first-name">First Name</label>
                                        <Input type="text" name="firstName" id="patient-check-in-first-name" value={this.state.firstName} onChange={this.handleInputChange} required={true} />
                                        <label htmlFor="patient-check-in-last-name">Last Name</label>
                                        <Input type="text" name="lastName" id="patient-check-in-last-name" value={this.state.lastName} onChange={this.handleInputChange} required={true} />
                                        <label htmlFor="patient-check-in-dob">Birth Date</label>
                                        <Input type="date" name="dob" id="patient-check-in-dob" value={this.state.dob} onChange={this.handleInputChange} required={true} />
                                    </fieldset>
                                </CardBody>
                                <CardFooter>
                                    <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
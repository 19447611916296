import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, Alert} from 'reactstrap';
import {Form, Input, Textarea, Button, Select, Option} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const qs = require('qs');
const moment = require('moment');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class BlockForm extends Component {
    constructor(props) {
        super(props);
        let types = [
            {value: '', label: ''},
            {value: 'Depression', label: 'Depression'},
            {value: 'At Risk', label: 'At Risk'},
            {value: 'Rest Pain', label: 'Rest Pain'},
            {value: 'Claudication', label: 'Claudication'},
            {value: 'Ulcer', label: 'Ulcer'},
            {value: 'Infected', label: 'Infected'},
            {value: 'PVD', label: 'PVD'}
        ]
        this.state = {
            types: types,
            alertMessage: '',
            alertStyle: 'danger',
            alertShow: false,
            loading: false,
            type: '',
            text: '',
            date: moment().format('YYYY-MM-DD h:mm:ss'),
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    loadBlock() {
        let authToken = cookies.get('auth_token')
        let type = this.state.type;
        if (type !== '') {
            let data = {
                type: type,
            }
            axios({
                method: 'get',
                url: `${base}/api/patients/block/?${qs.stringify(data)}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
            }).then(response => {
                this.setState({
                    text: response.data.text,
                })
            }).catch(error => {
                console.log(error);
                this.setState({
                    text: '',
                })
            })
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name === 'type') {
            this.setState({
                [name]: value,
            }, this.loadBlock);
        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        let authToken = cookies.get('auth_token')
        let csrfToken = cookies.get('csrftoken');
        let block = {
            csrfmiddlewaretoken: csrfToken,
            Type: this.state.type,
            Text: this.state.text,
            Date: this.state.date,
        }
        axios({
            method: 'post',
            url: `${base}/api/patients/block/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: block
        }).then(response => {
            this.setState({
                alertShow: true,
                alertMessage: `Block for ${block.Type} saved successfully.`,
                alertStyle: 'success'
            })
        }).catch(error => {
            this.setState({
                alertShow: true,
                alertMessage: `Failed to save block for ${block.Type}.`,
                alertStyle: 'danger'
            })
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>Edit Letter Block</CardHeader>
                        <CardBody>
                            <Alert isOpen={this.state.alertShow} toggle={() => this.setState({alertShow: false})} color={this.state.alertStyle}>{this.state.alertMessage}</Alert>
                            <fieldset>
                                <label htmlFor="letter-block-type">Type</label>
                                <Select id="letter-block-type" name="type" value={this.state.type} onChange={this.handleInputChange}>
                                    {this.state.types.map((type, idx) => (
                                        <Option key={idx} value={type.value} label={type.label} />
                                    ))}
                                </Select>
                                <label htmlFor="letter-block-text">Contents</label>
                                <Textarea id="letter-block-text" name="text" value={this.state.text} onChange={this.handleInputChange} />
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}
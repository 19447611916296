import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import {Form, Input, Button, Checkbox, Select, Option} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import moment from 'moment';

const cookies = new Cookies();
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: qs.parse(props.location.search, {ignoreQueryPrefix: true}).pid,
            then: qs.parse(props.location.search, {ignoreQueryPrefix: true}).then,
            user: '',
            personnel: '',
            patientId: '',
            firstName: '',
            lastName: '',
            dob: '',
            address: '',
            telephoneHome: '',
            telephoneCell: '',
            telephoneWork: '',
            email: '',
            emergencyContact: '',
            insurance: '',
            pcp: '',
            referral: '',
            active: false,
            personnelList: [],
            practice: '',
            practices: [],
            enrollmentDate: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.loadPatient();
        this.loadPractices();
    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let patient = response.data[0];
            if (patient){
                let fields = patient.fields;
                this.setState({
                    user: fields.User ? fields.User : '',
                    personnel: fields.Personnel ? fields.Personnel : '',
                    patientId: fields.PatientId,
                    firstName: fields.FirstName,
                    lastName: fields.LastName,
                    dob: fields.dob ? fields.dob : '',
                    address: fields.Address ? fields.Address : '',
                    telephoneHome: fields.HomePhone ? fields.HomePhone : '',
                    telephoneCell: fields.CellPhone ? fields.CellPhone : '',
                    telephoneWork: fields.WorkPhone ? fields.WorkPhone : '',
                    email: fields.PrimaryEmail ? fields.PrimaryEmail : '',
                    emergencyContact: fields.EmergencyContact ? fields.EmergencyContact : '',
                    insurance: fields.Insurance ? fields.Insurance : '',
                    pcp: fields.PrimaryCarePhysician ? fields.PrimaryCarePhysician : '',
                    practice: fields.Practice ? fields.Practice : '',
                    referral: fields.ReferralSource ? fields.ReferralSource : '',
                    enrollmentDate: fields.EnrollmentDate ? moment.utc(fields.EnrollmentDate).format('YYYY-MM-DD h:m:s') : moment().format('YYYY-MM-DD h:m:s'),
                    active: fields.Active ? fields.Active : false,
                }, this.loadPersonnel());
            }    
        }).catch(error => {
            console.log(error);
        })
    }

    loadPractices() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/practices/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let practices = [];
            response.data.forEach(practice => {
                let row = {
                    value: practice.pk,
                    label: practice.fields.name,
                }
                practices.push(row);
            })
            this.setState({
                practices: practices,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    loadPersonnel() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/personnel/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let personnel = [];
            response.data.forEach(person => {
                let row = {
                    value: person.pk,
                    label: person.fields.LastName + ', ' + person.fields.FirstName,
                }
                personnel.push(row);
            })
            this.setState({
                personnelList: personnel,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    toggleCheckbox(event) {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: !this.state[name],
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let patientInfo = {
            csrfmiddlewaretoken: csrfToken,
            User: this.state.user,
            Personnel: this.state.personnel,
            PatientId: this.state.patientId,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            dob: this.state.dob,
            Address: this.state.address,
            HomePhone: this.state.telephoneHome,
            CellPhone: this.state.telephoneCell,
            WorkPhone: this.state.telephoneWork,
            PrimaryEmail: this.state.email,
            EmergencyContact: this.state.emergencyContact,
            Insurance: this.state.insurance,
            PrimaryCarePhysician: this.state.pcp,
            Practice: this.state.practice,
            ReferralSource: this.state.referral,
            Active: this.state.active,
            EnrollmentDate: this.state.active ? this.state.enrollmentDate : null,
        }
        let authToken = cookies.get('auth_token')
        axios({
            method: 'put',
            url: `${base}/api/patients/patient/${this.state.pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: patientInfo,
        }).then(response => {
            this.props.history.push(this.state.then ? this.state.then : `/admin/patients/${this.state.pid}/view`);
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Patient Registration</legend>
                        </CardHeader>
                        <CardBody>
                        <fieldset>
                                <legend>Patient Information</legend>
                                <label htmlFor="patient-system-id">Patient ID</label>
                                <Input type="text" name="patientId" id="patient-system-id" maxLength={50} value={this.state.patientId} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-first-name">First Name</label>
                                <Input type="text" name="firstName" id="patient-new-first-name" maxLength={50} value={this.state.firstName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-last-name">Last Name</label>
                                <Input type="text" name="lastName" id="patient-new-last-name" maxLength={50} value={this.state.lastName} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-caretaker">Caregiver</label>
                                <Select id="patient-new-caretaker" name="personnel" value={this.state.personnel} onChange={this.handleInputChange}>
                                    <Option value="" label="" />
                                    {
                                        this.state.personnelList.map((person) => (
                                            <Option key={person.value} value={person.value} label={person.label} />
                                        ))
                                    }
                                </Select>
                                <label htmlFor="patient-new-dob">Date of Birth</label>
                                <Input type="date" name="dob" id="patient-new-dob" value={this.state.dob} onChange={this.handleInputChange} required={true} />
                                <label htmlFor="patient-new-address">Address</label>
                                <Input type="text" name="address" id="patient-new-address" maxLength={200} value={this.state.address} onChange={this.handleInputChange} />
                                <fieldset>
                                    <legend>Telephone Numbers</legend>
                                    <label htmlFor="patient-new-telephone-home">Home</label>
                                    <Input type="tel" name="telephoneHome" id="patient-new-telephone-home" maxLength={15} value={this.state.telephoneHome} onChange={this.handleInputChange} />
                                    <label htmlFor="patient-new-telephone-cell">Cell</label>
                                    <Input type="tel" name="telephoneCell" id="patient-new-telephone-cell" maxLength={15} value={this.state.telephoneCell} onChange={this.handleInputChange} />
                                    <label htmlFor="patient-new-telephone-work">Work</label>
                                    <Input type="tel" name="telephoneWork" id="patient-new-telephone-work" maxLength={15} value={this.state.telephoneWork} onChange={this.handleInputChange} />
                                </fieldset>
                                <label htmlFor="patient-new-email">Email</label>
                                <Input type="email" name="email" id="patient-new-email" maxLength={50} value={this.state.email} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-emergency-contact">Significant Other</label>
                                <Input type="tel" name="emergencyContact" id="patient-new-emergency-contact" maxLength={15} value={this.state.emergencyContact} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-insurance">Insurance Information</label>
                                <Input type="text" name="insurance" id="patient-new-insurance" maxLength={50} value={this.state.insurance} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-pcp">Primary Care Physician</label>
                                <Input type="text" name="pcp" id="patient-new-pcp" maxLength={50} value={this.state.pcp} onChange={this.handleInputChange} />
                                <label htmlFor="patient-new-practice">Practice</label>
                                <Select id="patient-new-practice" name="practice" value={this.state.practice} onChange={this.handleInputChange}>
                                    <Option value="" label="" />
                                    {
                                        this.state.practices.map((practice) => (
                                            <Option key={practice.value} value={practice.value} label={practice.label} />
                                        ))
                                    }
                                </Select>
                                <label htmlFor="patient-new-referral">Referral Source</label>
                                <Input type="text" name="referral" id="patient-new-referral" maxLength={50} value={this.state.referral} onChange={this.handleInputChange} />
                                <Checkbox name="active" label="Active" value={true} checked={this.state.active} onChange={this.toggleCheckbox} />
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
       )
    }
}
import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
    Container, Row, Col,
    Card, CardHeader, CardBody, CardFooter,
    Alert
} from 'reactstrap';

import { Form, Input, Button } from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Logo from './../../assets/DAPSwhitelogo.png';

const moment = require('moment')
const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const cookieDomain = window.location.hostname
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            alertShow: false,
            alertMessage: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        let cookieDate = moment().add(120, 'm').toDate()
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken')
        let data = {
            csrfmiddlewaretoken: csrfToken,
            username: this.state.username,
            password: this.state.password,
        }
        axios({
            method: 'post',
            url: `${base}/api/mfaAuth/login/`,
            headers: {
                'Content-Type': 'application/json',
                'Origin': prebase,
                'Upgrade-Insecure-Requests': 1,
            },
            withCredentials: true,
            data: data,
        }).then(response => {
            if (response.data.ephemeral_token) {
                cookies.remove('auth_token', { path: '/', domain: cookieDomain, expires: new Date(1970, 0, 1, 0, 0, 0, 0) })
                cookies.remove('mfa_configure', { path: '/', domain: cookieDomain, expires: new Date(1970, 0, 1, 0, 0, 0, 0) })
                axios.post(`${base}/api/temp-token/`, data, {                
                    'Content-Type': 'application/json',
                    'Origin': prebase,
                    'Upgrade-Insecure-Requests': 1,
                }).then(response => {
                    cookies.set('temp_token', response.data.token, { path: '/', domain: cookieDomain, expires: cookieDate})
                }).catch(error => {
                    console.log(error.response.data)
                })
                this.props.history.push(`/mfa-confirm?method=${response.data.method}&ephemeralToken=${response.data.ephemeral_token}`)
            } else {
                cookies.set('auth_token', response.data.auth_token, { path: '/', domain: cookieDomain, expires: moment().add(10, 'm').toDate()})
                cookies.set('mfa_configure', true, { path: '/', domain: cookieDomain, expires: moment().add(10, 'm').toDate()})
                this.props.history.push('/enable-mfa')
            }
        }).catch(error => {
            console.log(error.response.data);
            if (error.response.data.detail === "CSRF Failed: CSRF token missing or incorrect.") {
                this.setState({ alertShow: true, alertMessage: "Incorrect Token Cleared. Please Try Again" });
                document.cookie.split(";").forEach((c) => {
                    document.cookie = c
                      .replace(/^ +/, "")
                      .replace(/=.*/, "=;expires=" + new Date(1970, 0, 1, 0, 0, 0, 0).toUTCString() + ";path=/");
                  });
            } else {
                this.setState({ alertShow: true, alertMessage: error.response.data.error });
                console.log(error);
            }
        })
    }

    loadUser() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/isCare/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let user = response.data;
            let isCaregiver = false;
            for (let key in user) {
                isCaregiver = true;
            }
            if (isCaregiver) {
                this.props.history.push('/admin/patients/list');
            } else {
                this.props.history.push('/admin/forbidden');
            }
        }).catch(error => {
            this.setState({ alertShow: true, alertMessage: error.response.data });
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Row className="p-4">
                    <Col sm="12" md="6" className="text-center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src={Logo} alt="DAPS Health Logo" style={{maxHeight: '80%', maxWidth: '80%'}} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md='6' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <Card>
                            <Form onSubmit={this.handleSubmit}>
                                <CardHeader>
                                    <legend>Login</legend>
                                </CardHeader>
                                <CardBody>
                                    <Alert className="m-2" color="danger" isOpen={this.state.alertShow} toggle={() => { this.setState({ alertShow: false }) }}>
                                        {this.state.alertMessage}
                                    </Alert>
                                    <Alert className="m-2" color="info">New Users: Please reset your password using the link below.</Alert>
                                    <fieldset>
                                        <label htmlFor="user-login-name">Username</label>
                                        <Input type="text" name="username" id="user-login-name" value={this.state.username} onChange={this.handleInputChange} autoFocus/>
                                        <label htmlFor="user-login-password">Password</label>
                                        <Input type="password" name="password" id="user-login-password" value={this.state.password} onChange={this.handleInputChange} />
                                    </fieldset>
                                    <p className="m-4 text-secondary"><Link to="/forgot-password">Reset Password</Link></p>
                                </CardBody>
                                <CardFooter>
                                    <Button className="bg-success" color="primary" variant="raised">Login</Button>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default Login;
import React, { Component } from 'react';
import {
    Card, CardBody, CardTitle, CardHeader,
    Row, Col
} from 'reactstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class PatientInfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            patientId: props.patientId
        }
        this.loadPatient = this.loadPatient.bind(this);
    }

    componentDidMount() {
        if (this.state.patientId) this.loadPatient();
    }

    componentWillReceiveProps(props) {
        if (props.patientId != this.state.patientId) {
            this.setState({
                patientId: props.patientId
            }, this.loadPatient);
        }
    }

    loadPersonnel() {
        if (this.state.personnelId) {
            let csrfToken = cookies.get('csrftoken');
            let authToken = cookies.get('auth_token')
            axios({
                method: 'get',
                url: `${base}/api/caregivers/personnel/${this.state.personnelId}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
            }).then(response => {
                if (response.data.length > 0) {
                    let personnel = response.data[0].fields
                    this.setState({
                        personnelLabel: personnel.LastName + ', ' + personnel.FirstName
                    });
                }
            }).catch(error => {
                this.setState({ alertShow: true, alertMessage: error.response.data.message, loading: false });
            });
        }
    }

    loadPatient() {
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.props.patientId}/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            if (response.data.length > 0) {
                let patient = response.data[0].fields;
                this.setState({
                    FirstName: patient.FirstName,
                    LastName: patient.LastName,
                    CellPhone: patient.CellPhone,
                    HomePhone: patient.HomePhone,
                    WorkPhone: patient.WorkPhone,
                    Email: patient.PrimaryEmail,
                    loading: false,
                    personnelId: patient.Personnel
                }, this.loadPersonnel)
            }
        }).catch(error => {
            this.setState({ alertShow: true, alertMessage: error.response.data.message, loading: false });
        });
    }

    render() {
        return (
            <div>
                {this.state.patientId &&
                    <Card outline color="success">
                        <CardHeader>Patient Info</CardHeader>
                        <CardBody>
                            {
                                this.state.loading ?
                                    <div className="text-center">
                                        Loading <span className="fa fa-spin fa-sync" />
                                    </div> :
                                    <div>
                                        <CardTitle>
                                            <Link to={`/admin/patients/${this.props.patientId}/view`}>{this.state.LastName + ", " + this.state.FirstName}</Link>
                                        </CardTitle>
                                        <Row>
                                            <Col lg={12}>Home: {this.state.HomePhone}</Col>
                                            <Col lg={12}>Cell: {this.state.CellPhone}</Col>
                                            <Col lg={12}>Work: {this.state.WorkPhone}</Col>
                                            <Col lg={12}>Email: {this.state.Email}</Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col>Primary CM: { this.state.personnelLabel} </Col>
                                        </Row>
                                    </div>
                            }
                        </CardBody>
                    </Card>
                }
            </div>
        )
    }
}

export default PatientInfoCard;
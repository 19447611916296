import React, {Fragment, Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Dropdown, DropdownItem} from 'muicss/react';
import axios from 'axios';
import {Line, Bar} from 'react-chartjs-2';
import Cookies from 'universal-cookie';

const cookies = new Cookies()
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

//const screeningScores = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

export default class PersonnelProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            person: {fields: {}},
            locations: [],
            pageLoading: true,
        }
        this.loadPerson();
    }

    loadPerson() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/personnel/${this.state.pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                person: response.data[0],
            }, this.loadLocations);
        }).catch(error => {
            console.log(error);
        })
    }

    loadLocations() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/caregiverLocation/${this.state.pid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },withCredentials: true,
        }).then(response => {
            this.setState({
                locations: response.data[this.state.pid],
                pageLoading: false
            });
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        let person = this.state.person ? this.state.person.fields : {};
        return (
            <Fragment>
                {this.state.pageLoading ? 
                <Container></Container> :
                <Container>
                    <Card>
                        <CardHeader>
                            <h2 className="float-left">{person.LastName + ', ' + person.FirstName}</h2>
                            <Dropdown className="float-right" variant="raised" label="Update">
                                <DropdownItem link={"/admin/personnel/" + this.state.pid}>Enter Basic Info</DropdownItem>
                            </Dropdown>
                        </CardHeader>
                        <CardBody>
                            <Card className="border-success">
                                <CardHeader>Caregiver</CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={8}>
                                            <h4>Name: {person.FirstName + ' ' + person.LastName}</h4>
                                            <h5>Email: {person.PrimaryEmail}</h5>
                                            <h5>Role: {person.Role}</h5>
                                        </Col>
                                        <Col md={4}>
                                            <h5>Address: </h5>
                                            <div className="ml-4">
                                                <p className="h6">{person.Address}</p>
                                            </div>
                                            <h5>Telephone Numbers:</h5>
                                            <div className="ml-4">
                                                <p className="h6">Home: {person.HomePhone}</p>
                                                <p className="h6">Cell: {person.CellPhone}</p>
                                                <p className="h6">Work: {person.WorkPhone}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="border-primary">
                                <CardHeader>Works At</CardHeader>
                                <CardBody>
                                    {this.state.locations.map((loc) => (
                                        <div key={loc.pk}>
                                            <p className="h4">{loc.Name}</p>
                                            <div className="ml-4">
                                                <p className="h5">{loc.Address}
                                                <br />
                                                {(loc.City ? loc.City : '') + ', ' + (loc.State ? loc.State : '') + ' ' + (loc.ZipCode ? loc.ZipCode : '')}</p>
                                            </div>
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Container>}
            </Fragment>
        )
    }
}
import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import {Form, Input, Button, Select, Option} from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class LocationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lid: props.match.params.lid,
            name: '',
            street: '',
            city: '',
            state: '',
            zipcode: '',
            staff: [],
            newStaff: '',
            personnel: [],
            staffList: [],
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadPersonnel();
    }

    loadLocation() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/locations/${this.state.lid}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let location = response.data[0];
            this.setState({
                name: location.Name,
                address: location.Address,
                city: location.City,
                state: location.State,
                zipcode: location.ZipCode,
                staff: location.Staff,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    loadPersonnel() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/personnel/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let personnel = [
                {value: '', label: ''}
            ];
            let staffList = {};
            response.data.forEach(data => {
                let row = {
                    value: data.pk,
                    label: `${data.fields.LastName}, ${data.fields.FirstName}`,
                }
                personnel.push(row);
                let obj = {
                    pk: data.pk,
                    FirstName: data.fields.FirstName,
                    LastName: data.fields.LastName,
                }
                staffList[data.pk] = obj;
            })
            this.setState({
                personnel: personnel,
                staffList: staffList,
            }, this.state.lid ? this.loadLocation : null)
        }).catch(error => {
            console.log(error);
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let staff = this.state.staff;
        if (name === 'newStaff') {
            let staffList = this.state.staffList;
            let included = value === '';
            staff.forEach(member => {
                if (member.pk == value) {
                    included = true;
                }
            })
            if (!included) {
                staff.push(staffList[value]);
            }
        }
        this.setState({
            [name]: value,
            staff: staff
        });
    }

    clearStaff(key) {
        let staff = this.state.staff;
        staff.forEach((member, idx) => {
            if (member.pk == key) {
                staff.splice(idx, 1);
            }
        })
        this.setState({
            staff: staff,
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let authToken = cookies.get('auth_token')
        let staffPK = [];
        this.state.staff.forEach(member => {
            staffPK.push(member.pk);
        })
        let locationInfo = {
            csrfmiddlewaretoken: csrfToken,
            Name: this.state.name,
            Address: this.state.address,
            City: this.state.city,
            State: this.state.state,
            ZipCode: this.state.zipcode,
            Staffing: staffPK,
        }
        if (!this.state.lid) {
            axios({
                method: 'post',
                url: `${base}/api/caregivers/locations/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: locationInfo,
            }).then(response => {
                let lid = response.data
                this.props.history.push(this.state.then ? this.state.then : `/admin/locations/${lid}/view`);
            }).catch(error => {
                console.log(error);
            })
        } else {
            axios({
                method: 'put',
                url: `${base}/api/caregivers/locations/${this.state.lid}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Token ${authToken}`
                },
                withCredentials: true,
                data: locationInfo,
            }).then(response => {
                this.props.history.push(this.state.then ? this.state.then : `/admin/locations/${this.state.lid}/view`);
            }).catch(error => {
                console.log(error);
            })
        }
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Location and Staffing</legend>
                        </CardHeader>
                        <CardBody>
                        <fieldset>
                                <legend>Location Information</legend>
                                <label htmlFor="location-new-name">Name</label>
                                <Input type="text" name="name" id="location-new-name" maxLength={50} value={this.state.name} onChange={this.handleInputChange} required={true} />
                                <fieldset>
                                    <legend>Address</legend>
                                    <label htmlFor="location-new-address-street">Street</label>
                                    <Input type="text" name="address" id="location-new-address-street" maxLength={150} value={this.state.address} onChange={this.handleInputChange} />
                                    <label htmlFor="location-new-address-city">City</label>
                                    <Input type="text" name="city" id="location-new-address-city" maxLength={150} value={this.state.city} onChange={this.handleInputChange} />
                                    <label htmlFor="location-new-address-state">State (2 Letter Abbreviation)</label>
                                    <Input type="text" name="state" id="location-new-address-state" maxLength={50} value={this.state.state} onChange={this.handleInputChange} />
                                    <label htmlFor="location-new-address-zipcode">Zip Code</label>
                                    <Input type="text" name="zipcode" id="location-new-address-zipcode" maxLength={15} value={this.state.zipcode} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Staffing</legend>
                                    <label htmlFor="location-new-staff">Staff</label>
                                    <div>
                                        {this.state.staff.map(staff => (
                                            <p key={staff.pk}>{`${staff.LastName}, ${staff.FirstName}`}<span className="float-right text-danger fa fa-times-circle" onClick={() => this.clearStaff(staff.pk)} /></p>
                                        ))}
                                    </div>
                                    <Select name="newStaff" id="location-new-staff" value={this.state.newStaff} onChange={this.handleInputChange} multiple={true}>
                                        {this.state.personnel.map(option => (
                                            <Option key={option.value} value={option.value} label={option.label} />
                                        ))}
                                    </Select>
                                </fieldset>
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
       )
    }
}
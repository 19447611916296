import React, { Component } from 'react';
import './bootstrap.min.css';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';

import Container from './components/Container';

const store = createStore(rootReducer);

class App extends Component {
    render() {
        return (
            <div className="App">
                <Provider store={store}>
                    <Container />
                </Provider>
            </div>
        );
    }
}

export default App;

import React, { Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies()
const prebase = window.location.protocol + '//' + window.location.host;
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PersonnelList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personnel: [],
        };
        this.loadPersonnel();
    }
    
    loadPersonnel() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/caregivers/personnel/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            console.log(response.data)
            this.setState({
                personnel: response.data,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <CardHeader>
                        <h2 className="float-left">Personnel</h2>
                        <a className="mui-btn mui-btn--raised float-right" href="/admin/personnel/new">Add Caretaker</a>
                    </CardHeader>
                    <CardBody>
                        {this.state.personnel.map((person) => (
                            <a key={person.pk} href={`/admin/personnel/${person.pk}/view`}>
                                <Card key={person.pk}> 
                                    <CardBody>
                                    <p className="h4 float-left">{person.fields.LastName + ', ' + person.fields.FirstName}</p>
                                    <p className="h5 float-right">{person.fields.Role}</p>
                                    </CardBody>
                                </Card>
                            </a>
                        ))}
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </Container>
        )
    }
}


import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import IdleTimer from 'react-idle-timer';
import axios from 'axios';

const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;
const cookieDomain = window.location.hostname
const DEFAULT_IDLE_TIMER = 1000 * 60 * 20; //Milliseconds -> (20 minutes)
const DEFAULT_LOGOUT_TIMER = 60; // Seconds -> 60 seconds for alert showing

const cookies = new Cookies();

/**
 * @name LogoutTimer
 * @desc Will display modal and automatically log the user out of the browser.
 */
class LogoutTimer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            warningShow: false,
            timeRemaining: DEFAULT_LOGOUT_TIMER
        }
        this.idleTimer = null;
        this.onActive = this.onActive.bind(this);
        this.onIdle = this.onIdle.bind(this);
        this.tick = this.tick.bind(this);
        this.logout = this.logout.bind(this);
        this.logoutTimer = null;
    }

    logout() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/auth/logout/`,
            headers: {
                'Content-Type': 'application/json',
                'Origin': prebase,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            console.log(response);
            cookies.remove('auth_token', { path: '/', domain: cookieDomain, expires: new Date(1970, 0, 1, 0, 0, 0, 0) })
            sessionStorage.clear()
            this.props.history.push("/");
        }).catch(error => {
            console.log(error);
        })
    }

    onActive (e) {
        this.setState({warningShow: false, timeRemaining: DEFAULT_LOGOUT_TIMER});
        clearTimeout(this.logoutTimer);
    }

    onIdle (e) {
        this.logoutTimer = setInterval(this.tick, 1000);
        this.setState({warningShow: true});
    }

    tick() {
        if (this.state.timeRemaining <= 1) {
            this.logout();
            clearTimeout(this.logoutTimer);
        } else {
            this.setState({timeRemaining: this.state.timeRemaining - 1});
        }
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    timeout={DEFAULT_IDLE_TIMER} //20 minute timer to trigger event
                />
                <Modal isOpen={this.state.warningShow} toggle={() => this.setState({ warningShow: false })}>
                    <ModalHeader>
                        Idle Timer
                        </ModalHeader>
                    <ModalBody>
                        You will be logged out in {this.state.timeRemaining} second(s). Log out or resume activity to stay logged in.
                        </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default withRouter(LogoutTimer);
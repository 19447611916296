import React, { Component } from 'react';
import { Container, Card, CardHeader, CardBody, CardFooter, Alert } from 'reactstrap';
import { Form, Input, Button, Option, Select } from 'muicss/react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import PatientInfoCard from './patientInfoCard';
import moment from 'moment-timezone';
import { DropdownList, DateTimePicker } from 'react-widgets';
const cookies = new Cookies();
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

class AppointmentForm extends Component {

    constructor(props) {
        super(props);
        let config = {
            'Initial': { Length: 25, Gap: 5 },
            'Shoe': { Length: 25, Gap: 5 },
            'Education': { Length: 25, Gap: 5 },
            'Cast': { Length: 25, Gap: 5 },
            'Tech': { Length: 25, Gap: 5 },

        };
        if (props.serviceConfig) {
            props.serviceConfig.forEach(service => {
                config[service.Type] = service
            });
        }
        this.state = {
            ServiceConfig: config,
            Type: props.appointment ? props.appointment.fields.Type : 'Initial',
            TimeInDate: props.appointment ? moment(props.appointment.fields.TimeIn).toDate() : undefined,
            TimeIn: props.appointment ? moment(props.appointment.fields.TimeIn).toDate() : undefined,
            TimeOut: props.appointment ? moment(props.appointment.fields.TimeOut).toDate() : undefined,
            locationId: props.locationId,
            personnelId: props.appointment ? props.appointment.fields.Caregiver : (props.personnel[0] ? props.personnel[0].pk : undefined),
            personnel: props.personnel,
            alertShow: false,
            patients: [],
            patientId: props.appointment ? props.appointment.fields.Patient.pk : undefined,
            selectedPatient: undefined,
            appointment: props.appointment || undefined
        };

        this.onCancel = this.onCancel.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        if (!props.appointment) this.getPatients();
    }

    getPatients() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true
        }).then(response => {
            let patients = response.data.map(patient => {
                return {
                    value: patient.pk,
                    label: patient.fields.LastName + ', ' + patient.fields.FirstName
                }
            });
            this.setState({ patients: patients });
        });
    }

    patientSelect(e) {
        this.setState({
            patientId: e.value,
            selectedPatient: e
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let update = {
            [name]: value
        }
        this.setState(update);
    }

    handleStartChangeDate(value) {
        let updates = {
            TimeInDate: value,
        }
        if (this.state.TimeIn) {
            let time = this.state.TimeIn;
            let currTime = new Date(value.getYear() + 1900, value.getMonth(), value.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
            let endTime = new Date(currTime);
            endTime.setMinutes(endTime.getMinutes() + this.state.ServiceConfig[this.state.Type].Length);
            updates.TimeIn = currTime;
            updates.TimeOut = endTime;
            
        }
        this.setState(updates);
    }

    handleStartChange(value) {
        let endTime;
        if (this.state.TimeInDate) {
            value = new Date(this.state.TimeInDate.getYear() + 1900, this.state.TimeInDate.getMonth(), this.state.TimeInDate.getDate(), value.getHours(), value.getMinutes(), value.getSeconds());
            endTime = new Date(this.state.TimeInDate.getYear() + 1900, this.state.TimeInDate.getMonth(), this.state.TimeInDate.getDate(), value.getHours(), value.getMinutes(), value.getSeconds());
        } else {
            endTime = new Date(value);
        }
        endTime.setMinutes(endTime.getMinutes() + this.state.ServiceConfig[this.state.Type].Length);
        let updates = {
            TimeIn: value,
            TimeInDate: value,
            TimeOut: endTime
        }
        this.setState(updates);

    }

    handleSubmit(e) {
        e.preventDefault();
        let csrfToken = cookies.get('csrftoken');
        let data = {
            csrfmiddlewaretoken: csrfToken,
            Type: this.state.Type,
            Patient: this.state.patientId,
            Location: this.state.locationId,
            Caregiver: this.state.personnelId,
        }
        if (this.state.TimeIn) data.TimeIn = moment(this.state.TimeIn).format();
        if (this.state.TimeOut) data.TimeOut = moment(this.state.TimeOut).format();
        let aptId = this.state.appointment ? this.state.appointment.pk : undefined;
        let method = 'post';
        if (aptId) {
            let appointment = this.state.appointment;
            data.Patient = appointment.fields.Patient.pk;
            data.Caregiver = appointment.fields.Caregiver;
            data.Location = appointment.fields.Location;
        }
        let authToken = cookies.get('auth_token')
        axios({
            method: aptId ? 'put' : 'post',
            url: `${base}/api/patients/appointments/${aptId ? aptId + '/' : ''}`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: data
        }).then(response => {
            if (this.props.onSubmit) this.props.onSubmit();
        }).catch(error => {
            console.error(error.response.data);
            this.setState({ alertShow: true, alertMessage: error.response.data.alert || "Could not creat appointment. Please report in bottom right." });
        });
    }

    onCancel(e) {
        e.preventDefault();
        if (this.props.onCancel) this.props.onCancel();
    }

    componentWillReceiveProps(newProps) {
        if(this.state.appointment && newProps.appointment && this.state.appointment.pk !== newProps.appointment.pk || this.state.appointment || newProps.appointment) {
            this.setState ({
                Type: newProps.appointment ? newProps.appointment.fields.Type : 'Initial',
                TimeIn: newProps.appointment ? moment(newProps.appointment.fields.TimeIn).toDate() : undefined,
                TimeOut: newProps.appointment ? moment(newProps.appointment.fields.TimeOut).toDate() : undefined,
                locationId: newProps.locationId,
                personnelId: newProps.appointment ? newProps.appointment.fields.Caregiver : (newProps.personnel[0] ? newProps.personnel[0].pk : undefined),
                personnel: newProps.personnel,
                alertShow: false,
                patients: [],
                patientId: newProps.appointment ? newProps.appointment.fields.Patient.pk : undefined,
                selectedPatient: undefined,
                appointment: newProps.appointment || undefined
            });
            if (!newProps.appointment) this.getPatients();
        }
    }

    patientFilter(patient, searchString) {
        return patient.label.toLowerCase().indexOf(searchString.toLowerCase()) >= 0
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <Alert color={"danger"} isOpen={this.state.alertShow} toggle={() => this.setState({ alertShow: false })}>
                            {this.state ? this.state.alertMessage : null}
                        </Alert>
                        <CardHeader>
                            <legend>{this.state.appointment ? "Modify Appointment" : "Create Appointment"}</legend>
                        </CardHeader>
                        <div className="p-3">
                            {(!this.state.appointment && this.state.patients.length > 0) &&
                                <div className="mb-3">
                                    <DropdownList
                                        required={true}
                                        filter={this.patientFilter}
                                        textField={item => {if (item) return item.label}}
                                        placeholder="Select a patient"
                                        value={this.state.selectedPatient}
                                        onChange={this.patientSelect.bind(this)}
                                        data={this.state.patients}
                                    />
                                </div>
                            }
                            <PatientInfoCard patientId={this.state.patientId} />
                        </div>
                        <CardBody>
                            <Select label="Personnel" disabled={this.state.appointment} value={this.state.personnelId} onChange={(e) => { this.setState({ personnelId: e.target.value }) }}>
                                <Option key={"personnel-none"} value="" label="No Assigned Personnel" />
                                {this.state.personnel.map(personnel => {
                                    return (
                                        <Option key={"personnel" + personnel.pk} value={personnel.pk} label={personnel.LastName + ", " + personnel.FirstName + " - " + personnel.Role} />
                                    )
                                })}
                            </Select>
                            <Select label="Appt. Type" value={this.state.Type} onChange={(e) => {
                                this.setState({ Type: e.target.value, TimeIn: undefined, TimeOut: undefined })
                            }}>
                                <Option value="Initial" label={`Initial Appointment - ${this.state.ServiceConfig["Initial"] ? this.state.ServiceConfig["Initial"].Length || 0 : 0} min`} />
                                <Option value="Shoe" label={`Shoe size and fitting - ${this.state.ServiceConfig["Shoe"] ? this.state.ServiceConfig["Shoe"].Length || 0 : 0} min`} />
                                <Option value="Tech" label={`Distribution of home monitoring technology - ${this.state.ServiceConfig["Tech"] ? this.state.ServiceConfig["Tech"].Length || 0 : 0} min`} />
                                <Option value="Cast" label={`Distribution of ambulatory total contact cast - ${this.state.ServiceConfig["Cast"] ? this.state.ServiceConfig["Cast"].Length || 0 : 0} min`} />
                                <Option value="Education" label={`Diabetes education session - ${this.state.ServiceConfig["Education"] ? this.state.ServiceConfig["Education"].Length || 0 : 0} min`} />
                            </Select>
                            <label>Time In</label>
                            <DateTimePicker
                                name="TimeInDate"
                                value={this.state.TimeInDate}
                                onChange={this.handleStartChangeDate.bind(this)}
                                time={false}
                                step={this.state.ServiceConfig[this.state.Type].Length + this.state.ServiceConfig[this.state.Type].Gap}
                            />
                            <DateTimePicker
                                name="TimeIn"
                                value={this.state.TimeIn}
                                date={false}
                                min={moment(this.state.TimeInDate).hour(7).minute(0).toDate()}
                                max={new Date(2061,5,5,16,1)}
                                onChange={this.handleStartChange.bind(this)}
                                step={this.state.ServiceConfig[this.state.Type].Length + this.state.ServiceConfig[this.state.Type].Gap}
                            />
                            <label>Time Out</label>
                            <DateTimePicker
                                disabled
                                time={false}
                                name="TimeOut"
                                value={this.state.TimeOut}
                                onChange={value => {this.setState({TimeOut: value})}}
                            />
                            <DateTimePicker
                                disabled
                                date={false}
                                name="TimeOut"
                                value={this.state.TimeOut}
                                onChange={value => {this.setState({TimeOut: value})}}
                            />
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" color="primary" type="submit">Save</Button>
                            <Button variant="raised" color="secondary" onClick={(e) => { this.onCancel(e) }}>Cancel</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}

export default withRouter(AppointmentForm);

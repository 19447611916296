import React, {Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter} from 'reactstrap';
import {Form, Radio, Button} from 'muicss/react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const qs = require('qs');
const moment = require('moment');
const prebase = window.location.protocol + '//' + window.location.host;
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientScreening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: qs.parse(props.location.search, {ignoreQueryPrefix: true}).pid,
            screening: qs.parse(props.location.search, {ignoreQueryPrefix: true}).sid,
            then: qs.parse(props.location.search, {ignoreQueryPrefix: true}).then,
            lostInterest: '',
            depressed: '',
            troubleSleeping: '',
            noEnergy: '',
            troubleEating: '',
            poorSelfImage: '',
            noConcentration: '',
            listlessAgitated: '',
            selfHarm: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        if (this.state.screening) {
            this.loadPatientScreening();
        }
    }

    loadPatientScreening() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/screening/${this.state.screening}/show/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            let screening = response.data[0];
            this.setState({
                lostInterest: `${screening.fields.lostInterest}`,
                depressed: `${screening.fields.depressed}`,
                troubleSleeping: `${screening.fields.troubleSleeping}`,
                noEnergy: `${screening.fields.noEnergy}`,
                troubleEating: `${screening.fields.troubleEating}`,
                poorSelfImage: `${screening.fields.poorSelfImage}`,
                noConcentration: `${screening.fields.noConcentration}`,
                listlessAgitated: `${screening.fields.listlessAgitated}`,
                selfHarm: `${screening.fields.selfHarm}`,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let csrfToken = cookies.get("csrftoken");
        let data = {
            csrfmiddlewaretoken: csrfToken,
            updated: moment().format('YYYY-MM-DD'),
            patient: this.state.patient,
        };
        let total = 0;
        let responses = this.state;
        let keys = ['lostInterest', 'depressed', 'troubleSleeping', 'noEnergy', 'troubleEating', 'poorSelfImage', 'noConcentration', 'listlessAgitated', 'selfHarm'];
        keys.forEach((key) => {
            let value = parseInt(responses[key], 10);
            data[key] = value;
            total += value;
        });
        data['totalScore'] = total;
        let authToken = cookies.get('auth_token')
        axios({
            method: 'post',
            url: `${base}/api/patients/screening/`,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
            data: data
        }).then(response => {
            this.props.history.push(this.state.then ? this.state.then : '/?registered=true');
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <Container>
                <Card>
                    <Form onSubmit={this.handleSubmit}>
                        <CardHeader>
                            <legend>Patient Screening</legend>
                        </CardHeader>
                        <CardBody>
                            <fieldset>
                                <legend>Over the last two weeks, how often have you been bothered by any of the following problems?</legend>
                                <fieldset>
                                    <legend>Little interest or pleasure in doing things</legend>
                                    <Radio name="lostInterest" label="Not at all" value="0" checked={this.state.lostInterest === "0"} onChange={this.handleInputChange} />
                                    <Radio name="lostInterest" label="Several days" value="1" checked={this.state.lostInterest === "1"} onChange={this.handleInputChange} />
                                    <Radio name="lostInterest" label="More than half the days" value="2" checked={this.state.lostInterest === "2"} onChange={this.handleInputChange} />
                                    <Radio name="lostInterest" label="Nearly every day" value="3" checked={this.state.lostInterest === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Feeling down, depressed or hopeless</legend>
                                    <Radio name="depressed" label="Not at all" value="0" checked={this.state.depressed === "0"} onChange={this.handleInputChange} />
                                    <Radio name="depressed" label="Several days" value="1" checked={this.state.depressed === "1"} onChange={this.handleInputChange} />
                                    <Radio name="depressed" label="More than half the days" value="2" checked={this.state.depressed === "2"} onChange={this.handleInputChange} />
                                    <Radio name="depressed" label="Nearly every day" value="3" checked={this.state.depressed === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Trouble falling or staying asleep, or sleeping too much</legend>
                                    <Radio name="troubleSleeping" label="Not at all" value="0" checked={this.state.troubleSleeping === "0"} onChange={this.handleInputChange} />
                                    <Radio name="troubleSleeping" label="Several days" value="1" checked={this.state.troubleSleeping === "1"} onChange={this.handleInputChange} />
                                    <Radio name="troubleSleeping" label="More than half the days" value="2" checked={this.state.troubleSleeping === "2"} onChange={this.handleInputChange} />
                                    <Radio name="troubleSleeping" label="Nearly every day" value="3" checked={this.state.troubleSleeping === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Feeling tired or having little energy</legend>
                                    <Radio name="noEnergy" label="Not at all" value="0" checked={this.state.noEnergy === "0"} onChange={this.handleInputChange} />
                                    <Radio name="noEnergy" label="Several days" value="1" checked={this.state.noEnergy === "1"} onChange={this.handleInputChange} />
                                    <Radio name="noEnergy" label="More than half the days" value="2" checked={this.state.noEnergy === "2"} onChange={this.handleInputChange} />
                                    <Radio name="noEnergy" label="Nearly every day" value="3" checked={this.state.noEnergy === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Poor appetite or overeating</legend>
                                    <Radio name="troubleEating" label="Not at all" value="0" checked={this.state.troubleEating === "0"} onChange={this.handleInputChange} />
                                    <Radio name="troubleEating" label="Several days" value="1" checked={this.state.troubleEating === "1"} onChange={this.handleInputChange} />
                                    <Radio name="troubleEating" label="More than half the days" value="2" checked={this.state.troubleEating === "2"} onChange={this.handleInputChange} />
                                    <Radio name="troubleEating" label="Nearly every day" value="3" checked={this.state.troubleEating === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Feeling bad about yourself, or that you are a failure, or have let yourself or your family down</legend>
                                    <Radio name="poorSelfImage" label="Not at all" value="0" checked={this.state.poorSelfImage === "0"} onChange={this.handleInputChange} />
                                    <Radio name="poorSelfImage" label="Several days" value="1" checked={this.state.poorSelfImage === "1"} onChange={this.handleInputChange} />
                                    <Radio name="poorSelfImage" label="More than half the days" value="2" checked={this.state.poorSelfImage === "2"} onChange={this.handleInputChange} />
                                    <Radio name="poorSelfImage" label="Nearly every day" value="3" checked={this.state.poorSelfImage === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Trouble concentrating on things, such as reading the newspaper or watching television</legend>
                                    <Radio name="noConcentration" label="Not at all" value="0" checked={this.state.noConcentration === "0"} onChange={this.handleInputChange} />
                                    <Radio name="noConcentration" label="Several days" value="1" checked={this.state.noConcentration === "1"} onChange={this.handleInputChange} />
                                    <Radio name="noConcentration" label="More than half the days" value="2" checked={this.state.noConcentration === "2"} onChange={this.handleInputChange} />
                                    <Radio name="noConcentration" label="Nearly every day" value="3" checked={this.state.noConcentration === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Moving or speaking so slowly that other people could have noticed? Or the opposite, being so fidgety or restless that you have been moving around a lot more than usual</legend>
                                    <Radio name="listlessAgitated" label="Not at all" value="0" checked={this.state.listlessAgitated === "0"} onChange={this.handleInputChange} />
                                    <Radio name="listlessAgitated" label="Several days" value="1" checked={this.state.listlessAgitated === "1"} onChange={this.handleInputChange} />
                                    <Radio name="listlessAgitated" label="More than half the days" value="2" checked={this.state.listlessAgitated === "2"} onChange={this.handleInputChange} />
                                    <Radio name="listlessAgitated" label="Nearly every day" value="3" checked={this.state.listlessAgitated === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                                <fieldset>
                                    <legend>Thoughts that you would be better off dead or of hurting yourself in some way</legend>
                                    <Radio name="selfHarm" label="Not at all" value="0" checked={this.state.selfHarm === "0"} onChange={this.handleInputChange} />
                                    <Radio name="selfHarm" label="Several days" value="1" checked={this.state.selfHarm === "1"} onChange={this.handleInputChange} />
                                    <Radio name="selfHarm" label="More than half the days" value="2" checked={this.state.selfHarm === "2"} onChange={this.handleInputChange} />
                                    <Radio name="selfHarm" label="Nearly every day" value="3" checked={this.state.selfHarm === "3"} onChange={this.handleInputChange} />
                                </fieldset>
                            </fieldset>
                        </CardBody>
                        <CardFooter>
                            <Button variant="raised" className="bg-success" color="primary" type="submit">Submit</Button>
                        </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}
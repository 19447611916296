import React, {Fragment, Component} from 'react';
import {Container, Card, CardHeader, CardBody, CardFooter, UncontrolledTooltip} from 'reactstrap';
import axios from 'axios';
import { CloudWatchLogsClient, CreateLogGroupCommand, CreateLogStreamCommand, PutLogEventsCommand, DescribeLogStreamsCommand  } from "@aws-sdk/client-cloudwatch-logs";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientScreening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: props.match.params.pid,
            sid: props.match.params.sid,
            patient: {fields: {}},
            screening: {fields: {}},
            pageLoading: true,
        }
        this.loadPatient();
        this.writeLogs = this.writeLogs.bind(this)
    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.writeLogs(response.data[0])
            this.setState({
                patient: response.data[0],
            }, this.loadScreening);
        }).catch(error => {
            console.log(error);
        })
    }

    async writeLogs(patient) {     
        const logKey = localStorage.getItem('logKey');
        const client = new CloudWatchLogsClient({region: 'us-east-1',   credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'us-east-1' },
            identityPoolId: 'us-east-1:671651f5-c5ab-497b-8966-5baae2389b1a',
          })})
        const logGroupName = 'daps_access_logs'
        const logStreamName = `access_logs_${logKey}`
        const logGroupCommand = new CreateLogGroupCommand({logGroupName: logGroupName})
        const logStreamCommand = new CreateLogStreamCommand({logGroupName: logGroupName, logStreamName: logStreamName})
        try {
            const createLogGroup = await client.send(logGroupCommand)
        } catch (error) {
            if (!error.name === 'ResourceAlreadyExistsException') {
                console.log('log group error', error)
            }
        } finally {
            try {
                const createLogStream = await client.send(logStreamCommand)
            } catch (error) {
                if (!error.name === 'ResourceAlreadyExistsException') {
                    console.log('log stream error', error)
                }
            } finally {
                try  {
                    const describeLogStreams = new DescribeLogStreamsCommand({logGroupName: logGroupName, logStreamNamePrefix: logStreamName, limit: 1})
                    const currentStreams = await client.send(describeLogStreams)
                    const sequenceToken = currentStreams.logStreams[0].uploadSequenceToken
                    const logEventCommand = new PutLogEventsCommand({logGroupName: logGroupName, logStreamName: logStreamName, logEvents: [{message: `user ${localStorage.getItem('user_name')} has accessed patient ${patient.fields.FirstName} ${patient.fields.LastName}: ${patient.fields.PatientId} screening records on ${window.location.href}`, timestamp: Date.now()}], sequenceToken: sequenceToken})
                    const data = await client.send(logEventCommand)
                } catch (error) {
                    console.log('log event error', error)
                }
            }
        }
    }

    loadScreening() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/screening/${this.state.sid}/show/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                screening: response.data[0],
                pageLoading: false,
            });
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        let patient = this.state.patient ? this.state.patient.fields : {};
        let screening = this.state.screening ? this.state.screening.fields : {};

        let styling = {}
        styling['screening'] = {}
        let totalScore = screening.totalScore
        switch (true) {
            case (totalScore <=4):
                styling.screening.color = 'green'
                break;
            case (totalScore <=9):
                styling.screening.color = 'yellowgreen'
                break;
            case (totalScore <=14):
                styling.screening.color = 'orange'
                break;
            case (totalScore <=19):
                styling.screening.color = 'orangered'
                break;
            case (totalScore <=27):
                styling.screening.color = 'red'
                break;
        }
        return (
            <Fragment>
                {this.state.pageLoading ? 
                <Container></Container> :
                <Container>
                    <Card>
                        <CardHeader>
                            <h2 className="float-left">{patient.LastName + ', ' + patient.FirstName}</h2>
                        </CardHeader>
                        <CardBody>
                            <span className="h6 float-right">Last Updated: {screening.updated}</span>
                            <h3>Patient Health Questionnaire 9 Item</h3>
                            <div className="ml-4">
                                <p className="h4">Over the Past 2 Weeks how often have had these Symptoms:</p>
                                <div className="m-4">
                                    <p className="h5">Little interest or pleasure in doing things: {screening.lostInterest}</p>
                                    <p className="h5">Feeling down, depressed or hopeless: {screening.depressed}</p>
                                    <p className="h5">Trouble falling or staying asleep, or sleeping too much: {screening.troubleSleeping}</p>
                                    <p className="h5">Feeling tired or having little energy: {screening.noEnergy}</p>
                                    <p className="h5">Poor appetite or overeating: {screening.troubleEating}</p>
                                    <p className="h5">Feeling bad about yourself, or that you are a failure, or have let yourself or your family down: {screening.poorSelfImage}</p>
                                    <p className="h5">Trouble concentrating on things, such as reading the newspaper or watching television: {screening.noConcentration}</p>
                                    <p className="h5">Moving or speaking so slowly that other people could have noticed? Or the opposite, being so fidgety or restless that you have been moving around a lot more than usual: {screening.listlessAgitated}</p>
                                    <p className="h5" style={{ color: screening.selfHarm >= 2 ? 'red' : ''}}>Thoughts that you would be better off dead or of hurting yourself in some way:
                                        <span>{screening.selfHarm} {screening.selfHarm >= 2 && <i id="selfharm-warning" className="fa fa-exclamation-circle" ></i>}
                                        </span>
                                        {screening.selfHarm >= 2 &&
                                            <UncontrolledTooltip placement="top" target="selfharm-warning">
                                                RECOMMENDED: Immediate referral.
                                            </UncontrolledTooltip>
                                        }
                                    </p>
                                    </div>
                                    <p className="h4" style={styling['screening']}>Patient health questionnaire 9 question total score: {screening.totalScore}</p>
                                    <p>
                                        <small>
                                            <span style={{ color: 'green' }}>0-4: Minimal or None</span>
                                            <br />
                                            <span style={{ color: 'yellowgreen' }}>5-9: Mild</span>
                                            <br />
                                            <span style={{ color: 'orange' }}>10-14: Moderate</span>
                                            <br />
                                            <span style={{ color: 'orangered' }}>15-19: Moderately severe</span>
                                            <br />
                                            <span style={{ color: 'red' }}>20-27: Severe</span>
                                        </small>
                                    </p>                            
                                </div>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Container>}
            </Fragment>
        )
    }
}
import React, { Component } from 'react';
import { Container, Card, CardHeader, CardBody, CardFooter, Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import axios from 'axios';
import { Input, Button } from 'muicss/react';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import moment from 'moment';
import { CloudWatchLogsClient, CreateLogGroupCommand, CreateLogStreamCommand, PutLogEventsCommand, DescribeLogStreamsCommand } from "@aws-sdk/client-cloudwatch-logs";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import Cookies from 'universal-cookie';

const cookies = new Cookies()
const prebase = window.location.protocol + '//' + window.location.host;
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

export default class PatientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            filter: '',
            role: localStorage.getItem('role'),
            user_id: localStorage.getItem('user_id'),
            complianceEnd: moment().format('YYYY-MM-DD'),
            complianceStart: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            minStart: moment().subtract(674, 'days').format('YYYY-MM-DD'),
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.downloadRecord = this.downloadRecord.bind(this);
        this.writeLogs = this.writeLogs.bind(this)
        this.loadPatients();
    }

    componentDidMount() {
        this.writeLogs()
    }

    async writeLogs() {
        const logKey = localStorage.getItem('logKey');
        const client = new CloudWatchLogsClient({region: 'us-east-1',   credentials: fromCognitoIdentityPool({
            clientConfig: { region: 'us-east-1' },
            identityPoolId: 'us-east-1:671651f5-c5ab-497b-8966-5baae2389b1a',
          })})
        const logGroupName = 'daps_access_logs'
        const logStreamName = `access_logs_${logKey}`
        const logGroupCommand = new CreateLogGroupCommand({logGroupName: logGroupName})
        const logStreamCommand = new CreateLogStreamCommand({logGroupName: logGroupName, logStreamName: logStreamName})
        try {
            const createLogGroup = await client.send(logGroupCommand)
        } catch (error) {
            if (!error.name === 'ResourceAlreadyExistsException') {
                console.log('log group error', error)
            }
        } finally {
            try {
                const createLogStream = await client.send(logStreamCommand)
            } catch (error) {
                if (!error.name === 'ResourceAlreadyExistsException') {
                    console.log('log stream error', error)
                }
            } finally {
                try  {
                    const describeLogStreams = new DescribeLogStreamsCommand({logGroupName: logGroupName, logStreamNamePrefix: logStreamName, limit: 1})
                    const currentStreams = await client.send(describeLogStreams)
                    const sequenceToken = currentStreams.logStreams[0].uploadSequenceToken
                    const logEventCommand = new PutLogEventsCommand({logGroupName: logGroupName, logStreamName: logStreamName, logEvents: [{message: `user ${localStorage.getItem('user_name')} has accessed patient list on ${window.location.href}`, timestamp: Date.now()}], sequenceToken: sequenceToken})
                    const data = await client.send(logEventCommand)
                } catch (error) {
                    console.log('log event error', error)
                }
            }
        }
    }

    loadPatients() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                patients: response.data.sort((a, b) => {
                    if (a.fields.Active && !b.fields.Active) {
                        return -1;
                    }
                    if (b.fields.Active && !a.fields.Active) {
                        return 1;
                    }
                    if (a.fields.LastName < b.fields.LastName) {
                        return -1;
                    }
                    if (a.fields.LastName > b.fields.LastName) {
                        return 1;
                    }
                    if (a.fields.FirstName < b.fields.FirstName) {
                        return -1;
                    }
                    if (a.fields.FirstName > b.fields.FirstName) {
                        return 1;
                    }
                    if (a.pk < b.pk) {
                        return -1;
                    }
                    if (a.pk > b.pk) {
                        return 1;
                    }
                    return 0;
                }),
            })
        }).catch(error => {
            console.log(error);
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let minStart = this.state.minStart;
        if (name==="complianceEnd") {
            minStart = moment(value).subtract(674, 'days').format('YYYY-MM-DD');
        }
        this.setState({
            [name]: value,
            minStart: minStart,
        });
    }

    downloadRecord() {
        let authToken = cookies.get('auth_token')
        let caremanager = '';
        if (this.state.role === 'caremgr') {
            caremanager = `&caregiver=${this.state.user_id}`;
        }
        axios({
            method: 'get',
            url: `${base}/api/temperature/dailyPoints/csv/?startDate=${this.state.complianceStart}&endDate=${this.state.complianceEnd}${caremanager}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            responseType: 'blob',
        }).then((response) => {
            FileSaver(response.data, `compliance_${this.state.complianceStart}_to_${this.state.complianceEnd}.xlsx`)
        });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col md="4">
                        <Input type="date" name="complianceStart" id="complianceStart" value={this.state.complianceStart} onChange={this.handleInputChange} min={this.state.minStart} />  
                        <label htmlFor="complianceStart">Start Date</label>
                    </Col>
                    <Col md="4">                                    
                        <Input type="date" name="complianceEnd" id="complianceEnd" value={this.state.complianceEnd} onChange={this.handleInputChange}/>              
                        <label htmlFor="complianceEnd">End Date</label>
                    </Col>
                    <Col md="4">
                        <Button onClick={this.downloadRecord} variant="raised" color="primary" disabled={this.state.complianceStart < this.state.minStart}>Download Compliance Record</Button>
                    </Col>
                </Row>
                <Card>
                    <CardHeader>
                        <h2>Patients</h2>
                    </CardHeader>
                    <CardBody>
                        {this.state.role === 'admin' || this.state.role === 'caremgr' ? <Link className="mui-btn mui-btn--primary" to="/admin/patient/new">Add New Patient</Link> : ''}
                        <Input label="Search" type="text" name="filter" id="filter-input" value={this.state.filter} onChange={this.handleInputChange} autoFocus />
                        {this.state.patients.reduce((accumulator, patient) => {
                            if (patient.fields.LastName.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1 || patient.fields.FirstName.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1 ) {
                                accumulator.push(
                                    <a key={patient.pk} href={`/admin/patients/${patient.pk}/view`}>
                                        <Card key={patient.pk}>
                                            <CardBody>
                                                <p className="h4 float-left">
                                                    {patient.fields.LastName + ', ' + patient.fields.FirstName}&nbsp;
                                                    <span className={patient.fields.Active ? 'fa fa-check' : 'fa fa-times'} aria-label={patient.fields.Active ? 'Alerts enabled' : 'Alerts disabled'} title={patient.fields.Active ? 'Alerts enabled' : 'Alerts disabled'}></span>
                                                </p>
                                                <p className="h5 float-right">{patient.fields.dob}</p>
                                            </CardBody>
                                        </Card>
                                    </a>
                                )
                            }
                            return accumulator;
                        }, [])}
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                </Card>
            </Container>
        )
    }
}


import React, { Component } from 'react';
import { Container, Row, Col, Alert, Card, CardHeader, CardBody, CardFooter, Breadcrumb, BreadcrumbItem} from 'reactstrap';

import Logo from '../../assets/logo-no-text.png';
const qs = require('qs');

export default class Admin extends Component {
    constructor(props)       {
        super(props);
        this.state = {
            registered: qs.parse(props.location.search, {ignoreQueryPrefix: true}).registered
        }
    }

    render() {
        return (
            <Container style={{textAlign: 'center'}} >
                <Card>
                    <CardHeader>
                        <h2>Forbidden</h2>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="3">
                                <img className="float-left" src={Logo} alt="DAPS Health Logo" style={{width: '100%'}} />
                            </Col>
                            <Col sm="9">
                                <p className="m-4 p-4 float-right text-left">Your account does not have permission to access these pages. Please log out and log in with a health practioner account.</p>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter></CardFooter>
                </Card>
            </Container>
        );
    }
}
import React, { Component } from 'react';
import {
    Container, Row, Col,
    Card, CardHeader, CardBody, CardFooter,
} from 'reactstrap';

import QRCode from 'react-qr-code'
import Cookies from 'universal-cookie'

import { Button } from 'muicss/react';
import Logo from './../../assets/DAPSwhitelogo.png';

const qs = require('qs');

const cookies = new Cookies();

class MfaQr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qrCode: qs.parse(props.location.search, {ignoreQueryPrefix: true}).qrCode,
        }
    }

    render() {
        return (
            <Container>
                <Row className="p-4">
                    <Col sm="12" md="6" className="text-center" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src={Logo} alt="DAPS Health Logo" style={{maxHeight: '80%', maxWidth: '80%'}} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md='6' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <Card>
                            <CardHeader>
                                <legend>Scan This Code With Your Authentication Application</legend>
                            </CardHeader>
                            <CardBody>
                                <QRCode value={this.state.qrCode} />
                            </CardBody>
                            <CardFooter>
                                <Button className='my-4' variant="warning" onClick={() => cookies.get('mfa_configure') ? this.props.history.push('/mfa-confirm/?method=app') : this.props.history.push('/admin/mfa-confirm/?method=app')}>Next</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default MfaQr;
import React, {Fragment, Component} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Dropdown, DropdownItem} from 'muicss/react';
import axios from 'axios';
import {Line, Bar} from 'react-chartjs-2';
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const qs = require('qs');
const prebase = window.location.protocol + '//' + window.location.host
const base = (prebase.indexOf('3000') > -1) ? prebase.replace('3000', '8080') : prebase;

//const screeningScores = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

export default class PatientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pid: qs.parse(props.location.search, {ignoreQueryPrefix: true}).pid,
            patient: {fields: {}},
            history: {fields: {}},
            pageLoading: true,
            activeTab: 'patient',
        }
        this.loadPatient();
    }

    loadPatient() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/patient/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                patient: response.data[0],
            }, this.loadPatientHistory);
        }).catch(error => {
            console.log(error);
        })
    }

    loadPatientHistory() {
        let authToken = cookies.get('auth_token')
        axios({
            method: 'get',
            url: `${base}/api/patients/history/${this.state.pid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`
            },
            withCredentials: true,
        }).then(response => {
            this.setState({
                history: response.data[0],
                pageLoading: false,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        let patient = this.state.patient ? this.state.patient.fields : {};
        let history = this.state.history ? this.state.history.fields : {};
        return (
            <Fragment>
                {this.state.pageLoading ? 
                <Container></Container> :
                <Container>
                    <Card>
                        <CardHeader>
                            <h2 className="float-left">{patient.LastName + ', ' + patient.FirstName}</h2>
                        </CardHeader>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === 'patient' ? 'active' : ''} onClick={() => {this.toggle('patient')}}>Basic Information</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={this.state.activeTab === 'history' ? 'active' : ''} onClick={() => {this.toggle('history')}}>Patient History</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="patient">
                                    <br />
                                    <div className="ml-4">
                                        <p className="h4">Name: {patient.FirstName + ' ' + patient.LastName}</p>
                                        <p className="h5">DOB: {patient.dob}</p>
                                        <p className="h5">Address: {patient.Address}</p>
                                        <p className="h5">Telephone Numbers:</p>
                                        <div className="ml-4">
                                            <p className="h6">Home: {patient.HomePhone}</p>
                                            <p className="h6">Cell: {patient.CellPhone}</p>
                                            <p className="h6">Work: {patient.WorkPhone}</p>
                                        </div>
                                        <p className="h5">Email: {patient.PrimaryEmail}</p>
                                        <p className="h5">Significant Other: {patient.EmergencyContact}</p>
                                        <p className="h5">Insurance Information: {patient.Insurance}</p>
                                        <p className="h5">Primary Care Physician: {patient.PrimaryCarePhysician}</p>
                                        <p className="h5">Referral Source: {patient.ReferralSource}</p>
                                    </div>
                                    <br />
                                </TabPane>
                                <TabPane tabId="history">
                                    <br />
                                    <div className="clearfix">
                                        <span className="h6 float-right">Last Updated: {history.updated}</span>
                                    </div>
                                    <div className="mx-4">
                                        <Row>
                                            <Col md="6">
                                                <p className="h4">Diagnosis and Control</p>
                                                <div className="ml-4">
                                                    <p className="h5">Age at Diagnosis: {history.diagnosed}</p>
                                                    <p className="h5">Control Method: {history.controlMethod}</p>
                                                </div>
                                                <p className="h4">Complications</p>
                                                <div className="ml-4">
                                                    <p className="h5">Leg/Foot Ulcer or Lower Leg Amputation/Surgery: {history.ulcerAmputation}</p>
                                                    <p className="h5">Angioplasty, Stent or Leg Bypass Surgery: {history.circulation}</p>
                                                    <p className="h5">Foot Wound: {history.wound}</p>
                                                    <p className="h5">Smoking: {history.smoking}</p>
                                                </div>
                                                <p className="h4">Comorbidities</p>
                                                <div className="ml-4">
                                                    <p className="h5">Clinical Depression: {history.depression}</p>
                                                    <p className="h5">Problems with Eyes: {history.eyeProblems}</p>
                                                    <p className="h5">Heart Disease: {history.heartDisease}</p>
                                                    <p className="h5">Kidney Disease: {history.kidneyDisease}</p>
                                                    <p className="h5">High Blood Pressure: {history.highBloodPressure}</p>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <p className="h4">Neuropathy</p>
                                                <div className="ml-4">
                                                    <p className="h5">Burning or Tingling in Legs and Feet: {history.legFootBurning}</p>
                                                    <p className="h5">Leg or Foot Pain with Activity or Rest: {history.legFootPain}</p>
                                                    <p className="h5">Changes in Skin Color or Skin Lesions: {history.skinColorLesions}</p>
                                                    <p className="h5">Loss of Sensation in Legs and Feet: {history.legFootNumbness}</p>
                                                </div>
                                                <p className="h4">Procedures and Medications</p>
                                                <div className="ml-4">
                                                    <p className="h5">Surgeries: {history.surgeries}</p>
                                                    <p className="h5">Medications: {history.medications}</p>
                                                    <p className="h5">Most Recent Hemoglobin A1C: {history.recentA1c}</p>
                                                    <p className="h5">Morning Glucose: {history.morningGlucose}</p>
                                                    <p className="h5">Regular Podiatric Care: {history.podiatricCare}</p>
                                                </div>
                                                <p className="h4">Additional Notes</p>
                                                <div className="ml-4">
                                                    <p className="h5">{history.additionalNotes}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                        <CardFooter>
                            <a href={"/screening?"  + qs.stringify({pid: this.state.pid})} className="mui-btn bg-success mui-btn--raised" >
                                Confirm
                            </a>
                            <a href={"/update?" + qs.stringify({pid: this.state.pid, then: "review?" + qs.stringify({pid: this.state.pid})})} className="mui-btn bg-danger mui-btn--raised">
                                Update Patient
                            </a>
                            <a href={"/history?" + qs.stringify({pid: this.state.pid, then: "review?" + qs.stringify({pid: this.state.pid})})} className="mui-btn bg-info mui-btn--raised">
                                Update Records
                            </a>
                        </CardFooter>
                    </Card>
                </Container>}
            </Fragment>
        )
    }
}